<template>
  <v-card>
    <v-toolbar :color="hasSelectedUsers ? 'primary' : 'secondary'">
      <v-toolbar-title v-if="!hasSelectedUsers">Study members</v-toolbar-title>
      <v-toolbar-title v-else class="text-white">{{ selectedUsers.length }} members selected</v-toolbar-title>

      <v-spacer />

      <v-toolbar-items v-if="hasSelectedUsers" color="white">
        <v-btn :disabled="disabled" @click="scheduleDialog = true">
          Set auto schedule

          <template #append>
            <v-icon>mdi-clock-outline</v-icon>
          </template>
        </v-btn>

        <v-btn :disabled="disabled" @click="clearAutoSchedule()">
          Clear auto schedule

          <template #append>
            <v-icon>mdi-clock-minus-outline</v-icon>
          </template>
        </v-btn>
        <v-btn v-if="selectedUsers.length === config.studyUsers.length" @click="downloadStudyUsers()">
          Download selected

          <template #append>
            <v-icon>mdi-download</v-icon>
          </template>
        </v-btn>

        <v-btn :disabled="disabled" @click="removeStudyUsers()">
          Remove selected

          <template #append>
            <v-icon>mdi-delete</v-icon>
          </template>
        </v-btn>
      </v-toolbar-items>
      <v-toolbar-items v-else>
        <v-btn :disabled="disabled" @click="((selectedUsers = []), (selectDialog = true))">
          Add study members

          <template #append>
            <v-icon>mdi-plus-thick</v-icon>
          </template>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-data-table-server
      v-model="selectedUsers"
      show-expand
      single-expand
      expand-on-click
      loading-text="Loading... Please wait"
      item-value="userUid"
      :items-length="totalUsers"
      :search="search"
      :loading="isLoading"
      :headers="usersHeaders"
      :expanded="expandedUsers"
      :items="combinedUsersList"
      :items-per-page="25"
      :items-per-page-options="[
        { title: '25', value: 25 },
        { title: '50', value: 50 },
        { title: '100', value: 100 },
      ]"
      :no-data-text="search ? 'No matching users found' : 'No existing users'"
      @update:options="loadUsers($event)"
      @update:expanded="loadUserData($event)"
    >
      <template #header.actions="{ selectAll, allSelected }">
        <v-checkbox
          :disabled="disabled || !config.studyUsers.length"
          :model-value="allSelected"
          @update:model-value="selectAll(!allSelected)"
        />
      </template>

      <template #item.userUid="{ item }">
        <v-tooltip location="top">
          <template #activator="{ props }">
            {{ item.userUid }}
            <v-btn
              v-bind="props"
              icon="mdi-open-in-new"
              rounded
              @click.stop="goToDarwinUser(config.appFlavor, item.userUid)"
            />
          </template>
          <span>Open user in Darwin</span>
        </v-tooltip>
      </template>

      <template #item.schedule="{ item }">
        {{ getScheduleInfo(item).startsWith('No') ? 'No' : 'Yes' }}
      </template>

      <template #item.actions="{ internalItem, isSelected, toggleSelect }">
        <v-checkbox
          :disabled="disabled"
          :model-value="isSelected(internalItem)"
          @click.stop
          @update:model-value="toggleSelect(internalItem)"
        />
      </template>

      <template #item.warnings="{ item }">
        <span>
          <v-tooltip location="top">
            <template #activator="{ props }">
              <v-icon v-if="item.warnings.hasNoOrbUploads" v-bind="props" color="red">mdi-alert</v-icon>
            </template>
            <span>No ORB uploads</span>
          </v-tooltip>

          <v-tooltip location="top">
            <template #activator="{ props }">
              <v-icon v-if="item.warnings.hasSmallUploads" v-bind="props" color="yellow-darken-2">mdi-alert</v-icon>
            </template>
            <span>Some recent ORB uploads are small</span>
          </v-tooltip>
        </span>
      </template>

      <template #item.addedAt="{ item }">
        <span>
          {{ item.addedAt !== null ? $dayjs(item.addedAt).format('HH:mm - DD MMM YYYY') : 'Just added' }}
        </span>
      </template>

      <template #item.lastOrbUpload="{ item }">
        <span>
          {{ item.lastOrbUpload ? $dayjs(item.lastOrbUpload).format('HH:mm - DD MMM YYYY') : 'No ORB uploads' }}
        </span>
      </template>

      <template #item.fetchedAt="{ item }">
        <span>
          {{ item.fetchedAt !== null ? $dayjs(item.fetchedAt).format('HH:mm - DD MMM YYYY') : 'Not fetched yet' }}
        </span>
      </template>

      <template #expanded-row="{ columns, item }">
        <td :colspan="columns.length">
          <div class="expanded-row py-2 mx-4" style="padding-left: 55px">
            <v-row class="mr-0">
              <v-col cols="7">
                <v-tabs v-model="userTab">
                  <v-tab>Auto Schedule</v-tab>
                  <v-tab>ORB Stream</v-tab>
                  <v-tab>Jzlog Files</v-tab>
                </v-tabs>
              </v-col>

              <v-col cols="5">
                <div class="d-flex flex-row align-top justify-end text-right py-4">
                  <span class="mr-2">
                    {{ getScheduleInfo(item).startsWith('No') ? 'Not scheduled' : 'Scheduled' }}
                    |
                    {{ loadingOrbFiles ? '-' : userOrbFiles.length }} files for this study
                  </span>
                </div>
              </v-col>
            </v-row>

            <v-data-table
              v-if="userTab === 1"
              hide-default-footer
              no-data-text="No ORB files exists for this study"
              :loading="loadingOrbFiles"
              :headers="orbFilesHeaders"
              :items="userOrbFiles"
            >
              <template #item.actions="{ item: orb }">
                <v-btn
                  icon="mdi-download"
                  :disabled="!rights.includes('allowDataDownloadAccess')"
                  @click="downloadOrbFile(orb)"
                />
              </template>

              <template #item.streamUuid="{ item: orb }">
                {{ orb.streamUuid }}
                <v-tooltip :text="useClipboard.copied ? 'Copied' : 'Copy to clipboard'">
                  <template #activator="{ props }">
                    <v-btn icon="mdi-content-copy" v-bind="props" @click="useClipboard.copy(orb.streamUuid)" />
                  </template>
                </v-tooltip>
              </template>

              <template #item.label="{ item: orb }">
                {{ orb.info?.recording.label || 'No label set' }}
              </template>

              <template #item.fw="{ item: orb }">
                {{ orb.info?.ring.firmware_version || 'Unknown' }}
              </template>

              <template #item.collectionId="{ item: orb }">
                {{ orb.info?.recording.collection_id }}
              </template>

              <template #item.started="{ item: orb }">
                {{ $dayjs(orb.info?.recording.time_start).format('HH:mm:ss - DD MMM YYYY') }}
              </template>

              <template #item.uploadedAt="{ item: orb }">
                {{ $dayjs(orb.uploadedAt).format('HH:mm:ss - DD MMM YYYY') }}
              </template>
            </v-data-table>

            <v-data-table
              v-else-if="userTab === 2"
              hide-default-footer
              no-data-text="No Jzlog files exists for this user"
              :loading="loadingJzlogFiles"
              :headers="jzlogFilesHeaders"
              :items="userJzlogFiles"
            >
              <template #item.actions="{ item: file }">
                <v-btn
                  class="text-primary mr-2"
                  icon="mdi-download"
                  :disabled="!rights.includes('allowDataDownloadAccess')"
                  @click="downloadJzlogFile(file)"
                />
              </template>

              <template #item.type="{ item: file }">{{ file.type }}</template>

              <template #item.lastModified="{ item: file }">
                {{ $dayjs(file.lastModified).format('HH:mm:ss - DD MMM YYYY') }}
              </template>
            </v-data-table>

            <div v-else class="pa-4">{{ getScheduleInfo(item) }}</div>

            <div class="d-flex flex-row align-top justify-end text-right pt-4 px-4">
              <v-btn
                class="mt-n3 ml-2"
                color="primary"
                prepend-icon="mdi-reload"
                text="Refresh streams / files"
                @click.stop="loadUserData(expandedUsers)"
              />
            </div>
          </div>
        </td>
      </template>

      <template #footer.prepend>
        <v-text-field
          v-model.trim="search"
          hide-details="auto"
          class="ml-3"
          variant="underlined"
          style="width: 380px"
          prepend-icon="mdi-magnify"
          placeholder="Search by email or UUID"
          :error-messages="userSearchInputError"
        />

        <v-spacer />
      </template>
    </v-data-table-server>
  </v-card>

  <SetupSchedule
    :team="config"
    :open="scheduleDialog"
    @cancel="scheduleDialog = false"
    @confirm="setupAutoSchedule($event)"
  />

  <SelectMembers v-model="selectDialog" :team="config" />
</template>

<script lang="ts">
  import { useClipboard } from '@vueuse/core'

  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { DarwinUrl, Debounce } from '@jouzen/outo-toolkit-vuetify'

  import { jzlogFilesHeaders, orbFilesHeaders, scheduleHeaders, usersHeaders } from '#views/rdata/constants'

  import { AppStore, RdataStore, TeamsStore } from '#stores'

  import { AutoSchedule, JzlogFile, OrbStream, Study, StudyUser, StudyUserWithMetadata } from '#types'

  @Component
  class ManageMembers extends Vue {
    @Prop() public config!: Study

    @Prop() public disabled!: boolean

    public selectDialog = false
    public scheduleDialog = false

    public loadingOrbFiles = false
    public loadingJzlogFiles = false

    public expandedUsers: any[] = []
    public selectedUsers: any[] = []

    public userOrbFiles: OrbStream[] = []
    public userJzlogFiles: JzlogFile[] = []

    public userTab = 0
    public activePage = 1
    public search: string = ''
    public userSearchInputError: string | null = null

    public readonly usersHeaders = usersHeaders
    public readonly scheduleHeaders = scheduleHeaders
    public readonly orbFilesHeaders = orbFilesHeaders
    public readonly jzlogFilesHeaders = jzlogFilesHeaders

    public readonly useClipboard = useClipboard()

    protected readonly appStore = new AppStore()
    protected readonly rdataStore = new RdataStore()
    protected readonly teamsStore = new TeamsStore()

    public get rights() {
      return this.appStore.rights
    }

    public get isLoading() {
      return this.rdataStore.loading || this.teamsStore.loading
    }

    public get configFiles() {
      return this.rdataStore.files
    }

    public get hasSelectedUsers() {
      return this.selectedUsers.length > 0
    }

    public get labelUsers() {
      return this.teamsStore.labelUsers
    }

    public get totalUsers() {
      return this.teamsStore.pagination?.total || 0
    }

    public get combinedUsersList() {
      const studyUsers = this.config?.studyUsers || []

      const minimumGoodORBSize = 5 * 1024 // 5Kb

      const filteredStudyUsers = studyUsers.filter((studyUser: StudyUser) =>
        this.labelUsers.some((labelUser: StudyUserWithMetadata) => labelUser.userUid === studyUser.id),
      )

      const combinedUsers = this.labelUsers.map((labelUser: StudyUserWithMetadata) => {
        const matchedUser = filteredStudyUsers.find((studyUser: StudyUser) => studyUser.id === labelUser.userUid)
        const fetchedAt = matchedUser?.fetchedAt || null

        let lastOrbUpload = labelUser?.orbUploads?.length
          ? Math.min(...labelUser.orbUploads.map((orb) => this.$dayjs(orb.uploadedAt).valueOf()))
          : null

        const warnings = {
          hasNoOrbUploads: labelUser?.orbUploads?.length === 0,
          hasSmallUploads: labelUser?.orbUploads.some((orb) => orb.binarySize < minimumGoodORBSize),
        }

        return {
          ...labelUser,
          lastOrbUpload,
          warnings,
          fetchedAt,
        } as StudyUserWithMetadata
      })

      return combinedUsers
    }

    public get isResearchDataAdmin() {
      return this.appStore.isResearchDataAdmin
    }

    @Debounce(500)
    public async loadUsers(options: { page: number; search: string; itemsPerPage: number }) {
      const pagination = this.teamsStore.pagination

      const requestPayload: any = {
        team: this.config,
        search: options?.search,
        itemsPerPage: options?.itemsPerPage,
        path: '',
        page: options?.page,
      }

      if (options?.page === 1 && options?.itemsPerPage) {
        requestPayload.path = ''
      } else if (options?.page > this.activePage) {
        requestPayload.path = pagination?.next || ''
      } else if (options?.page < this.activePage) {
        requestPayload.path = pagination?.prev || ''
      }

      if (!options?.search) {
        this.userSearchInputError = null
      } else if (options?.search?.includes('@')) {
        if (!this.rights.includes('allowPersonalDataAccess')) {
          this.userSearchInputError = 'You do not have permission to search by email. Please use UUID instead.'
          return
        }

        const userUUIDs = await this.convertEmailToUUID(this.search)

        if (userUUIDs?.length) {
          requestPayload.search = userUUIDs[0]
        } else {
          this.userSearchInputError = 'No users found with this email'
          return
        }
      }

      this.activePage = options?.page

      this.teamsStore.fetchLabelUsers(this.config.appFlavor, requestPayload)
    }

    public async loadUserData(event: string[]) {
      this.expandedUsers = event.length ? event.splice(-1) : []

      if (this.expandedUsers.length) {
        const userId = this.expandedUsers[0]

        this.userOrbFiles = []
        this.userJzlogFiles = []

        this.loadingOrbFiles = true
        this.loadingJzlogFiles = true

        this.userOrbFiles = await this.teamsStore.listORBFiles(this.config.appFlavor, this.config, userId)
        this.userJzlogFiles = await this.teamsStore.listJzlogFiles(this.config.appFlavor, this.config, userId)

        this.loadingOrbFiles = false
        this.loadingJzlogFiles = false
      }
    }

    public async removeStudyUsers() {
      const confirm = await this.$confirm(
        'Remove selected users?',
        'Are you sure you want to remove selected users from the study?',
      )

      if (!confirm) {
        return
      }

      const deletedUsers = await this.teamsStore.deleteUsersFromLabel(
        this.config.appFlavor,
        this.config,
        this.selectedUsers,
      )

      // Remove auto schedules for deleted users
      this.config.autoSchedules = this.config.autoSchedules.filter((s: AutoSchedule) => !deletedUsers.includes(s.uuid))

      this.config.studyUsers = [
        ...this.config.studyUsers.filter((u: StudyUser) => !deletedUsers.find((userId: string) => userId === u.id)),
      ]

      this.selectedUsers = []

      await this.teamsStore.updateTeam(this.config.appFlavor, { ...this.config })
    }

    public downloadStudyUsers() {
      const csv = this.config.studyUsers.map((u: StudyUser) => {
        return {
          'ID': u.id,
          'Added at': u.addedAt ? this.$dayjs(u.addedAt).format('HH:mm:ss - DD MMM YYYY') : 'Just added',
          'Fetched at': u.fetchedAt ? this.$dayjs(u.fetchedAt).format('HH:mm:ss - DD MMM YYYY') : 'Not fetched yet',
        }
      })

      let csvContent = 'data:text/csv;charset=utf-8,'

      csvContent += [Object.keys(csv[0]).join(';'), ...csv.map((org: any) => Object.values(org).join(';'))].join('\n')

      const data = encodeURI(csvContent)
      const link = document.createElement('a')

      link.setAttribute('href', data)
      link.setAttribute('download', `${this.config.name}.csv`)

      link.click()
    }

    public getScheduleInfo(user: any) {
      const autoSchedule = this.config.autoSchedules.find((s: AutoSchedule) => s.uuid === user.id)

      if (!autoSchedule) {
        return 'No auto schedule set for this user'
      } else {
        const file = this.configFiles.find((f) => f.id === autoSchedule.file)

        if (!file) {
          return 'Auto scheduled file has been removed from the study'
        } else {
          return (
            `${file.name} is scheduled to be run at ${autoSchedule.time} ` +
            (autoSchedule.duration ? `for ${autoSchedule.duration} hours` : 'until memory runs out')
          )
        }
      }
    }

    public clearAutoSchedule() {
      this.$confirm(
        'Confirm auto schedule clearing',
        'Are you sure you want to clear auto schedule for selected users?',
      ).then((confirmed) => {
        if (confirmed) {
          this.config.autoSchedules = this.config.autoSchedules.filter((s: any) => !this.selectedUsers.includes(s.uuid))

          this.selectedUsers = []
        }
      })
    }

    public setupAutoSchedule(schedule: any) {
      this.selectedUsers.forEach((user) => {
        const config = this.config.autoSchedules.find((s: any) => s.uuid === user)

        if (!config) {
          this.config.autoSchedules.push({
            uuid: user,
            file: schedule.file,
            time: schedule.time,
            duration: schedule.duration || null,
          })
        } else {
          config.file = schedule.file
          config.time = schedule.time
          config.duration = schedule.duration || null
        }
      })

      this.scheduleDialog = false
      this.selectedUsers = []
    }

    public downloadOrbFile(orb: OrbStream) {
      this.teamsStore.downloadORBFile(this.config.appFlavor, this.config, orb)
    }

    public downloadJzlogFile(jzlog: JzlogFile) {
      this.teamsStore.downloadJzlogFile(this.config.appFlavor, this.config, jzlog)
    }

    @DarwinUrl()
    public goToDarwinUser(_appFlavor: string, userUrl: string) {
      window.open(userUrl, '_blank')
    }

    protected async convertEmailToUUID(email: string) {
      const searchData = await this.rdataStore.searchStudyUsersByEmail(email)

      return searchData?.userUids || []
    }
  }

  export default toNative(ManageMembers)
</script>
