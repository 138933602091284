import dayjs from 'dayjs'

export function validateVersion(version: string) {
  const versionPattern = /^\d{1,10}\.\d{1,10}\.\d{1,10}$/

  if (!!version && !versionPattern.test(version)) {
    return 'Invalid version'
  }

  return true
}

export function hasOuranianRollout(otaRollouts: any[]) {
  return otaRollouts.some((r: any) => r.state === 'active' && r.filters.includes('oura_internal'))
}

export function getCurrentPercentile(otaRollout: any) {
  return otaRollout.state === 'disabled' || otaRollout.state === 'pending'
    ? '0'
    : otaRollout.schedule.findLast((r: any) => dayjs(r.apply_at).isSameOrBefore(dayjs()))?.percentile
}

export function getCurrentScheduleStep(otaRollout: any) {
  return otaRollout.state === 'disabled' || otaRollout.state === 'pending'
    ? '0'
    : otaRollout.schedule.findLastIndex((r: any) => dayjs(r.apply_at).isSameOrBefore(dayjs())) + 1
}
