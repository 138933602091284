export const stateIcons: any = {
  active: 'mdi-rocket-outline',
  verified: 'mdi-check-outline',
  pending: 'mdi-clock-outline',
  disabled: 'mdi-close-outline',
  invalid: 'mdi-alert-outline',
  inactive: 'mdi-pencil-outline',
  unknown: 'mdi-help-circle-outline',
  completed: 'mdi-check-circle-outline',
}
export const stateColors: any = {
  active: 'green',
  verified: 'green',
  pending: 'orange',
  disabled: 'red',
  invalid: 'red',
  unknown: 'blue',
  inactive: 'black',
  completed: 'green',
}

export const appPlatforms = [
  {
    title: 'Any',
    value: '',
  },
  {
    title: 'Android',
    value: 'android',
  },
  {
    title: 'iOS',
    value: 'ios',
  },
]

export const rolloutStates = [
  {
    title: 'Any',
    value: '',
  },
  {
    title: 'Active',
    value: 'active',
  },
  {
    title: 'Pending',
    value: 'pending',
  },
  {
    title: 'Disabled',
    value: 'disabled',
  },
]

export const otafileStates = [
  {
    title: 'Any',
    value: '',
  },
  {
    title: 'Verified',
    value: 'verified',
  },
  {
    title: 'Pending',
    value: 'pending',
  },
  {
    title: 'Invalid',
    value: 'invalid',
  },
  {
    title: 'Disabled',
    value: 'disabled',
  },
]

export const appFlavors = [
  {
    title: 'Debug',
    value: 'debug',
  },
  {
    title: 'Experimental',
    value: 'experimental',
  },
  {
    title: 'Staging',
    value: 'staging',
  },
  {
    title: 'Research',
    value: 'research',
  },
  {
    title: 'Release',
    value: 'release',
  },
]

export const createSchedules = [
  {
    title: 'Full rollout 100%',
    value: 'daily-1',
  },
  {
    title: 'Weekly 5% -> 100%',
    value: 'weekly-5:100',
  },
  {
    title: 'Weekly 30% -> 100%',
    value: 'weekly-30:100',
  },
  {
    title: 'Weekly 5% -> 30% -> 100%',
    value: 'weekly-5:30:100',
  },
  {
    title: 'Weekly 5% -> 50% -> 100%',
    value: 'weekly-5:50:100',
  },
  {
    title: 'Manual with given percentage',
    value: 'manual',
  },
  {
    title: 'Full rollout 100% (for 7 days)',
    value: 'daily-100:100:100:100:100:100:100:0',
  },
]

export const internalDomains = [
  {
    title: 'Both internal domains',
    value: '',
  },
  {
    title: 'ouraring.com',
    value: 'ouraring.com',
  },
  {
    title: 'ringtesting.com',
    value: 'ringtesting.com',
  },
]

export const rolloutTargets = [
  {
    title: 'All users (Oura members)',
    value: 'all',
  },
  {
    title: 'Only internal Oura users',
    value: 'oura',
  },
  {
    title: 'Only to specific email alias',
    value: 'group',
  },
  {
    title: 'Only to feature flag segment',
    value: 'label',
  },
  {
    title: 'Only to specific users sample',
    value: 'sample',
  },
]

export const createHeaders: any = [
  {
    key: 'version',
    title: 'Version',
  },
  {
    key: 'filename',
    title: 'Filename',
  },
  {
    key: 'size',
    title: 'Size',
  },
  {
    key: 'uploaded_at',
    title: 'Uploaded at',
    align: 'end',
  },
]

export const detailHeaders: any = [
  {
    key: 'apply_at',
    width: '50%',
    title: 'Date',
  },
  {
    key: 'percentile',
    title: 'Percentile',
  },
]

export const rolloutHeaders: any = [
  {
    key: 'value.version',
    title: 'Version',
    class: 'text-primary text-no-wrap',
  },
  {
    key: 'priority',
    title: 'Priority',
    class: 'text-primary text-no-wrap',
  },

  {
    key: 'percentile',
    title: 'Percentile',
    class: 'text-primary text-no-wrap',
  },
  {
    key: 'schedule',
    title: 'Step',
    class: 'text-primary text-no-wrap',
  },
  {
    key: 'platform',
    title: 'Platform',
    class: 'text-primary text-no-wrap',
  },
  {
    key: 'filters',
    title: 'Filters',
    class: 'text-primary text-no-wrap',
  },
  {
    key: 'created_at',
    title: 'Created',
    class: 'text-primary text-no-wrap',
    cellClass: 'text-no-wrap',
  },
  {
    key: 'state',
    title: 'State',
    class: 'text-primary',
    align: 'end',
  },
]

export const otafileHeaders: any = [
  {
    key: 'version',
    title: 'Version',
    class: 'text-primary text-no-wrap',
  },
  {
    key: 'filename',
    title: 'Filename',
    class: 'text-primary text-no-wrap ',
  },
  {
    key: 'size',
    title: 'Size',
    class: 'text-primary text-no-wrap',
  },
  {
    key: 'rollouts',
    title: 'Rollouts',
    class: 'text-primary text-no-wrap',
    cellClass: 'text-no-wrap',
  },
  {
    key: 'uploader',
    title: 'Uploader',
    class: 'text-primary text-no-wrap',
    cellClass: 'text-no-wrap',
  },
  {
    key: 'created_at',
    title: 'Created',
    class: 'text-primary text-no-wrap',
    cellClass: 'text-no-wrap',
  },
  {
    key: 'state',
    title: 'State',
    class: 'text-primary text-no-wrap',
    align: 'end',
  },
]
