<template>
  <v-row>
    <v-col cols="12">
      <v-sheet>
        <v-data-table
          v-model="selectedFiles"
          show-select
          item-value="path"
          no-data-text="No search results to show"
          :loading="isLoading"
          :headers="factoryFilesHeaders"
          :items="factoryFileUploads"
          :items-per-page="100"
        >
          <template #data-table-select="{ props }">
            <v-row class="ma-0 align-center" style="min-width: 82px">
              <v-checkbox v-bind="props" @click="selectAll = !selectAll" />
            </v-row>
          </template>

          <template #item.uploadedAt="{ item }">
            <p class="text-no-wrap">
              {{ $dayjs(item.uploadedAt).format('YYYY-MM-DD HH:mm') }}
            </p>
          </template>

          <template #item.path="{ item }">
            <p class="text-no-wrap">
              {{ item.path.split('.')[2] }}
            </p>
          </template>

          <template #item.action="{ item }">
            <v-btn
              icon="mdi-cloud-download"
              @click="
                downloadFiles({
                  bucket: item.bucket,
                  key: [item.path],
                })
              "
            />
          </template>
        </v-data-table>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script lang="ts">
  import { Component, Model, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { factoryFilesHeaders } from '#views/tests/constants'

  import { TestsStore } from '#stores'

  import { FactoryTestsTabs } from '#types/tests'

  @Component
  class JzlogFiles extends Vue {
    @Prop() public tab!: FactoryTestsTabs

    @Model() public selectedFiles = []

    public selectAll = false

    public factoryFilesHeaders = factoryFilesHeaders

    private testsStore = new TestsStore()

    public get isLoading() {
      return this.testsStore.loading
    }

    public get factoryFileUploads() {
      return this.testsStore.factoryFileUploads
    }

    public async downloadFiles(files: { bucket: string; key: any }) {
      await this.testsStore.downloadFiles(files)
    }
  }

  export default toNative(JzlogFiles)
</script>
