<template>
  <!-- eslint-disable vue/v-on-handler-style -->

  <v-dialog width="900" :model-value="true" :persistent="hasChanges" @update:model-value="close()">
    <v-card :loading="isLoading">
      <v-card-title v-if="otaFile || otaRollout">
        Edit rollouts for {{ hwTypeTitle }} - {{ selectedOtaFile?.version }}

        <v-spacer />

        <v-btn rounded icon="mdi-close" class="mt-n2 mr-n2" @click="close()" />
      </v-card-title>
      <v-card-title v-else>
        <v-menu offset-y :disabled="isLoading || !!selectedOtaRollout?.id" offset="10">
          <template #activator="{ props }">
            <div v-bind="props" class="d-flex flex-row flex-grow-1 align-center">
              Create new rollout for {{ hwTypeTitle }}
              {{ selectedOtaFile ? `- ${selectedOtaFile?.version}` : '' }}

              <v-spacer />

              <v-btn v-if="!selectedOtaFile" text="No file selected" color="warning" append-icon="mdi-alert-outline" />

              <v-btn
                v-else-if="!selectedOtaRollout?.id"
                rounded
                size="small"
                icon="mdi-pencil"
                class="mt-n1"
                variant="tonal"
              />
            </div>
          </template>

          <v-sheet class="pa-4" style="max-height: 300px; overflow: auto">
            <v-row class="align-center justify-space-between">
              <v-col cols="6">
                <v-select v-model="selectedHwType" label="Hardware type" :items="hardwareTypes" @click.stop />
              </v-col>
              <v-col cols="4">
                <v-switch v-model="showAllRollouts" label="Show beta / daily builds" @click.stop />
              </v-col>
            </v-row>

            <v-data-table
              hide-default-footer
              :loading="isLoading"
              :headers="createHeaders"
              :items="filteredOtaFiles"
              :items-per-page="20"
              :row-props="rowProperties"
              @click:row="(_event: any, row: any) => (selectedOtaFile = row.item)"
            />
          </v-sheet>
        </v-menu>
      </v-card-title>

      <v-card-text>
        <RolloutStepper
          :hw-type="selectedHwType"
          :app-flavor="selectedAppFlavor"
          :ota-file="selectedOtaFile"
          @select="selectedAppFlavor = $event"
        />
      </v-card-text>

      <v-card-text class="pt-0 mt-n4" style="max-height: 420px; overflow: auto">
        <RolloutStatus :app-flavor="selectedAppFlavor" :all-rollouts="filteredOtaRollouts" />

        <RolloutSelect
          :ota-rollout="selectedOtaRollout"
          :all-rollouts="filteredOtaRollouts"
          @create="newRollout(true)"
          @select="selectedOtaRollout = $event"
        />

        <RolloutConfig
          v-if="selectedOtaRollout"
          :hw-type="selectedHwType"
          :app-flavor="selectedAppFlavor"
          :ota-rollout="selectedOtaRollout"
          :all-rollouts="filteredOtaRollouts"
          @valid="readyForLaunch = $event"
        />
      </v-card-text>

      <v-card-actions class="pt-4">
        <RolloutActions
          :undo="filteredOtaRollouts.length && !selectedOtaRollout.id"
          :cancel="!otaFile && !otaRollout"
          :launch="readyForLaunch"
          :hw-type="selectedHwType"
          :app-flavor="selectedAppFlavor"
          :ota-rollout="selectedOtaRollout"
          :all-rollouts="filteredOtaRollouts"
          @launch="close($event)"
          @cancel="close()"
          @undo="undo()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { cloneDeep, isEqual } from 'lodash-es'

  import { Component, Emit, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { RING_STATE, RING_TYPE, getRingTypesByState } from '@jouzen/outo-toolkit-vuetify'

  import { createHeaders } from '#views/otafr/constants'

  import { AppStore, OtaFRStore, SetupStore } from '#stores'

  import { OtaFile, OtaRollout } from '#types'

  @Component
  class ModifyRollout extends Vue {
    @Prop() public hwType!: string
    @Prop() public appFlavor!: string

    @Prop() public otaFile!: OtaFile | undefined
    @Prop() public otaRollout!: OtaRollout | undefined

    public readyForLaunch = false
    public showAllRollouts = false

    public selectedHwType = 'gen2x'
    public selectedAppFlavor = 'debug'

    public selectedOtaFile: OtaFile | null = null
    public selectedOtaRollout: any = null
    public originalOtaRollout: any = null

    public readonly hardwareTypes = getRingTypesByState([
      ~RING_STATE.DEPRECATED,
      RING_STATE.INTERNAL | RING_STATE.RELEASED,
    ])

    public readonly createHeaders = createHeaders

    protected readonly appStore = new AppStore()

    protected readonly otaFRStore = new OtaFRStore()
    protected readonly setupStore = new SetupStore()

    public get isLoading() {
      return this.otaFRStore.loading
    }

    public get hasChanges() {
      return !isEqual(this.selectedOtaRollout, this.originalOtaRollout)
    }

    public get filteredOtaRollouts() {
      return (this.otaFRStore.rollouts?.[this.selectedHwType as RING_TYPE] || []).filter(
        (r: any) =>
          r.flavor === this.selectedAppFlavor &&
          r.value.type === this.selectedOtaFile?.type &&
          r.value.version === this.selectedOtaFile?.version,
      )
    }

    public get filteredOtaFiles() {
      return (this.otaFRStore.files?.[this.selectedHwType as RING_TYPE] ?? []).filter(
        (f: any) =>
          f.state === 'verified' &&
          f.type === `firmware_${this.selectedHwType}` &&
          (this.showAllRollouts || (f.version.split('.')[0] < 200 && f.version.split('.')[2] < 100)) &&
          !this.otaFRStore.rollouts?.[this.selectedHwType as RING_TYPE].find(
            (r: any) => r.value.type === `firmware_${this.selectedHwType}` && r.value.version === f.version,
          ),
      ) as any[]
    }

    public get hwTypeTitle() {
      return this.hardwareTypes.find((t) => t.value === this.selectedHwType)?.title || 'Unknown'
    }

    @Emit('undo')
    public undo() {
      this.filteredOtaRolloutsChanged()

      return null
    }

    @Emit('close')
    public close(params?: any) {
      return params
    }

    @Watch('otaFile')
    protected otaFileChanged() {
      this.filteredOtaFilesChanged()
    }

    @Watch('otaRollout')
    protected otaRolloutChanged() {
      this.filteredOtaRolloutsChanged()
    }

    @Watch('hwType', { immediate: true })
    protected hwTypeChanged() {
      this.selectedHwType = this.hwType || 'gen2x'
    }

    @Watch('appFlavor', { immediate: true })
    protected appFlavorChanged() {
      this.selectedAppFlavor = this.appFlavor || 'debug'

      this.otaFRStore.listRolloutLabels(this.selectedAppFlavor)

      this.setupStore.listSampleFilters(this.selectedAppFlavor)
    }

    @Watch('selectedOtaFile', { immediate: true })
    protected selectedOtaFileChanged() {
      if (this.selectedOtaRollout && !this.selectedOtaRollout.id) {
        this.selectedOtaRollout.value = {
          type: this.selectedOtaFile?.type,
          slug: this.selectedOtaFile?.slug,
          version: this.selectedOtaFile?.version,
        }
      }
    }

    @Watch('selectedOtaRollout', { immediate: true })
    protected selectedOtaRolloutChanged() {
      this.originalOtaRollout = this.selectedOtaRollout ? cloneDeep(this.selectedOtaRollout) : null
    }

    @Watch('filteredOtaFiles', { immediate: true })
    protected filteredOtaFilesChanged() {
      this.selectedOtaFile = this.otaFile || this.filteredOtaFiles[0]
    }

    @Watch('filteredOtaRollouts', { immediate: true })
    protected filteredOtaRolloutsChanged() {
      if (this.otaRollout) {
        this.selectedOtaRollout = this.filteredOtaRollouts.find((r: any) => r.slug === this.otaRollout!.slug)
      } else {
        this.selectedOtaRollout = this.filteredOtaRollouts[this.filteredOtaRollouts.length - 1]
      }

      if (!this.selectedOtaRollout) {
        this.selectedOtaRollout = this.newRollout()
      }
    }

    public newRollout(set?: boolean) {
      const rollout = {
        type: 'otafile',
        flavor: this.selectedAppFlavor,
        filters: [],
        info: {
          source: 'poirot',
          actor: this.appStore.user.email,
        },
        value: this.selectedOtaFile
          ? {
              type: this.selectedOtaFile?.type,
              slug: this.selectedOtaFile?.slug,
              version: this.selectedOtaFile?.version,
            }
          : null,
        schedule:
          this.selectedAppFlavor === 'release'
            ? {
                style: 'weekly',
                step_list: [5, 30, 100],
                date_start: this.$dayjs().utc(true).toISOString().split('T')[0],
              }
            : {
                style: 'daily',
                step_count: 1,
                date_start: this.$dayjs().utc(true).toISOString().split('T')[0],
              },
      }

      if (set) {
        this.selectedOtaRollout = rollout
      }

      return rollout
    }

    public rowProperties(data: any) {
      return data.item.slug === this.selectedOtaFile?.slug ? { class: 'bg-secondary' } : null
    }
  }

  export default toNative(ModifyRollout)
</script>

<style lang="scss" scoped>
  .state {
    &.active,
    &.verified {
      color: green;
    }

    &.pending,
    &.disabled {
      color: orange;
    }

    &.invalid {
      color: red;
    }
  }
</style>
