<template>
  <v-dialog v-if="open && edited" persistent width="800px" :model-value="open && !confirmDialog && !isRerun">
    <v-card>
      <v-card-title>Create new {{ mode }} samples analyser task</v-card-title>

      <v-card-text>
        <v-sheet style="min-height: 400px; max-height: 400px; overflow: hidden">
          <v-row>
            <v-col cols="12">
              <v-alert
                v-if="!isValidSource"
                type="warning"
                :text="`${sourceTitle} is not valid Jzlog source for ${mode} samples.`"
              />
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="edited.info.name"
                autofocus
                hide-details
                persistent-placeholder
                label="Name for the task"
                placeholder="Type descriptive name for the task..."
              />
            </v-col>

            <v-col cols="6">
              <v-select
                hide-details
                persistent-placeholder
                label="Jzlog source"
                :items="jzlogSources"
                :model-value="source"
                @update:model-value="updateSource($event)"
              />
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="edited.info.description"
                hide-details
                persistent-placeholder
                label="Description for the task"
                placeholder="Type descriptive info about the task if needed..."
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <TaskConfig v-show="!advancedSetup" :mode="mode" :task="edited" :source="source" />

              <TaskBinary v-show="advancedSetup" :mode="mode" :task="edited" :source="source" />
            </v-col>
          </v-row>
        </v-sheet>
      </v-card-text>

      <v-card-actions>
        <v-btn v-if="advancedSetup" text="Analyzer settings" color="primary" @click="advancedSetup = !advancedSetup" />
        <v-btn v-else text="Command settings" color="primary" @click="advancedSetup = !advancedSetup" />

        <v-spacer />

        <v-btn text="Cancel" class="mr-2" @click="close()" />

        <v-btn
          color="primary"
          text="Run analyzer"
          :loading="creating"
          :disabled="!isValidSource || !isFormValid"
          @click="confirmDialog = true"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-if="open && edited" persistent width="800px" :model-value="(open && confirmDialog) || isRerun">
    <v-card>
      <v-card-title>Confirm your settings for this run</v-card-title>

      <v-alert v-if="saveError" type="error" class="text-center">{{ saveError }}</v-alert>

      <v-card-text class="d-flex justify-center mt-3">
        <v-table density="compact" style="max-width: 600px">
          <template #default>
            <tbody>
              <tr v-if="edited.info.name">
                <td class="text-right font-weight-medium">Name:</td>
                <td class="text-truncate" data-qa="name" style="max-width: 200px">{{ edited.info.name }}</td>
              </tr>
              <tr v-if="edited.info.description">
                <td class="text-right font-weight-medium">Description:</td>
                <td class="text-truncate" style="max-width: 200px">
                  {{ edited.info.description }}
                </td>
              </tr>

              <tr v-if="edited.parameters.fwVersions">
                <td class="text-right font-weight-medium">Targeted firmware version(s):</td>
                <td data-qa="fw">{{ edited.parameters.fwVersions.replace(/,/g, ', ') }}</td>
              </tr>
              <tr v-if="edited.parameters.hwType && !isEditedUpload">
                <td class="text-right font-weight-medium">Targeted ring type:</td>
                <td data-qa="hw">
                  {{ targetedRingTypeName }}
                </td>
              </tr>
              <tr v-if="edited.parameters.deviceModels">
                <td class="text-right font-weight-medium">Targeted device models:</td>
                <td>
                  <span v-if="edited.parameters.deviceModels">
                    {{
                      (Array.isArray(edited.parameters.deviceModels)
                        ? edited.parameters.deviceModels.join('; ')
                        : edited.parameters.deviceModels) || 'Any'
                    }}
                  </span>
                </td>
              </tr>
              <tr
                v-if="
                  edited.parameters.osSplit &&
                  ![TaskConfigModes.UPLOAD, TaskConfigModes.SELECT].includes(edited.parameters.analyzeSampleMode)
                "
              >
                <td class="text-right font-weight-medium">Operating system split:</td>
                <td data-qa="os">
                  {{
                    edited.parameters.osSplit === 'ios:100'
                      ? 'iOS: 100%'
                      : edited.parameters.osSplit === 'ios:0'
                        ? 'Android: 100%'
                        : 'Android: 50%, iOS: 50%'
                  }}
                </td>
              </tr>
              <tr v-if="edited.parameters.maxUsers && !isEditedUpload">
                <td class="text-right font-weight-medium">Max number of users:</td>
                <td>{{ edited.parameters.maxUsers }}</td>
              </tr>

              <tr v-if="edited.parameters.inputCommand">
                <td class="text-right font-weight-medium">Command to run:</td>
                <td data-qa="input-command">{{ edited.parameters.inputCommand }}</td>
              </tr>
              <tr>
                <td class="text-right font-weight-medium">Command run branch:</td>
                <td>
                  <div data-qa="branch">
                    {{ edited.parameters.inputCommandBranch || tlogBranch }}
                  </div>
                </td>
              </tr>
              <tr>
                <td class="text-right font-weight-medium">Tlog run options:</td>
                <td>
                  <div v-if="edited.parameters.fwVersionListId || edited.parameters.inputOptions">
                    {{
                      edited.parameters.fwVersionListId
                        ? tlogOptions(edited.parameters.fwVersionListId)
                        : edited.parameters.inputOptions
                    }}
                  </div>
                  <div v-else data-qa="versions">
                    <span v-if="tlogVersionsPrefs">{{ tlogOptions(tlogVersionsPrefs) }}</span>
                    <span v-else>{{ tlogOptionPrefs === 'all' ? '-run_all' : tlogOptionsPrefs }}</span>
                  </div>
                </td>
              </tr>
              <tr v-if="isEditedUpload && edited.file">
                <td class="text-right font-weight-medium">Uploaded file:</td>
                <td data-qa="upload-file">{{ edited.file ? edited.file['name'] : 'no file found' }}</td>
              </tr>
              <tr v-if="edited.parameters.whitelistedFilters">
                <td class="text-right font-weight-medium">Selected list:</td>
                <td>{{ getSampleFilterName(edited.parameters.whitelistedFilters, 'name') }}</td>
              </tr>
              <tr v-if="edited.parameters.blacklistedFilters">
                <td class="text-right font-weight-medium">Rings to be excluded:</td>
                <td>{{ getSampleFilterName(edited.parameters.blacklistedFilters, 'name') }}</td>
              </tr>
              <tr v-if="source !== JzlogSources.FACTORY && edited.parameters.blacklistedLabels">
                <td class="text-right font-weight-medium">Extra run info:</td>
                <td>Excluding testing labels</td>
              </tr>

              <tr v-if="edited.parameters.factory">
                <td class="text-right font-weight-medium">Factory:</td>
                <td>{{ edited.parameters.factory }}</td>
              </tr>
              <tr v-if="edited.parameters.testPhase">
                <td class="text-right font-weight-medium">Test phase:</td>
                <td>{{ edited.parameters.testPhase }}</td>
              </tr>
              <tr v-if="edited.parameters.timestampFrom">
                <td class="text-right font-weight-medium">Start date:</td>
                <td>
                  {{ $dayjs(edited.parameters.timestampFrom).format('YYYY-MM-DD') }}
                </td>
              </tr>
              <tr v-if="edited.parameters.timestampTo">
                <td class="text-right font-weight-medium">End date:</td>
                <td>
                  {{ $dayjs(edited.parameters.timestampTo).format('YYYY-MM-DD') }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-table>
      </v-card-text>

      <v-card-actions>
        <v-btn v-if="!isRerun" text="Back to edit" class="mr-2" :disabled="loading" @click="editChanges()" />

        <v-spacer />

        <v-btn text="Cancel" class="mr-2" @click="close()" />

        <!--only rerun task data has analyzeSampleMode key-->
        <v-btn
          color="primary"
          text="Start run"
          :loading="creating"
          :disabled="!isValidConfiguration"
          @click="createTask()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { cloneDeep } from 'lodash-es'

  import { Component, Emit, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { Debounce, RING_TYPE, getRingTypeByValue } from '@jouzen/outo-toolkit-vuetify'

  import { TaskHelpers } from '#common/TaskHelpers.vue'

  import { createDefaultTask, jzlogSources } from '#views/tasks/constants'

  import { PrefsStore, SetupStore, TasksStore } from '#stores'

  import { JzlogSources, TaskConfigModes, TaskSettings } from '#types'

  @Component
  class CreateTask extends mixins(TaskHelpers) {
    @Prop({ default: false }) public open!: boolean
    @Prop({ default: createDefaultTask }) public task!: TaskSettings

    @Prop({ required: true }) public source!: string
    @Prop({ required: true }) public mode!: TaskConfigModes

    @Emit('update:source')
    public updateSource(source: string) {
      this.updateSampleFilters(source)

      return source
    }

    public saveError = ''

    public confirmDialog = false
    public advancedSetup = false
    public creating = false

    public edited: TaskSettings | null = null

    public readonly jzlogSources = jzlogSources
    public readonly JzlogSources = JzlogSources
    public readonly TaskConfigModes = TaskConfigModes
    public readonly env = import.meta.env.VITE_APP_ENV

    public readonly getRingTypeByValue = getRingTypeByValue

    private readonly tasksStore = new TasksStore()
    private readonly prefsStore = new PrefsStore()
    private readonly setupStore = new SetupStore()

    @Emit('close')
    public close() {
      this.saveError = ''

      return null
    }

    @Watch('task', { immediate: true })
    protected taskChanged() {
      this.edited = cloneDeep(this.task)
    }

    public editChanges() {
      this.saveError = ''

      this.confirmDialog = false
    }

    public tlogOptions(id: string) {
      return this.tlogSampleFilters.find((f: any) => f.uid === id)?.name ?? ''
    }

    public get loading() {
      return this.setupStore.loading
    }

    public get daysLimit() {
      return this.prefsStore.daysLimit
    }

    public get tlogBranch() {
      return this.prefsStore.tlogBranch
    }

    public get tlogBranchesPrefs() {
      return this.prefsStore.tlogBranches
    }

    public get tlogOptionPrefs() {
      return this.prefsStore.tlogOption
    }

    public get tlogOptionsPrefs() {
      return this.prefsStore.tlogOptions
    }

    public get tlogVersionsPrefs() {
      return this.prefsStore.fwVersions[this.source]
    }

    public get tlogSampleFilters() {
      return this.setupStore.sampleFilters.filter((f: any) => f.type === 'fw_version')
    }

    public get targetedRingTypeName() {
      const ringType = RING_TYPE[this.edited?.parameters?.hwType as keyof typeof RING_TYPE]

      return getRingTypeByValue(ringType)?.title || 'Unknown'
    }

    public get isFormValid() {
      if (!this.edited || this.loading || this.creating) {
        return false
      }

      const { analyzeSampleMode, factory, testPhase, timestampFrom, timestampTo, whitelistedFilters } =
        this.edited.parameters

      if (this.isEditedUpload && !this.edited.file) {
        return false
      }

      if (analyzeSampleMode === TaskConfigModes.SELECT) {
        if (this.source !== JzlogSources.FACTORY) {
          return !!whitelistedFilters
        }

        if (this.source === JzlogSources.FACTORY) {
          return !!(factory && testPhase && timestampFrom && timestampTo)
        }
      }

      return true
    }

    public get isValidConfiguration() {
      if (!this.edited || this.loading || this.creating) {
        return false
      }

      if (this.isEditedUpload && !this.edited.file) {
        return false
      }

      return true
    }

    public get isValidSource() {
      // Factory runs are avilable only for TaskConfigModes.SELECT samples
      if (this.source === JzlogSources.FACTORY) {
        return this.mode === TaskConfigModes.SELECT
      }

      return true
    }

    public get sourceTitle() {
      return jzlogSources.find((s) => s.value === this.source)?.title ?? ''
    }

    public get isRerun() {
      return this.mode === TaskConfigModes.RERUN
    }

    public get isEditedUpload() {
      return this.edited?.parameters.analyzeSampleMode === TaskConfigModes.UPLOAD
    }

    @Debounce(500)
    protected updateSampleFilters(source: string) {
      this.setupStore.listSampleFilters(source)
    }

    public async createTask() {
      this.saveError = ''
      this.creating = true

      let result: any = true

      this.edited!.parameters.appFlavor = this.source

      const fwVersions = this.edited!.parameters.fwVersions
      if (!fwVersions || fwVersions?.trim() === '') {
        this.edited!.parameters.fwVersions = undefined
      }

      if (this.mode === TaskConfigModes.UPLOAD) {
        result = await this.tasksStore.uploadFile(this.source, this.edited!)
      }

      if (result) {
        this.edited!.parameters.appFlavor = this.source === JzlogSources.FACTORY ? '' : this.source
        result = await this.tasksStore.createTask(this.source, this.edited!)

        if (result?.data && !result?.data?.preProcessingException) {
          this.close()
        } else {
          this.saveError =
            result?.data?.preProcessingException || 'Error occurred when creating a task, please report this!'
        }
        this.creating = false
      } else {
        this.saveError = 'Error occurred when uploading a sample file, malformed file?'
        this.creating = false
      }
    }
  }

  export default toNative(CreateTask)
</script>
