<template>
  <v-dialog v-model="open" persistent max-width="1000">
    <v-card>
      <v-card-title>Add study members</v-card-title>

      <v-card-text>
        <template v-if="currentView === DialogViews.AddView">
          <v-combobox
            v-model="selectedUsersToAdd"
            chips
            multiple
            autofocus
            closable-chips
            prepend-icon="mdi-account-multiple-plus"
            label="Type user UID to add"
            hint="Press ENTER after each user UID addition"
            :rules="[validateUserUids]"
          />

          <div class="text-center ma-6"><b>OR</b></div>

          <v-file-input
            label="Select CSV file to import from"
            hint="You need to have one uuid per row and nothing else"
            @update:model-value="importStudyUsers($event as any)"
          />
        </template>

        <template v-if="currentView === DialogViews.MoveView">
          <v-alert type="success" variant="text">
            {{ addedUsers.length }} users were successfully added to this study.
          </v-alert>
          <v-alert type="error" variant="text">
            The following users already belong to another study. Select users and click 'Move' to transfer them to this
            study. Otherwise, they will be skipped. Click 'Skip' to proceed without adding these users.
          </v-alert>

          <v-data-table
            v-model="selectedUsersToMove"
            item-value="userUid"
            item-selectable="selectable"
            :headers="teamUsersHeaders"
            :items="conflictingUsers"
            height="400"
            :items-per-page="5"
          >
            <template #header.actions="{ selectAll, allSelected }">
              <v-checkbox :model-value="allSelected" @update:model-value="selectAll(!allSelected)" />
            </template>

            <template #item.userUid="{ item }">
              {{ item?.userUid }}
            </template>

            <template #item.status="{ item }">
              {{ item?.status }}
            </template>

            <template #item.studyId="{ item }">
              {{ getStudyId(item?.conflictingLabels) }}
            </template>

            <template #item.actions="{ internalItem, isSelected, toggleSelect }">
              <v-checkbox :model-value="isSelected(internalItem)" @update:model-value="toggleSelect(internalItem)" />
            </template>
          </v-data-table>
        </template>

        <template v-if="currentView === DialogViews.NotFoundView">
          <v-alert type="warning" variant="text">
            The following users could not be found and were not added to this study. They might exist in a different
            environment or their accounts have been deleted.
          </v-alert>

          <v-data-table :headers="notFoundUsersHeaders" :items="notFoundUsers" height="400" :items-per-page="5" />
        </template>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          :text="
            currentView === DialogViews.AddView ? 'Cancel' : currentView === DialogViews.MoveView ? 'Skip' : 'Finish'
          "
          class="mr-2"
          @click="cancel()"
        />

        <v-btn
          v-if="currentView === DialogViews.AddView || currentView === DialogViews.MoveView"
          :text="currentView === DialogViews.AddView ? 'Add' : 'Move'"
          color="primary"
          :disabled="!canAddOrMoveUsers"
          @click="currentView === DialogViews.AddView ? addUsers() : moveUsers()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { validate, version } from 'uuid'

  import { Component, Model, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { notFoundUsersHeaders, teamUsersHeaders } from '#views/rdata/constants'

  import { AppStore, TeamsStore } from '#stores'

  import { StudyUser } from '#types'

  enum DialogViews {
    AddView = 1,
    MoveView = 2,
    NotFoundView = 3,
  }

  @Component
  class SelectMembers extends Vue {
    @Model() public open!: boolean

    @Prop() public team!: any

    public addedUsers: any[] = []
    public notFoundUsers: any[] = []
    public conflictingUsers: any[] = []
    public selectedUsersToAdd: string[] = []
    public selectedUsersToMove: string[] = []
    public currentView = DialogViews.AddView

    public readonly DialogViews = DialogViews
    public readonly teamUsersHeaders = teamUsersHeaders
    public readonly notFoundUsersHeaders = notFoundUsersHeaders

    protected readonly appStore = new AppStore()
    protected readonly teamsStore = new TeamsStore()

    public get isLoading() {
      return this.teamsStore.loading
    }

    public get canAddOrMoveUsers() {
      if (this.isLoading) {
        return false
      }

      if (this.currentView === DialogViews.MoveView) {
        return this.selectedUsersToMove.length > 0
      }

      return this.validateUserUids(this.selectedUsersToAdd) === true && this.selectedUsersToAdd.length > 0
    }

    @Watch('open')
    public openChanged() {
      // Reset the dialog state when it is opened
      if (this.open) {
        this.resetDialog()
      }
    }

    public importStudyUsers(file: File) {
      if (!file) {
        this.selectedUsersToAdd = []
      } else {
        const reader = new FileReader()

        reader.readAsText(file, 'UTF-8')

        reader.onload = async (event: any) => {
          this.selectedUsersToAdd = event.target.result
            .split('\n')
            .map((u: string) => u.trim())
            .filter((u: string) => validate(u))
            .filter((u: string) => !this.team.studyUsers.find((su: StudyUser) => su.id === u))
        }
      }
    }

    public validateUserUids(uuids: string[]): string | true {
      const invalid = uuids.find((uuid: string) => !validate(uuid) || version(uuid) !== 4)

      return invalid ? `Not a valid uuid: ${invalid}` : true
    }

    public async addUsers() {
      const allUsers =
        (await this.teamsStore.addUsersToLabel(this.team.appFlavor, this.team, this.selectedUsersToAdd, {
          labelPrefix: 'research:id:',
          strategy: 'report',
        })) || []

      for (const u of allUsers) {
        const { userUid, status, conflictingLabels } = u
        if (status === 'added') {
          this.addedUsers.push({
            userUid,
            status,
          })
        }
        if (status === 'conflicts') {
          this.conflictingUsers.push({
            userUid,
            status,
            conflictingLabels,
          })
        } else if (status === 'not_found') {
          this.notFoundUsers.push({
            userUid,
            status: 'not found',
          })
        }
      }

      if (this.conflictingUsers?.length) {
        this.currentView = DialogViews.MoveView
      } else if (this.notFoundUsers?.length) {
        this.currentView = DialogViews.NotFoundView
      } else {
        this.open = false
      }
    }

    public async moveUsers() {
      await this.teamsStore.addUsersToLabel(this.team.appFlavor, this.team, this.selectedUsersToMove, {
        labelPrefix: 'research:id:',
        strategy: 'move',
      })

      // After moving users, display users with status 'not_found' if there are any. Otherwise close the dialog.
      if (this.notFoundUsers?.length) {
        this.currentView = DialogViews.NotFoundView
      } else {
        this.open = false
      }
    }

    public cancel() {
      if (this.currentView === DialogViews.MoveView && this.conflictingUsers?.length) {
        this.currentView = DialogViews.NotFoundView
      } else {
        this.open = false
      }
    }

    public getStudyId(conflictingLabels: string[]) {
      return conflictingLabels.map((label: string) => label.split(':').pop()).join(', ')
    }

    private resetDialog() {
      this.currentView = DialogViews.AddView
      this.selectedUsersToAdd = []
      this.selectedUsersToMove = []
      this.addedUsers = []
      this.conflictingUsers = []
      this.notFoundUsers = []
    }
  }

  export default toNative(SelectMembers)
</script>
