<template>
  <v-row class="mt-4">
    <v-col cols="12" md="2">
      <v-text-field
        v-model.trim="file.serialNo"
        clearable
        persistent-placeholder
        min-length="10"
        max-length="16"
        label="Serial No"
        validate-on="invalid-input lazy"
        placeholder="Enter full serial No"
        hint="Filtering by serial number will ignore other filters"
        :return-object="false"
        :rules="[serialNumberRule, requiredFiltersRule]"
        :persistent-hint="searchBySerialNo"
        @keydown.enter="updateSearch()"
        @click:clear="file.serialNo = ''"
      />
    </v-col>

    <v-col cols="12" md="2">
      <v-select
        v-model="file.factory"
        label="Factory"
        :items="factories"
        :disabled="searchBySerialNo"
        @keydown.enter="updateSearch()"
      />
    </v-col>

    <v-col cols="12" md="2">
      <v-menu
        v-model="earliestDateMenu"
        offset="8"
        min-width="auto"
        transition="scale-transition"
        :close-on-content-click="false"
      >
        <template #activator="{ props }">
          <v-text-field
            v-bind="props"
            v-model="file.timeStart"
            clearable
            validate-on="invalid-input lazy"
            :label="timeStartLabel"
            :rules="[timespanRule, requiredFiltersRule]"
            :disabled="searchBySerialNo"
            @keydown.enter="updateSearch()"
            @click:clear="file.timeStart = ''"
          />
        </template>

        <v-date-picker
          :model-value="file.timeStart ? $dayjs(file.timeStart).toDate() : undefined"
          @update:model-value="((file.timeStart = $dayjs($event).format('YYYY-MM-DD')), (earliestDateMenu = false))"
        />
      </v-menu>
    </v-col>

    <v-col cols="12" md="2">
      <v-menu
        v-model="latestDateMenu"
        offset="8"
        min-width="auto"
        transition="scale-transition"
        :close-on-content-click="false"
      >
        <template #activator="{ props }">
          <v-text-field
            v-bind="props"
            v-model="file.timeEnd"
            clearable
            validate-on="invalid-input lazy"
            :label="timeEndLabel"
            :rules="[timespanRule, requiredFiltersRule]"
            :disabled="searchBySerialNo"
            @keydown.enter="updateSearch()"
            @click:clear="file.timeEnd = ''"
          />
        </template>

        <v-date-picker
          :model-value="file.timeEnd ? $dayjs(file.timeEnd).toDate() : undefined"
          @update:model-value="((file.timeEnd = $dayjs($event).format('YYYY-MM-DD')), (latestDateMenu = false))"
        />
      </v-menu>
    </v-col>

    <template v-if="isFileTab">
      <v-col cols="12" md="2">
        <v-select
          v-model="file.testPhases"
          label="Test phase"
          :items="factoryTestPhases"
          :disabled="searchBySerialNo"
          @keydown.enter="updateSearch()"
        />
      </v-col>

      <v-col cols="12" md="2">
        <v-select
          v-model="file.hardwareType"
          label="Hardware type"
          :items="hardwareTypes"
          :disabled="searchBySerialNo"
          @keydown.enter="updateSearch()"
        />
      </v-col>
    </template>

    <template v-if="!isFileTab">
      <v-col cols="12" md="2">
        <v-select
          v-model="file.overallTestResult"
          label="Status"
          :items="statuses"
          :disabled="searchBySerialNo"
          @keydown.enter="updateSearch()"
        />
      </v-col>

      <v-col cols="12" md="2">
        <v-select
          v-model="file.testType"
          label="Test type"
          :items="types"
          :disabled="searchBySerialNo"
          @keydown.enter="updateSearch()"
        />
      </v-col>
    </template>
  </v-row>

  <v-expand-transition>
    <v-row v-show="extra">
      <v-col>
        <v-select
          v-model="file.productType"
          persistent-placeholder
          label="Product types"
          placeholder="Product type"
          :items="productTypes"
          :disabled="searchBySerialNo"
          @keydown.enter="updateSearch()"
        />
      </v-col>

      <template v-if="tab === FactoryTestsTabs.RINGS">
        <v-col>
          <v-select
            v-model="file.purpose"
            label="Purpose"
            :items="purposes"
            :disabled="searchBySerialNo"
            @keydown.enter="updateSearch()"
          />
        </v-col>

        <v-col>
          <v-select
            v-model="file.ringSize"
            label="Ring size"
            :items="sizes"
            :disabled="searchBySerialNo"
            @keydown.enter="updateSearch()"
          />
        </v-col>

        <v-col>
          <v-select
            v-model="file.ringColor"
            label="Ring color"
            :items="colors"
            :disabled="searchBySerialNo"
            @keydown.enter="updateSearch()"
          />
        </v-col>

        <v-col>
          <v-select
            v-model="file.ringModel"
            label="Ring model"
            :items="models"
            :disabled="searchBySerialNo"
            @keydown.enter="updateSearch()"
          />
        </v-col>
      </template>

      <v-col>
        <v-text-field
          v-model.trim="file.failedMeasNo"
          persistent-placeholder
          validate-on="invalid-input lazy"
          label="Failed Measurement No"
          placeholder="e.g. 090.001"
          :disabled="searchBySerialNo"
          :rules="[failedMeasNoRule]"
          @keydown.enter="updateSearch()"
        />
      </v-col>
    </v-row>
  </v-expand-transition>
</template>

<script lang="ts">
  import { getSearchParams, isValidFailedMeasNo, isValidFilters, isValidSerialNo, isValidTimeSpan } from './utilities'

  import { Dayjs } from 'dayjs'

  import { capitalize, isEmpty } from 'lodash-es'

  import { Component, Model, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { RING_STATE, getRingTypeValuesByState } from '@jouzen/outo-toolkit-vuetify'

  import {
    colors,
    factories,
    factoryTestPhases,
    leabProductTypes,
    maxTimespans,
    models,
    purposes,
    sanminaProductTypes,
    sizes,
    statuses,
    testTypes,
  } from '#views/tests/constants'

  import { TestsStore } from '#stores'

  import { FactoryFileUploadsParams, FactoryTestsTabs, SearchFilters, serialNoParam } from '#types/tests'

  @Component
  export class SearchFields extends Vue {
    @Prop() public tab!: FactoryTestsTabs
    @Prop() public extra!: boolean

    @Model() public fields!: { [key: string]: string }

    public file: SearchFilters = {
      productType: '',
      resultSize: '',
      serialNo: '',
      serialRegex: '',
      factory: '',
      testType: '',
      timeStart: '',
      timeEnd: '',
      purpose: '',
      ringSize: '',
      ringModel: '',
      ringColor: '',
      failedMeasNo: '',
      overallTestResult: '',
      testPhases: '',
      hardwareType: '',
    }

    public latestDateMenu = false
    public earliestDateMenu = false

    public readonly sizes = sizes
    public readonly models = models
    public readonly colors = colors
    public readonly types = testTypes
    public readonly statuses = statuses
    public readonly purposes = purposes
    public readonly factories = factories
    public readonly leabProductTypes = leabProductTypes
    public readonly factoryTestPhases = factoryTestPhases
    public readonly sanminaProductTypes = sanminaProductTypes

    public readonly FactoryTestsTabs = FactoryTestsTabs

    public readonly hardwareTypes = [
      { title: 'Any', value: '' },
      ...getRingTypeValuesByState([~RING_STATE.DEPRECATED, RING_STATE.INTERNAL | RING_STATE.RELEASED]).map((value) => ({
        title: capitalize(value),
        value,
      })),
    ]

    private readonly testsStore = new TestsStore()

    public get isFileTab() {
      return this.tab === FactoryTestsTabs.FILES
    }

    public get timeEndLabel() {
      return `Latest ${this.isFileTab ? 'uploaded at' : 'test'} date`
    }

    public get timeStartLabel() {
      return `Earliest ${this.isFileTab ? 'uploaded at' : 'test'} date`
    }

    public get productTypes() {
      const factoryProductTypes = ['leab', 'Any'].includes(this.file.factory) ? leabProductTypes : sanminaProductTypes

      return factoryProductTypes.filter((t) => !t.value || t.value.includes(this.productCategory))
    }

    public get maxTimespan() {
      return maxTimespans[this.tab]
    }

    public get timespanRule() {
      return isValidTimeSpan(this.file.timeStart, this.file.timeEnd, this.maxTimespan)
    }

    public get serialNumberRule() {
      return isValidSerialNo(this.file.serialNo)
    }

    public get failedMeasNoRule() {
      return isValidFailedMeasNo(this.file.failedMeasNo)
    }

    public get requiredFiltersRule() {
      return isValidFilters(this.file, this.tab)
    }

    public get isValidFields() {
      if (this.requiredFiltersRule !== true) {
        return false
      }

      if (this.searchBySerialNo) {
        return this.serialNumberRule === true
      }

      if (this.isFileTab) {
        return this.timespanRule === true
      }

      return this.timespanRule === true && this.failedMeasNoRule === true
    }

    public get searchBySerialNo() {
      return this.file.serialNo.length > 0
    }

    private get productCategory() {
      if (this.tab === FactoryTestsTabs.CHARGERS) {
        return 'charger'
      }

      return 'ring'
    }

    @Watch('tab')
    public tabChanged(newValue: FactoryTestsTabs, oldValue: FactoryTestsTabs) {
      const isRingOrCharger = (tab: FactoryTestsTabs) =>
        [this.FactoryTestsTabs.CHARGERS, this.FactoryTestsTabs.RINGS].includes(tab)

      if (isRingOrCharger(oldValue) && isRingOrCharger(newValue)) {
        return
      }

      this.setDefaultTimespan(this.$dayjs())
    }

    public mounted() {
      this.setDefaultTimespan(this.$dayjs())
    }

    public async updateSearch() {
      if (!this.isValidFields) {
        return
      }

      const params = getSearchParams(this.tab, this.file)

      if (this.isFileTab) {
        await this.testsStore.listFactoryFileUploads(params as FactoryFileUploadsParams | serialNoParam)
      } else {
        await this.testsStore.searchTests({
          category: this.productCategory,
          fields: isEmpty(params) ? undefined : params,
          path: '',
        })

        this.fields = params
      }
    }

    private setDefaultTimespan(date: Dayjs) {
      const fallbackTimespan = 30

      this.file.timeEnd = date.format('YYYY-MM-DD')
      this.file.timeStart = date.subtract(this.maxTimespan || fallbackTimespan, 'day').format('YYYY-MM-DD')
    }
  }

  export default toNative(SearchFields)
</script>
