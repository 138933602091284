import { chunk, uniq } from 'lodash-es'

import { Pinia, Store } from 'pinia-class-component'

import { httpsCallable } from 'firebase/functions'

import { Environment, RING_STATE, RING_TYPE, getRingTypeValuesByState } from '@jouzen/outo-toolkit-vuetify'

import { OtaFile, OtaRollout, UserLabel } from '#types'

const hwTypes = getRingTypeValuesByState([~RING_STATE.DEPRECATED, RING_STATE.INTERNAL | RING_STATE.RELEASED])

type otaFilesObject = { [key in RING_TYPE]: OtaFile[] }
type otaRolloutsObject = { [key in RING_TYPE]: OtaRollout[] }

@Store
export class OtaFRStore extends Pinia {
  public loading = false

  public labels: UserLabel[] | null = null

  public files: otaFilesObject | null = null
  public rollouts: otaRolloutsObject | null = null

  public async listFiles() {
    this.loading = true

    this.files = null

    const response = await httpsCallable(this.$functions, 'listOtaFilesFromFilero')({ hwTypes })

    this.files = (response?.data as otaFilesObject) || null

    this.loading = false
  }

  public async listRollouts() {
    this.loading = true

    this.rollouts = null

    const response = await httpsCallable(this.$functions, 'listOtaRolloutsFromFilero')({ hwTypes })

    this.rollouts = (response?.data as otaRolloutsObject) || null

    this.loading = false
  }

  public async changeState(data: { state: string; rollout: any }) {
    this.loading = true

    const response = await httpsCallable(this.$functions, 'updateOtaRolloutInFilero')(data)

    if (response?.data) {
      await this.listRollouts()
    }

    this.loading = false

    return response
  }

  public async createRollout(data: any) {
    this.loading = true

    const response = await httpsCallable(this.$functions, 'createOtaRolloutInFilero')(data)

    if (response?.data) {
      this.listRollouts()
    }

    this.loading = false

    return response
  }

  @Environment()
  public async listRolloutLabels(env: string) {
    this.labels = []

    this.loading = true

    const labelRes = await this.$axios.get(`/api/v1/admin/labels/`, { apiEnv: env })

    if (labelRes?.status === 200) {
      this.labels = (labelRes.data.labels || []).filter((l: any) => l.name.startsWith('criteria:'))
    }

    this.loading = false
  }

  @Environment()
  public async createRolloutLabel(env: string, data: any) {
    this.loading = true

    let response = await this.$axios.get(`/api/v1/admin/labels/${data.label}`, { apiEnv: env })

    if (response.status === 404) {
      response = await this.$axios.post('/api/v1/admin/labels', { name: data.label }, { apiEnv: env })
    }

    if (response.status === 200) {
      // For now we do not remove users not in the current list, this might be wanted or not in the future

      response.data.users = await Promise.all(
        chunk(uniq(data.users), 1000).map((u) =>
          this.$axios.post(`/api/v1/admin/labels/${data.label}/users`, { userUids: u }, { apiEnv: env }),
        ),
      )
    }

    this.loading = false

    return response
  }
}
