<template>
  <!-- eslint-disable vue/v-on-handler-style -->

  <v-menu :disabled="isLoading || allRollouts.length === 0">
    <template #activator="{ props }">
      <v-text-field v-bind="props" readonly hide-details class="mt-4">
        <template #prepend-inner>
          <v-icon color="primary" style="margin: 0 12px 0 6px">mdi-rocket</v-icon>

          <div v-if="allRollouts.length > 1" class="mx-2 text-primary">
            {{ (allRollouts.findIndex((r: any) => r.id === otaRollout.id) + 1 || allRollouts.length + 1) + ':' }}
          </div>
        </template>

        <template #default>
          <span v-if="!otaRollout.id">New rollout, not saved / launched yet</span>

          <template v-else>
            <v-tooltip text="Priority" location="top">
              <template #activator="{ props: tooltipProps }">
                <v-chip
                  v-bind="tooltipProps"
                  class="my-n2"
                  prepend-icon="mdi-order-numeric-ascending"
                  :text="otaRollout?.priority?.toString() || '50'"
                />
              </template>
            </v-tooltip>

            <v-tooltip v-if="!!otaRollout.info?.actor" text="Creator" location="top">
              <template #activator="{ props: tooltipProps }">
                <v-chip
                  v-bind="tooltipProps"
                  class="ml-2 my-n2"
                  prepend-icon="mdi-account-outline"
                  :text="otaRollout.info?.actor"
                />
              </template>
            </v-tooltip>

            <v-tooltip v-if="!!otaRollout.info?.source" text="Created in" location="top">
              <template #activator="{ props: tooltipProps }">
                <v-chip
                  v-bind="tooltipProps"
                  class="ml-2 my-n2"
                  prepend-icon="mdi-cloud-arrow-up-outline"
                  :text="otaRollout.info?.source?.replace('github', 'GitHub').replace('poirot', 'Poirot')"
                />
              </template>
            </v-tooltip>

            <v-tooltip v-if="!!otaRollout.created_at" text="Created at" location="top">
              <template #activator="{ props: tooltipProps }">
                <v-chip
                  v-bind="tooltipProps"
                  class="ml-2 my-n2"
                  prepend-icon="mdi-calendar-outline"
                  :text="$dayjs(otaRollout.created_at).format('DD MMM YYYY')"
                />
              </template>
            </v-tooltip>
          </template>
        </template>

        <template #append-inner>
          <v-chip
            class="ml-2 my-n2"
            variant="flat"
            :color="stateColors[otaRollout?.state] || 'black'"
            :text="otaRollout?.state?.toUpperCase() || 'INACTIVE'"
          />

          <v-icon v-if="allRollouts.length >= 1" class="ml-2" color="primary">mdi-menu-down</v-icon>
        </template>
      </v-text-field>

      <v-divider :class="allRollouts.length >= 1 ? 'text-primary' : ''" style="opacity: 1 !important" />
    </template>

    <v-sheet tile style="max-height: 300px; overflow: auto">
      <v-data-table
        hide-default-footer
        :items="allRollouts"
        :items-per-page="20"
        :row-props="rowProperties"
        :headers="rolloutHeaders.slice(1)"
        @click:row="(_event: any, row: any) => select(row.item)"
      >
        <template #item.created_at="{ item }">
          {{ $dayjs(item.created_at).format('DD MMM YYYY') }}
        </template>

        <template #item.percentile="{ item }">{{ currentPercentile(item) || '0' }}%</template>

        <template #item.filters="{ item }">
          {{ item.filters.map((f: any) => f.split(':').splice(0, 3).join(':')).join(',') || 'None' }}
        </template>

        <template #item.schedule="{ item }">{{ currentScheduleStep(item) }} / {{ item.schedule.length }}</template>

        <template #item.platform="{ item }">
          {{ item.platform ? (appPlatforms.find((p: any) => p.value === item.platform) || {}).title || '' : 'Both' }}
          {{ item.min_version && !item.max_version ? '>=' : '' }}
          {{ item.min_version ? item.min_version : '' }}

          {{ item.min_version && item.max_version ? '-' : '' }}

          {{ !item.min_version && item.max_version ? '=<' : '' }}
          {{ item.max_version ? item.max_version : '' }}
        </template>

        <template #item.state="{ item }">
          <span class="state" :class="item.state">{{ item.state.toUpperCase() }}</span>
        </template>
      </v-data-table>
    </v-sheet>

    <v-card tile>
      <v-card-actions>
        <v-btn
          color="error"
          text="Disable all rollouts"
          prepend-icon="mdi-close-outline"
          :disabled="allRollouts.every((r) => r.state === 'disabled')"
          @click="disableRollouts()"
        />

        <v-spacer />

        <v-btn
          color="success"
          text="Create new rollout"
          prepend-icon="mdi-plus-thick"
          :disabled="!otaRollout?.id"
          @click="create()"
        />
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script lang="ts">
  import { Component, Emit, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { appPlatforms, rolloutHeaders, stateColors } from '#views/otafr/constants'

  import { getCurrentPercentile, getCurrentScheduleStep } from '#views/otafr/utilities'

  import { OtaFRStore } from '#stores'

  import { OtaRollout } from '#types'

  @Component
  class RolloutSelect extends Vue {
    @Prop() public otaRollout!: OtaRollout

    @Prop() public allRollouts!: OtaRollout[]

    public readonly stateColors = stateColors
    public readonly appPlatforms = appPlatforms
    public readonly rolloutHeaders = rolloutHeaders

    public readonly currentPercentile = getCurrentPercentile
    public readonly currentScheduleStep = getCurrentScheduleStep

    protected readonly otaFRStore = new OtaFRStore()

    public get isLoading() {
      return this.otaFRStore.loading
    }

    @Emit('create')
    public create() {
      return true
    }

    @Emit('select')
    public select(item: any) {
      return item
    }

    public disableRollouts() {
      this.$confirm('Confirm disable all rollouts', `This will change all rollouts to disabled state, are you sure?`, {
        buttonTrueColor: 'error',
      }).then((confirmed) => {
        if (confirmed) {
          this.allRollouts.forEach((rollout) => {
            this.otaFRStore.changeState({ rollout, state: 'disabled' })
          })
        }
      })
    }

    public rowProperties(data: any) {
      return data.item.slug === this.otaRollout?.slug ? { class: 'bg-secondary' } : null
    }
  }

  export default toNative(RolloutSelect)
</script>
