<template>
  <template v-if="allRollouts.length === 0">
    <v-alert
      v-if="appFlavor !== 'release'"
      type="info"
      :text="`No rollouts made for ${appFlavor} app flavor yet, you can create one now!`"
    />
    <v-alert v-else type="info" :text="`No public rollout done yet, Ouranians rollout needs to be done first!`" />
  </template>

  <template v-else-if="allRollouts.length === 1 && appFlavor === 'release'">
    <v-alert
      v-if="allRollouts[0].filters.find((f: string) => f.includes('oura_internal'))"
      type="info"
      :text="`Ouranians rollout in ${allRollouts[0].state} state, public rollout can be created now`"
    />
    <v-alert
      v-else-if="rolloutsState === 'completed'"
      type="success"
      :text="`Public rollout has been completed successfully, but no ouranians rollout was made!`"
    />
    <v-alert
      v-else
      type="warning"
      :text="`Ouranians rollout not done, but there is a rollout in ${allRollouts[0].state} state`"
    />
  </template>

  <template v-else>
    <v-alert
      v-if="rolloutsState === 'active'"
      type="info"
      :text="`${statusSubject} active for ${appFlavor} app flavor and the firmware can be available to some users`"
    />
    <v-alert
      v-else-if="rolloutsState === 'paused'"
      type="warning"
      :text="`${statusSubject} paused for ${appFlavor} app flavor and the firmware can be available to some users`"
    />
    <v-alert
      v-else-if="rolloutsState === 'pending'"
      type="warning"
      :text="`${statusSubject} pending for ${appFlavor} app flavor, but still waiting for manual activation`"
    />
    <v-alert
      v-else-if="rolloutsState === 'disabled'"
      type="warning"
      :text="`${statusSubject} disabled for ${appFlavor} app flavor and has no effect for any of the users`"
    />
    <v-alert
      v-else-if="rolloutsState === 'completed'"
      type="success"
      :text="`Full rollout completed for ${appFlavor} app flavor and the firmware is available for all the users`"
    />
    <v-alert
      v-else
      type="info"
      :text="`There are multiple rollouts for ${appFlavor} app flavor, select rollout below to view the details`"
    />
  </template>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { OtaRollout } from '#types'

  @Component
  class RolloutStatus extends Vue {
    @Prop() public appFlavor!: string

    @Prop() public allRollouts!: OtaRollout[]

    public get statusSubject() {
      return this.allRollouts.length === 1 ? 'Rollout' : 'All rollouts'
    }

    public get rolloutsState() {
      const allEqual = (arr: string[]) => arr.every((val) => val === arr[0])

      const hasCompleted = this.allRollouts.some(
        (r) =>
          r.state === 'active' &&
          !r.filters.length &&
          r.schedule[r.schedule.length - 1].percentile === 100 &&
          this.$dayjs(r.schedule[r.schedule.length - 1].apply_at).isBefore(this.$dayjs()),
      )

      return hasCompleted
        ? 'completed'
        : allEqual(this.allRollouts.map((r) => r.state))
          ? this.allRollouts[0].state
          : 'unknown'
    }
  }

  export default toNative(RolloutStatus)
</script>
