import { cloneDeep, identity, omitBy, pick, pickBy } from 'lodash-es'

import { FactoryFileUploadsParams, FactoryTestsTabs, SearchFilters, serialNoParam } from '#types/tests'

const dateToMs = (date: string) => new Date(date).getTime()
const daysToMs = (days: number) => days * 24 * 60 * 60 * 1000
const dateToISOString = (date: string) => new Date(date).toISOString()

/**
 * Validate time span between two dates.
 * Start time must be before end time.
 * Start and end times must be within a certain number of days of one another. Unlimited by default.
 *
 * @param timeStart - Start time
 * @param timeEnd - End time
 * @param maxDaysApart - Maximum number of days between start and end times, 0 = unlimited (default)
 * @returns Error string | true for valid
 */
export const isValidTimeSpan = (timeStart: string, timeEnd: string, maxDaysApart = 0) => {
  const endMs = dateToMs(timeEnd)
  const startMs = dateToMs(timeStart)

  if (startMs > endMs) {
    return 'End time must be after start time'
  }

  if (maxDaysApart > 0 && endMs - startMs > daysToMs(maxDaysApart)) {
    return `Start and end time must be within ${maxDaysApart} days of one another`
  }

  return true
}

/**
 * Valid serial number should be between 10 and 16 characters.
 * Empty string is also considered valid.
 *
 * @param serialNo - Serial number to validate
 * @returns Error string | true for valid
 */
export const isValidSerialNo = (serialNo: string) => {
  if (serialNo.length !== 0 && serialNo.length < 10) {
    return 'Minimum 10 characters'
  }

  if (serialNo.length > 16) {
    return 'Maximum 16 characters'
  }

  return true
}

/**
 * Validate failed measurement number should be in format 000.000 (3 digits, dot, 3 digits).
 * Empty string is also considered valid.
 *
 * @param value - Failed measurement number to validate
 * @returns Error string | true for valid
 */
export const isValidFailedMeasNo = (value: string) => {
  const regex = /^\d{3}\.\d{3}$/

  if (value && !regex.test(value)) {
    return 'Incorrect input number. Expected format: 090.001 (3 digits, dot, 3 digits)'
  }

  return true
}

/**
 * Validates that required search filters are selected.
 *
 * @returns Error string | true for valid
 */
export const isValidFilters = (fields: SearchFilters, tab: FactoryTestsTabs) => {
  if (tab !== FactoryTestsTabs.FILES) {
    return true
  }

  const { serialNo, timeStart, timeEnd } = fields

  const hasRequiredFilters = serialNo.length || (timeStart.length && timeEnd.length)

  if (!hasRequiredFilters) {
    return 'Serial number or time range is required'
  }

  return true
}

/**
 * Returns search parameters for either factory files or ring or charger tests.
 */
export const getSearchParams = (tab: FactoryTestsTabs, fields: SearchFilters) => {
  const clonedFields: any = cloneDeep(fields)
  const { factory, hardwareType, testPhases, timeStart, timeEnd } = clonedFields

  // If serial number is present, return only serial number
  if (clonedFields.serialNo) {
    return pick(clonedFields, ['serialNo']) as serialNoParam
  }

  // File search paramaters
  if (tab === FactoryTestsTabs.FILES) {
    return omitBy(
      {
        factory,
        hardwareType,
        testPhase: testPhases,
        timestampFrom: timeStart && dateToISOString(timeStart),
        timestampTo: timeEnd && dateToISOString(timeEnd).replace('T00:00:00.000Z', 'T23:59:59.999Z'),
      },
      (value) => !value,
    ) as FactoryFileUploadsParams
  }

  // Ring or charger parameters
  if (timeStart) {
    clonedFields.timeRangeStart = dateToISOString(timeStart)
    clonedFields.timeStart = ''
  }

  if (timeEnd) {
    clonedFields.timeRangeEnd = dateToISOString(timeEnd).replace('T00:00:00.000Z', 'T23:59:59.999Z')
    clonedFields.timeEnd = ''
  }

  return omitBy(pickBy(clonedFields, identity), (value) => !value) as Partial<SearchFilters>
}
