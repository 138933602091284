<template>
  <v-card v-if="sensor !== 'System'">
    <v-toolbar>
      <v-switch
        class="px-4"
        color="primary"
        style="width: 100%"
        :disabled="disabled"
        :model-value="channelDescriptionValue !== ''"
        @update:model-value="setChannelEnabled(0, sensor, schema, config, $event)"
      >
        <template #label>
          <v-toolbar-title>{{ sensor }}</v-toolbar-title>
        </template>
      </v-switch>
    </v-toolbar>

    <v-card-text class="pa-6">
      <v-row>
        <v-col>
          <v-autocomplete
            hide-details
            label="Interval"
            density="compact"
            no-data-text="No matching items"
            :disabled="disabled || channelDescriptionValue === ''"
            :items="getFieldItems(sensor, schema, config, 'measurement_data_interval')"
            :model-value="getFieldValue(0, sensor, schema, config, 'measurement_data_interval')"
            @update:model-value="setFieldValue(0, sensor, schema, config, 'measurement_data_interval', $event)"
          />
        </v-col>

        <v-col v-if="schema?.definitions[sensor.toLowerCase() + '_data_scale']">
          <v-autocomplete
            hide-details
            label="Scale"
            density="compact"
            no-data-text="No matching items"
            :disabled="disabled || channelDescriptionValue === ''"
            :items="getFieldItems(sensor, schema, config, sensor.toLowerCase() + '_data_scale')"
            :model-value="getFieldValue(0, sensor, schema, config, sensor.toLowerCase() + '_data_scale')"
            @update:model-value="setFieldValue(0, sensor, schema, config, sensor.toLowerCase() + '_data_scale', $event)"
          />
        </v-col>
      </v-row>

      <v-row v-if="!!schema.definitions['measurement_data_format_id'] && sensor === 'ACM'">
        <v-col>
          <v-autocomplete
            hide-details
            label="Data format"
            density="compact"
            no-data-text="No matching items"
            :disabled="disabled || channelDescriptionValue === ''"
            :items="getFieldItems(sensor, schema, config, 'measurement_data_format_id')"
            :model-value="getFieldValue(0, sensor, schema, config, 'measurement_data_format_id')"
            @update:model-value="setFieldValue(0, sensor, schema, config, 'measurement_data_format_id', $event)"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>

  <v-card v-else>
    <v-toolbar>
      <v-switch
        class="px-4"
        color="primary"
        style="width: 100%"
        :disabled="disabled || !schema.definitions['system_led_voltage']"
        :model-value="getChannelEnabled(0, sensor, schema, config, 'system_led_voltage')"
        @update:model-value="setChannelEnabled(0, sensor, schema, config, $event, 'system_led_voltage')"
      >
        <template #label>
          <v-toolbar-title>
            LED Voltage

            <v-tooltip location="top">
              <template #activator="{ props }">
                <v-icon v-bind="props" color="primary" size="extra-small">mdi-information</v-icon>
              </template>

              <span>
                Value range:
                {{ schema.definitions?.system_led_voltage?.properties.value?.minimum }} -
                {{ schema.definitions?.system_led_voltage?.properties.value?.maximum }}
              </span>
            </v-tooltip>
          </v-toolbar-title>
        </template>
      </v-switch>
    </v-toolbar>

    <v-card-text class="pa-6">
      <v-row>
        <v-col>
          <v-text-field
            hide-details
            suffix="mV"
            type="number"
            label="Voltage"
            density="compact"
            validate-on="lazy blur"
            :model-value="getFieldValue(0, sensor, schema, config, 'system_led_voltage')"
            :rules="[(v: string) => setFieldValue(0, sensor, schema, config, 'system_led_voltage', v)]"
            :disabled="disabled || getFieldValue(0, sensor, schema, config, 'system_led_voltage') === ''"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import {
    getChannelEnabled,
    getFieldItems,
    getFieldValue,
    setChannelEnabled,
    setFieldValue,
  } from '#views/rdata/utilities'

  import { RingSensor } from '#types'

  @Component
  class EditingCard extends Vue {
    @Prop() public config!: any
    @Prop() public schema!: any

    @Prop() public sensor!: RingSensor

    @Prop() public disabled!: boolean

    public readonly getFieldItems = getFieldItems
    public readonly getFieldValue = getFieldValue
    public readonly setFieldValue = setFieldValue

    public readonly getChannelEnabled = getChannelEnabled
    public readonly setChannelEnabled = setChannelEnabled

    public get channelDescriptionValue() {
      return this.getFieldValue(0, this.sensor, this.schema, this.config, 'measurement_channel_description_id')
    }
  }

  export default toNative(EditingCard)
</script>
