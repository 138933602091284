<template>
  <!-- eslint-disable vue/v-on-handler-style -->
  <v-app-bar>
    <v-tabs style="max-width: 500px" :model-value="tab">
      <v-tab to="rollouts">Rollouts</v-tab>
      <v-tab to="otafiles">OTA Files</v-tab>
    </v-tabs>

    <v-spacer />

    <v-btn
      icon="mdi-reload"
      :color="isLoading ? 'primary' : ''"
      :loading="isLoading"
      @click="refreshOtaFilesAndRollouts()"
    />
  </v-app-bar>

  <v-container>
    <v-row>
      <v-col v-if="tab === 'rollouts'" cols="9">
        <div class="text-h5 font-weight-light">Firmware versions rolled out to the apps</div>

        <div class="text-subtitle-2 text-grey-darken-2 font-weight-light">
          <template v-if="isOtaRolloutsAdmin">
            You can create new rollouts and activate or disable existing ones
          </template>
          <template v-else>You can only view rollouts, apply for admin rights in IT portal</template>
        </div>
      </v-col>

      <v-col v-else-if="tab === 'otafiles'" cols="9">
        <div class="text-h5 font-weight-light">Firmware OTA files available for rollouts</div>

        <div class="text-subtitle-2 text-grey-darken-2 font-weight-light">
          <template v-if="isOtaRolloutsAdmin">
            You can create new rollouts and activate or disable existing ones
          </template>
          <template v-else>You can only view otafiles, apply for admin rights in IT portal</template>
        </div>
      </v-col>

      <v-col cols="3" class="d-flex justify-end align-top">
        <v-btn text="Create rollout" color="primary" :disabled="!isOtaRolloutsAdmin" @click="createRollout()" />
      </v-col>
    </v-row>

    <v-row class="mt-4 align-center">
      <v-col>
        <v-select v-model="routeParams.hwType" label="Hardware type" :items="hardwareTypes" />
      </v-col>
      <v-col v-if="tab === 'rollouts'">
        <v-select v-model="routeParams.appFlavor" label="App flavor" :items="appFlavors" />
      </v-col>
      <v-col v-if="tab === 'rollouts'">
        <v-select v-model="routeParams.appPlatform" label="App platform" :items="appPlatforms" />
      </v-col>
      <v-col v-if="tab === 'rollouts'">
        <v-select v-model="routeParams.rolloutState" label="Rollout state" :items="rolloutStates" />
      </v-col>
      <v-col v-if="tab === 'otafiles'">
        <v-select v-model="routeParams.otafileState" label="OTA file state" :items="otafileStates" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-sheet>
          <v-data-table
            v-if="tab === 'rollouts'"
            no-data-text="No rollouts with selected filters"
            :loading="isLoading"
            :headers="rolloutHeaders"
            :items="firmwareRollouts"
            :items-per-page="20"
            :items-per-page-options="[
              { title: '20', value: 20 },
              { title: '50', value: 50 },
              { title: '100', value: 100 },
            ]"
            @click:row="(_event: any, row: any) => selectOtaRollout(row.item)"
          >
            <template #item.percentile="{ item }">{{ currentPercentile(item) || '0' }}%</template>

            <template #item.schedule="{ item }">{{ currentScheduleStep(item) }} / {{ item.schedule.length }}</template>

            <template #item.filters="{ item }">
              {{ item.filters.map((f: any) => f.split(':').splice(0, 3).join(':')).join(',') || 'No filters used' }}
            </template>

            <template #item.platform="{ item }">
              {{
                item.platform ? (appPlatforms.find((p: any) => p.value === item.platform) || {}).title || '' : 'Both'
              }}
              {{ item.min_version && !item.max_version ? '>=' : '' }}
              {{ item.min_version ? item.min_version : '' }}

              {{ item.min_version && item.max_version ? '-' : '' }}

              {{ !item.min_version && item.max_version ? '=<' : '' }}
              {{ item.max_version ? item.max_version : '' }}
            </template>

            <template #item.created_at="{ item }">
              {{ new Date(item.created_at).toUTCString() }}
            </template>

            <template #item.state="{ item }">
              <span :class="'text-' + stateColors[item.state]">{{ item.state.toUpperCase() }}</span>
            </template>
          </v-data-table>

          <v-data-table
            v-if="tab === 'otafiles'"
            :loading="isLoading"
            :headers="otafileHeaders"
            :items="firmwareOtaFiles"
            :items-per-page="20"
            :items-per-page-options="[
              { title: '20', value: 20 },
              { title: '50', value: 50 },
              { title: '100', value: 100 },
            ]"
            @click:row="(_event: any, row: any) => selectOtaFile(row.item)"
          >
            <template #item.rollouts="{ item }">
              {{ firmwareRollouts.filter((r) => r.value.slug === item.slug).length }}
            </template>

            <template #item.uploader="{ item }">
              {{ item.extra.source?.replace('github', 'GitHub').replace('poirot', 'Poirot') || 'Unknown' }} /
              {{ item.extra.actor?.split('@')[0] || 'unknown' }}
            </template>

            <template #item.created_at="{ item }">
              {{ new Date(item.created_at).toUTCString() }}
            </template>

            <template #item.state="{ item }">
              <span :class="'text-' + stateColors[item.state]">{{ item.state.toUpperCase() }}</span>
            </template>

            <template #item.actions="{}">
              <div @click.stop="">
                <v-menu left offset-y>
                  <template #activator="{ props }">
                    <v-btn v-bind="props" icon="mdi-dots-vertical" :disabled="!isOtaRolloutsAdmin || true" />
                  </template>
                </v-menu>
              </div>
            </template>
          </v-data-table>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>

  <ModifyRollout
    v-if="rolloutDialog"
    :hw-type="routeParams.hwType"
    :app-flavor="routeParams.appFlavor"
    :ota-file="selectedOtaFile"
    :ota-rollout="selectedOtaRollout"
    @close="closeModifyRollout($event)"
  />
</template>

<script lang="ts">
  import { Component, Prop, mixins, toNative } from 'vue-facing-decorator'

  import { RING_STATE, RING_TYPE, RouteParams, getRingTypesByState } from '@jouzen/outo-toolkit-vuetify'

  import {
    appFlavors,
    appPlatforms,
    otafileHeaders,
    otafileStates,
    rolloutHeaders,
    rolloutStates,
    stateColors,
  } from '#views/otafr/constants'

  import { getCurrentPercentile, getCurrentScheduleStep } from '#views/otafr/utilities'

  import { AppStore, OtaFRStore } from '#stores'

  import { OtaFile, OtaRollout } from '#types'

  @Component
  class RolloutsView extends mixins(RouteParams) {
    @Prop() public tab!: string

    public readonly env = import.meta.env.VITE_APP_ENV

    public routeParams: any = {
      hwType: 'gen2x',
      appFlavor: this.env === 'release' ? 'release' : this.env === 'staging' ? 'staging' : 'debug',
      appPlatform: '',
      rolloutState: '',
      otafileState: '',
    }

    public rolloutDialog = false

    public selectedOtaFile: OtaFile | null = null
    public selectedOtaRollout: OtaRollout | null = null

    public readonly appFlavors = appFlavors
    public readonly stateColors = stateColors
    public readonly appPlatforms = appPlatforms
    public readonly rolloutStates = rolloutStates
    public readonly otafileStates = otafileStates
    public readonly rolloutHeaders = rolloutHeaders
    public readonly otafileHeaders = otafileHeaders

    public readonly currentPercentile = getCurrentPercentile
    public readonly currentScheduleStep = getCurrentScheduleStep

    public readonly hardwareTypes = getRingTypesByState([
      ~RING_STATE.DEPRECATED,
      RING_STATE.INTERNAL | RING_STATE.RELEASED,
    ])

    protected readonly appStore = new AppStore()
    protected readonly otaFRStore = new OtaFRStore()

    public get isLoading() {
      return this.otaFRStore.loading
    }

    public get firmwareOtaFiles() {
      return (this.otaFRStore.files?.[this.routeParams.hwType as RING_TYPE] ?? []).filter(
        (f: any) =>
          f.type === `firmware_${this.routeParams.hwType}` &&
          (!this.routeParams.otafileState || f.state === this.routeParams.otafileState),
      ) as any[]
    }

    public get firmwareRollouts() {
      return (this.otaFRStore.rollouts?.[(this.routeParams.hwType ?? RING_TYPE.GEN2X) as RING_TYPE] ?? []).filter(
        (r: any) =>
          (!r.flavor || r.flavor === this.routeParams.appFlavor) &&
          (!this.routeParams.appPlatform || r.platform === this.routeParams.appPlatform) &&
          (!this.routeParams.rolloutState || r.state === this.routeParams.rolloutState),
      ) as any[]
    }

    public get isOtaRolloutsAdmin() {
      return this.appStore.isOtaRolloutsAdmin
    }

    public mounted() {
      this.refreshOtaFilesAndRollouts()
    }

    public createRollout() {
      this.selectedOtaFile = null
      this.selectedOtaRollout = null

      this.rolloutDialog = true
    }

    public selectOtaFile(otaFile: any) {
      this.selectedOtaFile = otaFile
      this.selectedOtaRollout = null

      this.rolloutDialog = true
    }

    public selectOtaRollout(otaRollout: any) {
      this.selectedOtaFile = this.firmwareOtaFiles.find(
        (f) => f.type === otaRollout.value.type && f.slug === otaRollout.value.slug,
      )

      this.selectedOtaRollout = otaRollout

      this.rolloutDialog = true
    }

    public closeModifyRollout(closeParams: any) {
      if (!closeParams) {
        this.rolloutDialog = false

        this.selectedOtaFile = null
        this.selectedOtaRollout = null
      } else {
        this.routeParams.hwType = closeParams.hwType
        this.routeParams.appFlavor = closeParams.appFlavor

        this.selectedOtaFile = this.firmwareOtaFiles.find(
          (f) => f.type === closeParams.otaRollout.value.type && f.slug === closeParams.otaRollout.value.slug,
        )

        this.selectedOtaRollout = closeParams.otaRollout
      }
    }

    public async refreshOtaFilesAndRollouts() {
      // Lets make sure that files are available before rollouts

      await this.otaFRStore.listFiles()

      await this.otaFRStore.listRollouts()
    }
  }

  export default toNative(RolloutsView)
</script>
