<template>
  <!-- eslint-disable vue/v-on-handler-style -->

  <v-dialog v-model="rolloutDialog" width="1000" scrollable>
    <v-card :loading="loading || publishing">
      <v-card-title>
        <span class="headline">{{ action.title }} Firmware Rollout</span>
      </v-card-title>

      <v-card-text>
        <template v-if="action.title === 'Edit' || action.title === 'Setup'">
          <div class="text-overline px-3">Firmware file</div>

          <v-menu offset-y :disabled="loading">
            <template #activator="{ props }">
              <div v-bind="props" class="px-3 mb-8">
                <v-data-table
                  hide-default-header
                  hide-default-footer
                  no-data-text="No file selected"
                  style="border-radius: 0; border-bottom: 1px solid grey"
                  :loading="loading"
                  :headers="headers"
                  :items="file ? [file] : []"
                />
              </div>
            </template>

            <v-card style="max-height: 300px; overflow: auto">
              <v-data-table
                hide-default-footer
                :loading="loading"
                :headers="headers"
                :items="rollout ? filteredFiles : otaFiles"
                :items-per-page="100"
                @click:row="(_event: any, row: any) => (file = row.item)"
              />
            </v-card>
          </v-menu>
        </template>

        <div class="text-overline px-3">Target preview</div>

        <v-row class="px-3">
          <v-col class="d-flex">
            <v-text-field label="Ring type" readonly hide-details :model-value="hardwareName" />
          </v-col>
          <v-col class="d-flex">
            <v-text-field label="App flavor" readonly hide-details :model-value="team.appFlavor" />
          </v-col>
        </v-row>

        <v-row class="px-3">
          <v-col>
            <v-text-field label="Label" readonly hide-details :model-value="'research:id:' + team.id" />
          </v-col>
          <v-col>
            <v-text-field label="Priority" readonly hide-details :model-value="60" />
          </v-col>
        </v-row>

        <div class="text-overline px-3 pt-8">Schedule preview</div>

        <v-row class="px-3">
          <v-col>
            <v-text-field v-model="rolloutDate" readonly hide-details label="Rollout date" />
          </v-col>

          <v-col>
            <v-text-field v-model="schedules[0].title" readonly hide-details label="Schedule type" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn class="mr-2" text="Cancel" @click="close()" />

        <v-btn
          v-if="action.title == 'Edit'"
          text="Save"
          color="primary"
          :disabled="!file || loading || publishing"
          @click="publishRollout()"
        />
        <v-btn
          v-else-if="action.title == 'Setup'"
          text="Publish"
          color="primary"
          :disabled="!file || loading || publishing"
          @click="publishRollout()"
        />
        <v-btn v-else color="error" text="Confirm" :disabled="loading" @click="updateRollout()" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { Component, Emit, Model, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { compare } from 'compare-versions'

  import { RING_TYPE, getRingTypeByValue } from '@jouzen/outo-toolkit-vuetify'

  import { createHeaders, createSchedules } from '#views/otafr/constants'

  import { AppStore, OtaFRStore } from '#stores'

  import { OtaFile, OtaRollout } from '#types'

  @Component
  class SetupRollout extends Vue {
    @Prop() public team!: any

    @Prop() public action!: any
    @Prop() public hwType!: RING_TYPE

    @Prop() public rollout!: OtaRollout | null

    @Model() public rolloutDialog!: boolean

    @Emit('close')
    public close() {
      this.file = null

      this.rolloutDialog = false
    }

    @Emit('update')
    public update(rollout: OtaRollout | null) {
      return rollout
    }

    public file: any = null

    public publishing = false

    public rolloutMenu = false

    public rolloutDate: string = new Date().toISOString().split('T')[0]

    public readonly headers = createHeaders
    public readonly schedules = createSchedules

    private readonly appStore = new AppStore()

    private readonly otaFRStore = new OtaFRStore()

    public get loading() {
      return this.otaFRStore.loading
    }

    public get userEmail() {
      return this.appStore.userEmail
    }

    public get rollouts() {
      return this.otaFRStore.rollouts?.[this.hwType] ?? []
    }

    public get otaFiles() {
      return (this.otaFRStore.files?.[this.hwType] ?? []).filter(
        (f: any) =>
          f.state === 'verified' &&
          f.type === `firmware_${this.hwType}` &&
          f.version.split('.')[0] < 200 &&
          f.version.split('.')[2] < 100,
      )
    }

    public get hardwareName() {
      const ringType = RING_TYPE[this.hwType as keyof typeof RING_TYPE]

      return getRingTypeByValue(ringType)?.title ?? 'Unknown'
    }

    public get filteredFiles() {
      if (this.rollout) {
        // if rollout state is either disabled or active, filter out files that have older versions
        if (this.rollout.state === 'active' || this.rollout.state === 'disabled') {
          return this.otaFiles.filter((f: OtaFile) => compare(f.version, this.rollout!.value.version, '>'))
        }
      }

      return this.otaFiles
    }

    public mounted() {
      this.otaFRStore.listFiles()
    }

    public async updateRollout() {
      await this.otaFRStore.changeState({
        state: this.action.value,
        rollout: this.rollout,
      })

      this.update(this.rollout)

      this.close()
    }

    public async publishRollout() {
      this.publishing = true

      if (this.action.title === 'Edit') {
        await this.otaFRStore.changeState({
          state: this.action.value,
          rollout: this.rollout,
        })
      }

      let value: any = null

      if (this.file) {
        value = {
          slug: this.file.slug,
          type: this.file.type,
          version: this.file.version,
        }
      }

      const rolloutToAdd = {
        type: 'otafile',
        value: value,
        schedule: {
          style: 'daily',
          step_count: 1,
          date_start: this.$dayjs(this.rolloutDate).utc(true).add(6, 'h').toISOString().split('T')[0],
        },
        filters: [`label:research:id:${this.team.id}`],
        info: {
          source: 'poirot',
          actor: this.userEmail,
        },
        flavor: this.team.appFlavor,
        priority: 60,
      }

      const response = await this.otaFRStore.createRollout({ rollout: rolloutToAdd })

      if (response?.data) {
        this.update(response.data as OtaRollout)
      }

      if (this.action.title === 'Edit' || this.action.title === 'Setup') {
        await this.otaFRStore.changeState({
          state: 'active',
          rollout: response.data,
        })
      }

      this.publishing = false

      this.close()
    }
  }

  export default toNative(SetupRollout)
</script>
