<template>
  <v-tabs v-model="tab" @update:model-value="tabChange()">
    <v-tab>Daily</v-tab>
    <v-tab>Weekly</v-tab>
    <v-tab>Monthly</v-tab>
  </v-tabs>

  <v-row align="center" class="mt-2">
    <v-col :cols="tab === 0 ? 2 : 1">
      <span v-if="tab === 0">Every day at:</span>
      <span v-if="tab === 1">Every:</span>
      <span v-if="tab === 2">Every</span>
    </v-col>

    <v-col v-if="tab === 1" cols="7" class="d-flex px-0 justify-space-between">
      <v-checkbox
        v-for="wD in weekDays"
        :key="wD"
        v-model="weekDay"
        multiple
        hide-details
        :label="wD"
        :value="wD"
        @update:model-value="cronChange()"
      />
    </v-col>

    <v-col v-if="tab > 1" cols="3">
      <v-select
        v-model="monthDay"
        multiple
        hide-details
        label="Day of month"
        density="compact"
        :items="monthDays"
        @update:model-value="cronChange()"
      />
    </v-col>

    <v-col v-if="tab === 2" cols="1" style="font-size: 1rem; text-align: center">of each</v-col>

    <v-col v-if="tab === 2" cols="3">
      <v-select
        v-model="month"
        multiple
        hide-details
        label="Pick a month"
        density="compact"
        :items="months"
        @update:model-value="cronChange()"
      />
    </v-col>

    <v-col v-if="tab > 0" cols="1" style="font-size: 1rem; text-align: center">at</v-col>

    <v-col :cols="tab === 0 ? 4 : 3">
      <v-text-field
        v-model="time"
        label="Give UTC time"
        hint="Give UTC time in format of HH:mm"
        :rules="[validateCronTime]"
        @blur="cronChange()"
      />
    </v-col>
  </v-row>
</template>

<script lang="ts">
  import { Component, Emit, Model, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { months, weekDays } from '#views/setup/constants'

  @Component
  class CronSettings extends Vue {
    @Model({ name: 'cronValue' }) public cron!: string[]

    public tab = 0

    public time = '01:00'

    public menuTime = false

    public month: string[] = []
    public months: string[] = months

    public monthDay: string[] = []
    public monthDays: string[] = []

    public weekDay: string[] = []
    public weekDays: string[] = weekDays

    private cronString: string[] = ['0', '1', '*', '*', '?', '*']

    @Emit('valid')
    public updateValid(isValid: boolean) {
      return isValid
    }

    @Watch('cron')
    protected cronChanged() {
      this.cronString = [...this.cron]

      this.time =
        this.cron[1] !== '*' && this.cron[0] !== '*'
          ? `${this.cron[1].padStart(2, '0')}:${this.cron[0].padStart(2, '0')}`
          : '00:00'

      this.weekDay = this.cron[4] !== '*' && this.cron[4] !== '?' ? [...this.cron[4].split(',')] : []

      this.monthDay = this.cron[2].includes('L')
        ? [...['Last day of month']]
        : this.cron[2] !== '*'
          ? [...this.cron[2].split(',')]
          : []

      this.month = this.cron[3] !== '*' && this.cron[2] !== '*' ? [...this.cron[3].split(',')] : []
    }

    public mounted() {
      const array = [...new Array(32)]

      this.monthDays.push('Last day of month')

      array.forEach((a, index) => {
        this.monthDays.push(index + 1 + '')
      })

      if (this.cron) {
        this.tab = this.cron[4] === '?' && this.cron[2] === '*' ? 0 : this.cron[2] === '?' ? 1 : 2

        this.cronChanged()
      }
    }

    public validateCronTime(time: string) {
      const isValid = this.$dayjs(time, 'HH:mm', true).isValid()

      this.updateValid(isValid)

      return isValid || 'Time must be in format HH:mm'
    }

    public tabChange() {
      this.cronString = ['0', '1', '*', '*', '?', '*']

      this.time = '01:00'

      this.month = []
      this.weekDay = []
      this.monthDay = []

      this.cron = this.cronString
    }

    public cronChange() {
      if (this.time) {
        this.cronString[1] = this.time.split(':')[0] || '00'
        this.cronString[0] = this.time.split(':')[1] || '00'
      }

      if (this.weekDay.length > 0) {
        this.cronString[2] = '?'
        this.cronString[4] = this.weekDay.join(',')
      }

      if (this.monthDay.length > 0) {
        if (this.monthDay.includes('Last day of month')) {
          this.cronString[2] = 'L'
        } else {
          this.cronString[2] = this.monthDay.join(',')
        }
      }

      if (this.month.length) {
        this.cronString[4] = '?'

        if (!this.month.includes('EVERY MONTH')) {
          this.cronString[3] = this.month.length > 1 ? this.month.join(',') : this.month[0]
        }
      }

      this.cron = this.cronString
    }
  }

  export default toNative(CronSettings)
</script>
