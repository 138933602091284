import { Activity, AutoSchedule, Flag, MemoryLayout, StudyFile, StudyProtocol, StudyUser } from '#types'

export type UserRights = 'allowDataDownloadAccess'

export interface StatsPrefs {
  collapsedCards: string
}

export interface WarrantyReasonStats {
  warrantyReasons: {
    count: number
    reason: string
  }[]
}

export interface CreateTaskParameter {
  scheduleDescription: string
  cronSchedule: string
  jobs: Job[]
}

export interface EditTaskParameter {
  state?: string
  ruleName: string
  taskName: string
  scheduleDescription?: string
  cronSchedule?: string
  parameters?: any
  info?: any
}

export interface Job {
  jobType: string
  containerOverrides: {
    environment: Environment[]
  }
}

export interface Environment {
  name: string
  value: string
}

export interface FirmwareAlert {
  versions: string[]
  alert: string
}

export interface SearchInput {
  appFlavor?: string
  onlyActive?: string
  labelListId?: string
  sampleListId?: string
  dataEndDate?: string
  dataStartDate?: string
  userEndDate?: string
  userStartDate?: string
}

export interface SummaryJson {
  fw_versions: string[]
  os_versions: string[]
  fw_summaries: FWSummary[]
  temperature_summary: any
  failed_runs: number
  successful_runs: number
}

export interface FWSummary {
  fw: string
  rings_with_fw: number
  os_summaries: OSSummary[]
  dump_summary: any
}

export interface OSSummary {
  os: string
  nights: number
  rings: number
  event_summaries: EventSummary
  battery_slope_summary: BatterySlopeSummary | null
}

export interface BatterySlopeSummary {
  failure: BatterySlopeStatus
  warning: BatterySlopeStatus
  slope_average: number
  note: BatterySlopeStatus | null
  ok: BatterySlopeStatus | null
  worst_cases?: BatterySlopeWorstCase[] | null
}

export type BatterySlopeWorstCase = {
  user_uid?: string
  uid?: string
  average: number
}

export interface EventSummary {
  [key: string]: EventSummaryData[]
}

export type EventKeys = 'FAILURE' | 'WARNING' | 'NOTE'

export interface EventSummaryData {
  name: string
  count: number
  distinct_ring_count: number
  count_per_ring: number
  count_per_night: number
  count_per_distinct_ring: number
  top_occurrences: TopOccurrence[]
  order?: number
  event?: string
  changes_per_night?: string
}

export interface CoredumpSummary {
  dumps: CoredumpsSummary[]
  total_count: number
  individual_count: number
  incomplete_count: number
}

export interface CoredumpsSummary {
  error: string
  failed_in: any
  link_register: any
}

interface TopOccurrence {
  user_uid?: string
  uid?: string
  occurrence_count: number
}

export interface BatterySlopeStatus {
  count: number
  average: number
  sum: number
  status?: string
}

// study = study group = team
export interface Study {
  id: string
  name: string
  appFlavor: string
  type?: string
  fwVersion: string
  ringSizes: string[]
  createdAt: any
  updatedAt: any
  updatedBy: string
  createdBy: string
  managerEmails: string[]
  studyFiles: StudyFile[]
  studyUsers: StudyUser[]
  autoSchedules: AutoSchedule[]
  protocols?: StudyProtocol[]
  memoryLayout: MemoryLayout
  labels?: string[]
  rollouts?: { [key: string]: string[] }
}

export type ProtoFile = {
  /**
   * file id
   *
   * Example: "resting_heart_rate"
   */
  id: string

  /**
   * file name
   *
   * Example: "Resting Heart Rate"
   */
  name: string
  flags: Flag[]
  activities: Activity[]
  createdAt?: any
  updatedAt?: any

  /**
   * created by
   *
   * Example: "example@ouraring.com"
   */
  createdBy?: string

  /**
   * updated by
   *
   * Example: "example@ouraring.com"
   */
  updatedBy?: string
}

export enum EnvValues {
  TEST = 'test',
  STAGE = 'stage',
  PROD = 'prod',
}
