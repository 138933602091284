import { chunk, sortBy, uniq } from 'lodash-es'

import { Pinia, Store } from 'pinia-class-component'

import {
  Unsubscribe,
  collection,
  deleteDoc,
  doc,
  getFirestore,
  onSnapshot,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
} from 'firebase/firestore'

import { Environment } from '@jouzen/outo-toolkit-vuetify'

import { AppStore, RdataStore } from '#stores'

import {
  GetFileUrlRequest,
  HandleLabelConflicts,
  JzlogFile,
  OrbStream,
  Study,
  StudyFile,
  StudyUser,
  StudyUserWithMetadata,
} from '#types'

let unsubscribe: Unsubscribe | undefined = undefined

@Store
export class TeamsStore extends Pinia {
  public loading = false

  public teams: any[] = []
  public schemas: any[] = []

  public labelUsers: StudyUserWithMetadata[] = []

  public pagination = {
    pageSize: 100,
    next: null,
    prev: null,
    total: 0,
  }

  public getRdataFileCollectionIds(team: any): string[] {
    const rdata = new RdataStore()

    return rdata.files
      .filter((rdataFile) => team?.studyFiles?.find((studyFile: StudyFile) => studyFile?.id === rdataFile?.id))
      .map((rdataFile) => rdataFile?.data?.data_collections[0]?.id)
  }

  public async createTeam(team: any) {
    const appStore = new AppStore()

    team.createdAt = serverTimestamp()
    team.updatedAt = serverTimestamp()

    team.createdBy = appStore.user.email
    team.updatedBy = appStore.user.email

    return await setDoc(doc(getFirestore(), `/teams/${team.id}`), team)
  }

  @Environment()
  public async updateTeam(env: string, team: any) {
    const appStore = new AppStore()

    return await updateDoc(doc(getFirestore(), `/teams/${team.id}`), {
      ...team,
      updatedAt: serverTimestamp(),
      updatedBy: appStore.user.email,
    })
  }

  public async deleteTeam(team: Study) {
    return await deleteDoc(doc(getFirestore(), `/teams/${team.id}`))
  }

  @Environment()
  public async createLabel(env: string, teamId: string) {
    this.loading = true

    const labelName = `research:id:${teamId}`

    const labelResponse = await this.$axios.post(`/api/v1/admin/labels`, { name: labelName }, { apiEnv: env })

    this.loading = false

    return labelResponse
  }

  @Environment()
  public async deleteLabel(env: string, teamId: string) {
    this.loading = true

    const labelName = `research:id:${teamId}`

    const response = await this.$axios.delete(`/api/v1/admin/labels/${labelName}`, { apiEnv: env })

    this.loading = false

    return response
  }

  public async saveRollouts(team: any) {
    return await updateDoc(doc(getFirestore(), `/teams/${team.id}`), { rollouts: team.rollouts })
  }

  @Environment()
  private async checkLabelExists(label: string, env: string) {
    const labelRes = await this.$axios.get(`/api/v1/admin/labels/${label}`, { apiEnv: env })

    return labelRes?.status === 200
  }

  @Environment()
  public async fetchLabelUsers(
    env: string,
    data: { team: Study; search?: string; itemsPerPage?: number; path?: string },
  ) {
    const label = `research:id:${data.team.id}`
    let apiPath = ''

    this.loading = true

    if (await this.checkLabelExists(label, env)) {
      if (data?.search) {
        apiPath = `/api/v1/studies/${label}/users/${data.search}`
      } else if (data?.path) {
        apiPath = import.meta.env.VITE_API_URL + data.path
      } else {
        apiPath = `/api/v1/studies/${label}/users?pageSize=${data?.itemsPerPage ?? 100} `
      }
      const usersRes = await this.$axios.get(apiPath, { apiEnv: env })

      if (data?.search) {
        this.labelUsers = usersRes?.data ? [usersRes.data] : []
        this.pagination = { pageSize: data?.itemsPerPage ?? 100, next: null, prev: null, total: usersRes?.data ? 1 : 0 }
      } else {
        this.labelUsers = usersRes?.data?.users ?? []
        this.pagination = usersRes?.data?.pagination ?? { pageSize: 100, next: null, prev: null, total: 0 }
      }
    }

    const rdataFileCollectionIds = this.getRdataFileCollectionIds(data.team)

    this.labelUsers = this.labelUsers.map((user) => {
      const filteredOrbUploads =
        user.orbUploads?.filter((orb) => rdataFileCollectionIds.includes(orb.collectionId)) || []
      return { ...user, orbUploads: filteredOrbUploads }
    })

    this.loading = false

    return this.labelUsers
  }

  @Environment()
  public async addUsersToLabel(env: string, team: any, uuids: string[], handleLabelConflicts?: HandleLabelConflicts) {
    let allUsers: any[] = []
    const label = `research:id:${team.id}`

    this.loading = true

    const labelExists = await this.checkLabelExists(label, env)

    if (!labelExists) {
      return
    }

    for (const c of chunk(uniq(uuids), 1000)) {
      const usersRes = await this.$axios.post(
        `/api/v1/admin/labels/${label}/users`,
        { userUids: c, handleLabelConflicts },
        { apiEnv: env },
      )

      if (usersRes?.status === 200) {
        allUsers = allUsers.concat(usersRes.data.users || [])
      }
    }

    this.loading = false

    this.fetchLabelUsers(env, { team })

    return allUsers
  }

  @Environment()
  public async deleteUsersFromLabel(env: string, team: any, uuids: string[]) {
    const deletedUsers: string[] = []
    const label = `research:id:${team.id}`

    this.loading = true

    if (await this.checkLabelExists(label, env)) {
      for (const uuid of uniq(uuids)) {
        const deleteRes = await this.$axios.delete(`/api/v1/admin/labels/${label}/users/${uuid}`, {
          apiEnv: env,
        })

        if (deleteRes?.status == 204) {
          deletedUsers.push(uuid)
        }
      }
    }

    this.fetchLabelUsers(env, { team })

    this.loading = false

    return deletedUsers
  }

  @Environment()
  public async listORBFiles(env: string, team: any, userId: string) {
    const path = `/api/v1/users/${userId}/orb-streams`

    const response = await this.$axios.get(`${path}`, { apiEnv: env })

    const user = team.studyUsers.find((user: StudyUser) => user.id === userId)

    const rdataFileCollectionIds = this.getRdataFileCollectionIds(team)

    return (response?.data?.streams || []).filter(
      (s: any) =>
        rdataFileCollectionIds.includes(s.info.recording.collection_id) ||
        (s.fileType === 'hr_data' && new Date(s.uploadedAt).getTime() > user!.addedAt),
    )
  }

  // TODO: Get more info from jzlog files so we can filter them to show only the ones that are relevant to the team
  @Environment()
  public async listJzlogFiles(env: string, _team: any, userId: string) {
    const dataFile = {
      bucket: 'ring-stream',
      prefix: 'stream-full/',
    }

    const path = `/api/v1/files?environment=${env}&search_prefix=${dataFile.prefix}${userId}&bucket=${dataFile.bucket}`

    const response = await this.$axios.get(`${path}`)

    if (response?.data?.contents) {
      return response?.data?.contents
        .map((content: any) => ({
          ...content,
          bucket: dataFile.bucket,
          env: env,
        }))
        .slice(0, 5)
    }
  }

  @Environment()
  public async downloadORBFile(env: string, _team: any, file: OrbStream) {
    const path = '/api/v1/files/url'

    const requestData: GetFileUrlRequest = {
      bucket: 'ring-stream',
      save_as: `${file.userUid}-${file.streamUuid}.orb`,
      key: [`stream-full/${file.userUid}/ORB/${file.streamUuid}`],
    }

    const response = await this.$axios.post(path, requestData, { apiEnv: env })

    if (response?.data?.fileUrl) {
      window.open(response?.data?.fileUrl, '_blank')
    }

    return response
  }

  @Environment()
  public async downloadJzlogFile(env: string, _team: any, file: JzlogFile) {
    const path = '/api/v1/files/url'

    const requestData: GetFileUrlRequest = {
      bucket: file.bucket,
      anonymizeJzlog: false,
      save_as: `${file.key}.jzlog`,
      key: [file.key],
    }

    const response = await this.$axios.post(path, requestData, { apiEnv: env })

    if (response?.data?.fileUrl) {
      window.open(response?.data?.fileUrl, '_blank')
    }

    return response
  }

  @Environment()
  public async sendRemoteCommand(env: string, command: string, userId: string) {
    const path = `/api/v1/users/${userId}/notifications`

    const requestData = {
      message: {
        data: {
          'remote-command': `oura://v1/${command}`,
        },
      },
    }

    const response = await this.$axios.post(path, requestData, { apiEnv: env })

    return response
  }
  public async subscribeToTeams() {
    if (unsubscribe) {
      return
    }

    this.loading = true

    unsubscribe = onSnapshot(query(collection(getFirestore(), '/teams')), (snap) => {
      const files = snap.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))

      this.teams = sortBy(files, [(o: any) => o.createdAt || '']).reverse()

      this.loading = false
    })
  }

  public async unsubscribeFromTeams() {
    if (unsubscribe) {
      unsubscribe()

      unsubscribe = undefined
    }
  }
}
