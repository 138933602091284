import { FieldValue, Timestamp } from 'firebase/firestore'

export type RdataFile = {
  id: string
  name: string
  schema: string

  data: ConfigFileData
  live?: any

  isArchived?: boolean

  createdAt: Timestamp | FieldValue
  createdBy: string

  updatedAt: Timestamp | FieldValue
  updatedBy: string

  modifiedAt?: Timestamp | FieldValue
}

export type RdataSchema = {
  /**
   * schema
   *
   * Example: "http://json-schema.org/draft-07/schema#"
   */
  $schema: string

  definitions: {
    [key: string]: {
      /**
       * type
       *
       * Example: "object"
       */
      type: string
      properties: {
        class_id: any
        length: any
        param_id: any
        value: any
      }
    }
  }

  /**
   * name
   *
   * Example: "default-schema-cooper.json"
   */
  name: string
  properties: ConfigFileData

  /**
   * required
   *
   * Example: ["format", "date", "hardware_type", "data_collections"]
   */
  required: string[]

  /**
   * title
   *
   * Example: "Open research data configuration"
   */
  title: string

  /**
   * type
   *
   * Example: "object"
   */
  type: string
}

export type ConfigFileData = {
  data_collections: DataCollection[]

  /**
   * date
   *
   * Example: "2023-05-08"
   */
  date: string

  /**
   * format
   *
   * Example: 3
   */
  format: number

  /**
   * hardware_type
   *
   * Example: "gen2x"
   */
  hardware_type: string
}

type DataCollection = {
  description: string
  id: string
  measurements: RdataMeasurement[]
  /**
   * name
   *
   * Example "PPG: Measurement Slot 0"
   */
  name: string
}

export type RdataMeasurement = {
  name: string
  parameters: RdataParameter[]
}

export type RdataParameter = {
  /**
   * class_id
   *
   * Example: 0
   */
  class_id: number

  /**
   * length
   *
   * Example: 1
   */
  length: number

  /**
   * name
   *
   * Example: "Measurement: Data Interval [us] - 50Hz"
   */
  name: string

  /**
   * param_id
   *
   * Example: 0
   */
  param_id: number

  /**
   * value
   *
   * Example: 0
   */
  value: number
}

export type SchemaValueOption = {
  /*
   * const
   *
   * Example: 5
   */
  const: number

  /**
   * title
   *
   * Example: 'PPG: IR LED1'
   */
  title: string

  /**
   * rules
   *
   * Example: ['PPG', 'EGC', 'BIOZ']
   */
  rules?: string
}

export type StudyFile = {
  addedAt: number
  collectionId: string
  id: string
}

export type StudyUser = {
  addedAt: number | null
  fetchedAt?: number | null
  id: string
}

export type MemoryLayout = {
  title: string
  rdataSize: number
  jzlog: number
  ringApiValue: number
}

export type AutoSchedule = {
  /**
   * file
   *
   * Example: "394e8b5d-4693-499d-8d69-1a0988c60b8a"
   */
  file: string

  /**
   * time
   *
   * Eexample: "12:00"
   */
  time: string

  /**
   * uuid
   *
   * Example: "ad76dae0-2615-452b-91c4-cd3021c568ee"
   */
  uuid: string
  duration?: number
}

export enum RingSensor {
  PPG = 'PPG',
  ECG = 'ECG',
  BIOZ = 'BIOZ',
  ACM = 'ACM',
  GYRO = 'Gyro',
  TEMPERATURE = 'Temperature',
  SYSTEM = 'System',
  TIMING = 'TIMING',
  TEMP = 'TEMP',
}
