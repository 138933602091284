<template>
  <v-dialog v-model="open" persistent width="1000">
    <v-card v-if="task">
      <v-card-title>{{ !schedule ? 'Create' : 'Edit' }} schedule</v-card-title>

      <v-alert v-if="saveError" type="error">{{ saveError }}</v-alert>

      <v-card-text>
        <v-row align="center">
          <v-col cols="8">
            <v-text-field v-model="infoText" autofocus label="Schedule description" />
          </v-col>

          <v-col cols="4">
            <v-select v-model="jzlogSource" label="Jzlog source" :items="jzlogSources" />
          </v-col>
        </v-row>

        <v-row class="px-0 mr-4">
          <v-col>
            <p class="section-title">Schedule for task running</p>
          </v-col>

          <v-col cols="12" class="section-border ml-6 mb-8 pl-4">
            <CronSettings v-model:cron-value="cron" @valid="isValidCronSchedule = $event" />
          </v-col>

          <v-col>
            <p class="section-title">Configuration for the task</p>
          </v-col>

          <v-col cols="12" class="section-border ml-6 mb-8 pl-4">
            <v-tabs v-model="selectedTab" class="mb-6">
              <v-tab v-for="tab in taskTabs" :key="tab.value" :value="tab.value">{{ tab.title }}</v-tab>
            </v-tabs>

            <v-alert
              v-if="isFactory && !isValidFactoryMode"
              type="warning"
              class="mb-4"
              :text="`${upperFirst(selectedTab)} samples are not supported by ${jzlogSource} jzlog source`"
            />

            <template v-if="task.parameters.inputCommand">
              <TaskConfig :task="task" :mode="selectedTab" :source="jzlogSource" :is-schedule="true" class="mb-4" />

              <TaskBinary :mode="selectedTab" :task="task" />
            </template>
          </v-col>

          <v-col>
            <p class="section-title">Alert settings</p>
          </v-col>

          <v-col cols="12" class="section-border ml-6 mb-8 pl-4">
            <v-select
              v-model="task.parameters.taskRuleSetId"
              class="mt-4"
              label="Select alert setting"
              item-title="name"
              item-value="uid"
              no-data-text="No alert settings available"
              :items="scheduleAlerts"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn text="Cancel" class="mr-2" @click="close()" />

        <v-btn
          text="Save"
          color="primary"
          :disabled="!cron || !isValidCronSchedule || !infoText || !isValid || isLoading"
          @click="saveSchedule()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { upperFirst } from 'lodash-es'

  import { Component, Emit, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { TaskHelpers } from '#common/TaskHelpers.vue'

  import { createDefaultTask, jzlogSources, taskStarterTabs } from '#views/tasks/constants'

  import { AppStore, SetupStore, TasksStore } from '#stores'

  import {
    EnvValues,
    JzlogSources,
    SampleFilter,
    ScheduleData,
    ScheduleTask,
    TaskConfigModes,
    TaskSettings,
  } from '#types'

  @Component
  class EditSchedule extends mixins(TaskHelpers) {
    @Prop({ required: true }) public env!: string
    @Prop({ required: true }) public open!: boolean

    @Prop({ required: true }) public schedule!: ScheduleTask

    @Emit('close')
    public close() {
      this.infoText = ''
      this.saveError = ''

      this.selectedTab = TaskConfigModes.RANDOM

      return null
    }

    public infoText = ''
    public saveError = ''
    public jzlogSource: JzlogSources | '' = ''

    public isValidCronSchedule = false
    public selectedTab: TaskConfigModes = TaskConfigModes.RANDOM

    public task: TaskSettings | null = null

    public cron = ['0', '1', '*', '*', '?', '*']

    public taskTabs = taskStarterTabs.slice(0, -1)

    public readonly upperFirst = upperFirst

    private readonly appStore = new AppStore()
    private readonly tasksStore = new TasksStore()
    private readonly setupStore = new SetupStore()

    public get isValid() {
      const randomValidation =
        this.selectedTab === TaskConfigModes.RANDOM &&
        !this.isValidFactoryMode &&
        !!this.task &&
        !!this.task.parameters.hwType &&
        !!this.task.parameters.maxUsers &&
        !!this.task.parameters.fwVersions

      const selectValidationAppSource =
        this.selectedTab === TaskConfigModes.SELECT &&
        !this.isValidFactoryMode &&
        !!this.task &&
        !!this.task.parameters.fwVersions &&
        !!this.task.parameters.whitelistedFilters

      const selectValidationFactorySource =
        this.isValidFactoryMode &&
        !!this.task &&
        !!this.task.parameters.hwType &&
        !!this.task.parameters.factory &&
        !!this.task.parameters.testPhase &&
        !!this.task.parameters.samplePeriodDays

      const uploadValidation = this.selectedTab === TaskConfigModes.UPLOAD && !!this.task && !!this.task.file

      return randomValidation || selectValidationAppSource || selectValidationFactorySource || uploadValidation
    }

    public get isLoading() {
      return this.tasksStore.loading || this.setupStore.loading
    }

    public get jzlogSources() {
      return jzlogSources.filter((s: any) => !s.env || s.env === this.env)
    }

    public get scheduleAlerts() {
      return this.setupStore.scheduleAlerts || []
    }

    public get taskSampleFilters() {
      return this.setupStore.sampleFilters.filter((f: SampleFilter) => f.type !== 'fwVersion')
    }

    public get isFactory() {
      return this.jzlogSource === JzlogSources.FACTORY
    }

    public get isValidFactoryMode() {
      return this.isFactory && this.selectedTab === TaskConfigModes.SELECT
    }

    @Watch('open', { immediate: true })
    protected openChanged() {
      if (this.open) {
        this.scheduleChanged()

        this.setupStore.listSampleFilters(this.env)

        this.setupStore.listScheduleAlerts(this.env)
      }
    }

    @Watch('schedule', { immediate: true })
    protected scheduleChanged() {
      if (!this.open) {
        return
      }

      if (!this.schedule) {
        this.task = createDefaultTask()

        this.cron = ['0', '1', '*', '*', '?', '*']

        this.jzlogSource = this.jzlogSources[0].value
      } else {
        const { info, taskName, parameters } = this.schedule?.targets[0]?.input || {}

        this.selectedTab = TaskConfigModes[parameters?.samplePath ? 'UPLOAD' : parameters?.hwType ? 'SELECT' : 'RANDOM']

        if (parameters) {
          this.infoText = this.schedule.description

          if (parameters.appFlavor) {
            this.jzlogSource = parameters.appFlavor as JzlogSources
          } else if (taskName.includes(JzlogSources.FACTORY)) {
            this.jzlogSource = JzlogSources.FACTORY
          } else {
            this.jzlogSource = this.jzlogSources[0].value
          }

          this.cron = this.schedule.scheduleExpression.replace('cron(', '').replace(')', '').split(' ')

          this.task = {
            info,
            parameters,
          }
        } else {
          this.saveError = 'Schedule has invalid configuration!'
        }
      }
    }

    @Watch('jzlogSource', { immediate: true })
    protected jzlogSourceChanged() {
      if (this.isFactory) {
        this.selectedTab = TaskConfigModes.SELECT
      } else {
        const { parameters } = this.schedule?.targets[0]?.input || {}

        this.selectedTab = TaskConfigModes[parameters?.samplePath ? 'UPLOAD' : parameters?.hwType ? 'SELECT' : 'RANDOM']
      }
    }

    public async saveSchedule() {
      if (!this.task) {
        this.saveError = 'Cannot save empty task!'
        return
      }

      const cloudEnv = this.isFactory ? EnvValues.PROD : this.env
      const data: Omit<ScheduleData, 'info'> = {
        scheduleDescription: this.infoText,
        cronSchedule: this.cron.join(' '),
        parameters: {
          ...this.task.parameters,
          analyzeSampleMode: TaskConfigModes.SCHEDULED,
          ...(this.isFactory ? {} : { appFlavor: this.jzlogSource }),
        },
      }

      this.saveError = ''

      if (!this.schedule) {
        const scheduleData: ScheduleData = {
          ...data,
          taskName: `poirot-analyze-${this.isValidFactoryMode ? 'factory-jzlogs' : 'firmware'}`,
          info: {
            name: 'Scheduled task',
            description: this.infoText,
            scheduleCreator: this.appStore.user.email,
          },
        }

        const result = await this.setupStore.createScheduleTask(cloudEnv, scheduleData)

        if (!result?.data?.ruleName) {
          this.saveError = 'Unknown error occurred when creating the schedule, please report this!'
        } else {
          this.close()
        }
      } else {
        const { info } = this.schedule?.targets[0]?.input || {}

        const scheduleData: ScheduleData = {
          ...data,
          info: { ...info, description: this.infoText },
        }

        const result = await this.setupStore.modifyScheduleTask(cloudEnv, this.schedule.ruleName, scheduleData)

        if (!result?.data?.ruleName) {
          this.saveError = 'Unknown error occurred when saving the schedule, please report this!'
        } else {
          await this.setupStore.modifyScheduleTask(cloudEnv, this.schedule.ruleName, { state: this.schedule.state })

          this.close()
        }
      }
    }
  }

  export default toNative(EditSchedule)
</script>

<style lang="scss">
  .section-title {
    color: #9c27b0;
    font-size: 0.8rem;
    font-weight: 500;
    text-transform: uppercase;
  }

  .section-border {
    border-left: 2px solid #9c27b0;
  }
</style>
