<template>
  <v-app-bar :color="!selectedTests.length ? 'secondary' : 'primary'">
    <template v-if="!selectedTests.length">
      <v-tabs style="max-width: 600px" :model-value="tab">
        <v-tab :to="FactoryTestsTabs.RINGS">Rings</v-tab>
        <v-tab :to="FactoryTestsTabs.CHARGERS">Chargers</v-tab>
        <v-tab :to="FactoryTestsTabs.FILES">Files</v-tab>
      </v-tabs>

      <v-spacer />

      <v-btn
        icon="mdi-filter-variant-plus"
        :disabled="tab === FactoryTestsTabs.FILES"
        :color="showExtraFilters ? 'primary' : ''"
        @click="routeParams.extraFilters = showExtraFilters ? 'false' : 'true'"
      />
    </template>

    <template v-if="selectedTests.length">
      <v-toolbar-items color="white" class="flex-grow-1">
        <v-btn variant="text" @click="selectedTests = []">
          <v-icon class="mr-2">mdi-close</v-icon>

          {{ selectedTests.length }} {{ selectedTests.length > 1 ? 'Files' : 'File' }} selected
        </v-btn>

        <v-spacer />

        <v-btn
          v-if="tab !== FactoryTestsTabs.FILES"
          variant="text"
          :disabled="selectedTests.length > 10"
          @click="compareSelectedFiles()"
        >
          <v-icon class="mr-2">mdi-select-compare</v-icon>

          Compare selected
        </v-btn>

        <v-btn
          variant="text"
          prepend-icon="mdi-download"
          text="Download selected"
          @click="downloadFiles({ bucket: 'factory-io', key: selectedTests })"
        />

        <v-btn variant="text" @click="confirmDialog = true">
          <v-icon class="mr-2">mdi-download-multiple</v-icon>

          Download all results
        </v-btn>
      </v-toolbar-items>
    </template>
  </v-app-bar>

  <v-container>
    <v-row>
      <v-col cols="9">
        <h5 class="text-h5 font-weight-light">
          <template v-if="tab === FactoryTestsTabs.RINGS">Ring factory test files matching the search</template>
          <template v-if="tab === FactoryTestsTabs.CHARGERS">Charger factory test files matching the search</template>
          <template v-if="tab === FactoryTestsTabs.FILES">Jzlog files matching the search</template>
        </h5>

        <p class="text-subtitle-2 text-grey-darken-2 font-weight-light">
          <template v-if="tab === FactoryTestsTabs.RINGS">
            Select one or more files to compare or download the files
          </template>
          <template v-if="tab === FactoryTestsTabs.CHARGERS">
            Select one or more files to compare or download the files
          </template>
          <template v-if="tab === FactoryTestsTabs.FILES">Select one or more files to download the files</template>
        </p>
      </v-col>

      <v-col cols="3" class="d-flex justify-end align-top">
        <v-btn
          color="primary"
          :text="`Search ${tab}`"
          :disabled="isLoading || !isSearchRefValid"
          @click="searchRef?.updateSearch()"
        />
      </v-col>
    </v-row>

    <SearchFields ref="searchRef" v-model="searchFields" :tab="tab" :extra="showExtraFilters" />

    <v-row v-if="tab !== FactoryTestsTabs.FILES">
      <v-col cols="12">
        <v-sheet>
          <v-data-table
            v-model="selectedTests"
            show-select
            item-value="key"
            no-data-text="No search results to show"
            :page="activePage"
            :search="filterText"
            :loading="isLoading"
            :headers="listHeaders"
            :items-per-page="100"
            :items-per-page-options="[10, 25, 50, 100]"
            :items="tab === FactoryTestsTabs.RINGS ? ringTests : chargerTests"
            @update:page="moreTests($event)"
          >
            <template #item.overallTestResult="{ item }">
              <div v-if="item.overallTestResult" class="text-no-wrap">
                <v-chip
                  class="ma-2"
                  variant="flat"
                  color="transparent"
                  prepend-icon="mdi-square"
                  :class="item.overallTestResult === 'pass' ? 'text-green' : 'text-red'"
                >
                  {{ item.overallTestResult.toUpperCase() }}
                </v-chip>
              </div>
            </template>

            <template #item.durationSeconds="{ item }">
              <div class="text-no-wrap">
                {{ Math.round(item.durationSeconds) }}
              </div>
            </template>

            <template #item.testStart="{ item }">
              <div class="text-no-wrap">
                {{ $dayjs(item.testStart).format('YYYY-MM-DD HH:mm') }}
              </div>
            </template>

            <template #item.failedMeasNo="{ item }">
              <div class="text-no-wrap">
                {{ item.failedMeasNo || 'No failures' }}
              </div>
            </template>

            <template #item.action="{ item }">
              <v-btn
                icon="mdi-cloud-download"
                @click="
                  downloadFiles({
                    bucket: 'factory-io',
                    key: [item.key],
                  })
                "
              />
            </template>
          </v-data-table>
        </v-sheet>
      </v-col>
    </v-row>

    <template v-if="tab === FactoryTestsTabs.FILES">
      <JzlogFiles v-model="selectedTests" :tab="tab" />
    </template>
  </v-container>

  <CompareTests :open="compareDialog" @close="compareDialog = false" />

  <DownloadAll v-model="confirmDialog" :fields="searchFields" :tab="tab" />
</template>

<script lang="ts">
  import { Component, Prop, Ref, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { RouteParams } from '@jouzen/outo-toolkit-vuetify'

  import { testsListHeaders } from '#views/tests/constants'

  import { SearchFields } from '#views/tests/SearchFields.vue'

  import { TestsStore } from '#stores'

  import { FactoryTestSummary, FactoryTestsTabs, SearchTestParams } from '#types'

  @Component
  class TestsView extends mixins(RouteParams) {
    @Prop() public tab!: FactoryTestsTabs

    @Ref() public readonly searchRef: SearchFields | null = null

    public routeParams: any = {
      extraFilters: 'false',
    }

    public serialNo = ''
    public filterText = ''

    public confirmDialog = false
    public compareDialog = false

    public searchFields: any = null
    public selectedTests: string[] = []

    public cancelDownloadAllPolling = false

    private isSearchRefAvailable = false

    public readonly listHeaders = testsListHeaders

    public readonly FactoryTestsTabs = FactoryTestsTabs

    private readonly testsStore = new TestsStore()

    public get isLoading() {
      return this.testsStore.loading
    }

    public get activePage() {
      return this.testsStore.page
    }

    public get ringTests() {
      return this.testsStore.ringTests || []
    }

    public get chargerTests() {
      return this.testsStore.chargerTests || []
    }

    public get allFactoryFiles() {
      return this.testsStore.allFactoryFiles
    }

    public get allFactoryFilesError() {
      return this.testsStore.allFactoryFilesError
    }

    public get cancelAllFactoryFilePoll() {
      return this.testsStore.cancelAllFactoryFilePoll
    }

    public get showExtraFilters() {
      return this.routeParams.extraFilters === 'true'
    }

    private get productCategory() {
      if (this.tab === FactoryTestsTabs.CHARGERS) {
        return 'charger'
      }

      return 'ring'
    }

    public get isSearchRefValid() {
      return this.isSearchRefAvailable ? this?.searchRef?.isValidFields : false
    }

    @Watch('ringTests')
    @Watch('searchFields')
    protected ringTestsChanged() {
      this.selectedTests = []
    }

    @Watch('chargerTests')
    protected chargerTestsChanged() {
      this.ringTestsChanged()
    }

    public mounted() {
      if (this.searchRef) {
        this.isSearchRefAvailable = true
      }
    }

    public moreTests(page: number) {
      const pagination = this.testsStore.pagination

      const data: SearchTestParams = {
        category: this.productCategory,
        path: page > this.activePage ? pagination?.next : pagination?.previous,
        page: page,
      }

      this.testsStore.searchTests(data)
    }

    public resetDownloadAll() {
      this.confirmDialog = false

      this.cancelDownloadAllPolling = false

      this.testsStore.setAllFactoryFiles([])

      this.testsStore.setAllFactoryFilesError('')

      this.testsStore.setAllFactoryZips(0)

      this.testsStore.setCancelAllFactoryFilePoll(false)
    }

    public downloadFiles(files: { bucket: string; key: any }) {
      this.testsStore.downloadFiles(files)
    }

    public compareSelectedFiles() {
      this.compareDialog = true

      let tests: FactoryTestSummary[] = this.tab === 'rings' ? this.ringTests : this.chargerTests

      this.testsStore.searchTestDetails({
        category: this.tab.slice(0, -1),
        testIds: tests.filter((t) => this.selectedTests.includes(t.key!)).map((t) => t.testId),
      })
    }
  }

  export default toNative(TestsView)
</script>

<style lang="scss" scoped>
  :deep(.v-data-table) {
    tr:hover {
      cursor: pointer;
    }

    .v-row-group__header {
      .text-start {
        button:nth-child(2) {
          display: none;
        }
      }
    }

    .v-data-footer__select,
    .v-data-footer__pagination {
      visibility: hidden;
    }
  }
</style>
