<template>
  <v-stepper
    class="mt-n4 mx-n2 elevation-0"
    bg-color="secondary"
    :model-value="appFlavor"
    @update:model-value="emitSelect($event as string)"
  >
    <v-stepper-header>
      <v-stepper-item
        editable
        value="debug"
        title="Debug"
        :icon="stateIcons[rolloutsState('debug')]"
        :edit-icon="stateIcons[rolloutsState('debug')]"
        :complete-icon="stateIcons[rolloutsState('debug')]"
        :color="stateColors[rolloutsState('debug')]"
        :complete="!!rolloutsCount('debug')"
        :subtitle="rolloutsCount('debug') > 1 ? rolloutsCount('debug') + ' rollouts' : ''"
      />
      <v-divider />

      <v-stepper-item
        editable
        value="experimental"
        title="Experimental"
        :icon="stateIcons[rolloutsState('experimental')]"
        :edit-icon="stateIcons[rolloutsState('experimental')]"
        :complete-icon="stateIcons[rolloutsState('experimental')]"
        :color="stateColors[rolloutsState('experimental')]"
        :complete="!!rolloutsCount('experimental')"
        :subtitle="rolloutsCount('experimental') > 1 ? rolloutsCount('experimental') + ' rollouts' : ''"
      />

      <v-divider />

      <v-stepper-item
        editable
        value="staging"
        title="Staging"
        :icon="stateIcons[rolloutsState('staging')]"
        :edit-icon="stateIcons[rolloutsState('staging')]"
        :complete-icon="stateIcons[rolloutsState('staging')]"
        :color="stateColors[rolloutsState('staging')]"
        :complete="!!rolloutsCount('staging')"
        :subtitle="rolloutsCount('staging') > 1 ? rolloutsCount('staging') + ' rollouts' : ''"
      />

      <v-divider />

      <v-stepper-item
        editable
        value="research"
        title="Research"
        :icon="stateIcons[rolloutsState('research')]"
        :edit-icon="stateIcons[rolloutsState('research')]"
        :complete-icon="stateIcons[rolloutsState('research')]"
        :color="stateColors[rolloutsState('research')]"
        :complete="!!rolloutsCount('research')"
        :subtitle="rolloutsCount('research') > 1 ? rolloutsCount('research') + ' rollouts' : ''"
      />

      <v-divider />

      <v-stepper-item
        value="release"
        title="Release"
        :icon="stateIcons[rolloutsState('release')]"
        :edit-icon="stateIcons[rolloutsState('release')]"
        :complete-icon="stateIcons[rolloutsState('release')]"
        :color="stateColors[rolloutsState('release')]"
        :complete="!!rolloutsCount('release')"
        :rules="[() => !!hasActiveRollout('staging')]"
        :editable="!!hasActiveRollout('staging') || !!rolloutsCount('release')"
        :subtitle="
          !hasActiveRollout('staging')
            ? 'No staging rollout'
            : rolloutsCount('release') > 1
              ? rolloutsCount('release') + ' rollouts'
              : ''
        "
      />
    </v-stepper-header>
  </v-stepper>
</template>

<script lang="ts">
  import { Component, Emit, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { RING_TYPE } from '@jouzen/outo-toolkit-vuetify'

  import { stateColors, stateIcons } from '#views/otafr/constants'

  import { OtaFRStore } from '#stores'

  import { OtaFile } from '#types'

  @Component
  class RolloutStepper extends Vue {
    @Prop() public hwType!: string
    @Prop() public appFlavor!: string

    @Prop() public otaFile!: OtaFile

    public readonly stateIcons = stateIcons
    public readonly stateColors = stateColors

    protected readonly otaFRStore = new OtaFRStore()

    @Emit('select')
    public emitSelect(appFlavor: string) {
      return appFlavor
    }

    @Watch('appFlavor', { immediate: true })
    public appFlavorChanged() {
      if (this.appFlavor === 'release' && !this.hasActiveRollout('staging') && !this.rolloutsCount('release')) {
        this.emitSelect('staging')
      }
    }

    public appRollouts(flavor: string) {
      return (this.otaFRStore.rollouts?.[this.hwType as RING_TYPE] || []).filter(
        (r: any) =>
          r.flavor === flavor && r.value.type === this.otaFile?.type && r.value.version === this.otaFile?.version,
      )
    }

    public rolloutsCount(flavor: string) {
      return this.appRollouts(flavor).length
    }

    public rolloutsState(flavor: string) {
      const rollouts = this.appRollouts(flavor)

      const allEqual = (arr: string[]) => arr.every((val) => val === arr[0])

      const hasCompleted = rollouts.some(
        (r: any) =>
          r.state === 'active' &&
          !r.filters.length &&
          r.schedule[r.schedule.length - 1].percentile === 100 &&
          this.$dayjs(r.schedule[r.schedule.length - 1].apply_at).isBefore(this.$dayjs()),
      )

      return hasCompleted
        ? 'completed'
        : allEqual(rollouts.map((r: any) => r.state))
          ? rollouts[0]?.state || 'inactive'
          : 'unknown'
    }

    public hasActiveRollout(flavor: string) {
      return this.appRollouts(flavor).some(
        (r: any) =>
          r.state === 'active' && (!r.schedule || this.$dayjs().isSameOrAfter(r.schedule[0].apply_at, 'date')),
      )
    }
  }

  export default toNative(RolloutStepper)
</script>
