import { round, uniq } from 'lodash-es'

import { pagesPerSecond } from '#views/rdata/constants'

import {
  ConfigFileData,
  MemoryLayout,
  RdataMeasurement,
  RdataParameter,
  RdataSchema,
  RingSensor,
  SchemaValueOption,
} from '#types'

function getSlotNumber(slot: number, sensor: RingSensor, schema: RdataSchema) {
  const slotSchema = schema?.definitions?.measurement_slot_id

  if (![RingSensor.ECG, RingSensor.BIOZ].includes(sensor)) {
    return slot
  }

  const slotValues = slotSchema?.properties?.value?.oneOf?.filter(
    (item: SchemaValueOption) => !item.rules || item.rules.includes(sensor),
  )

  return slotValues[0]?.const
}

export function getFieldItems(sensor: RingSensor, schema: RdataSchema, config: any, field: string): any[] {
  let items: any[] = []

  const dataSchema = schema?.definitions?.[field]

  const dataValues: SchemaValueOption[] = dataSchema?.properties?.value?.anyOf || dataSchema?.properties?.value?.oneOf

  if (sensor === 'BIOZ' && field === 'measurement_data_interval') {
    const allowed: any = {
      0: [31250], // 1024Hz -> 32Hz
      1: [50000], // 2.56kHz -> 20Hz
      2: [50000], // 5.12kHz -> 20Hz
      3: [50000], // 10.24kHz -> 20Hz
      4: [40000], // 25.6kHz -> 25Hz,
      5: [40000], // 51.2kHz -> 25Hz,
      6: [40000], // 100.4kHz -> 25Hz,
      7: [32000], // 256kHz -> 31.25Hz,
      8: [32000], // 512kHz -> 31.25Hz,
    }

    const stimFrequency = getFieldValue(0, sensor, schema, config, 'bioz_stim_freq')

    items = dataValues?.filter(
      (item: SchemaValueOption) =>
        (!item.rules || item.rules.includes(sensor)) &&
        (!allowed[stimFrequency] || allowed[stimFrequency].includes(item.const)),
    )
  } else {
    items = dataValues?.filter((item: SchemaValueOption) => !item.rules || item.rules.includes(sensor))
  }

  return (
    items?.map((item: SchemaValueOption) => ({
      value: item.const,
      text: item.title.replace(`${sensor}: `, ''),
      title: item.title,
      type: sensor,
    })) || []
  )
}

export function getFieldValue(
  slot: number,
  sensor: RingSensor,
  schema: RdataSchema,
  config: ConfigFileData,
  field: string,
): any {
  const dataSchema = schema?.definitions?.[field]

  const slotSchema = schema?.definitions?.measurement_slot_id

  const typeSchema = schema?.definitions?.measurement_channel_description_id

  const slotNumber = getSlotNumber(slot, sensor, schema)

  const measurementData: RdataMeasurement | undefined =
    sensor === RingSensor.SYSTEM
      ? findMatchingMeasurement(slotNumber, sensor, slotSchema, dataSchema, config)
      : findMatchingMeasurement(slotNumber, sensor, slotSchema, typeSchema, config)

  if (measurementData) {
    const fieldData = measurementData.parameters.find(
      (p: RdataParameter) =>
        p.length === dataSchema?.properties?.length.default &&
        p.class_id === dataSchema?.properties?.class_id.default &&
        p.param_id === dataSchema?.properties?.param_id.default,
    )

    const schemaValues = dataSchema?.properties?.value?.anyOf || dataSchema?.properties?.value?.oneOf

    if (fieldData) {
      if (field === 'ring_feature_mask') {
        const selectedFeatures = fieldData.name.split(' - ')[1].split(', ')

        const selectedItems = schemaValues
          .filter((o: SchemaValueOption) => selectedFeatures.includes(o.title))
          .map((o: SchemaValueOption) => ({
            value: o.const,
            title: o.title,
            text: o.title,
            type: sensor,
          }))

        return [...selectedItems]
      } else if (dataSchema.properties.value.type === 'integer' && !schemaValues) {
        return fieldData.value
      } else if (dataSchema.properties.value.type === 'integer' && schemaValues) {
        const option = schemaValues?.find((o: SchemaValueOption) => o.const === fieldData.value)

        if (option) {
          return option.const
        }
      }
    } else if (
      dataSchema?.properties &&
      field !== 'measurement_channel_description_id' &&
      getFieldValue(slot, sensor, schema, config, 'measurement_channel_description_id') !== ''
    ) {
      // Set default field data if channel is set to enabled
      const value =
        typeof dataSchema?.properties?.value?.default !== 'object'
          ? dataSchema?.properties?.value?.default
          : dataSchema?.properties?.value?.default[sensor]

      setFieldValue(slot, sensor, schema, config, field, value)
    }
  }
  return ''
}

export function setFieldValue(
  slot: number,
  sensor: RingSensor,
  schema: RdataSchema,
  config: ConfigFileData,
  field: string,
  value: any,
) {
  if (value !== null) {
    let prevValue = null

    const dataSchema = schema?.definitions?.[field]

    const slotSchema = schema?.definitions?.measurement_slot_id

    const typeSchema = schema?.definitions?.measurement_channel_description_id

    const slotNumber = getSlotNumber(slot, sensor, schema)

    let measurementDatas = getMeasurementDatas(config, sensor, slotNumber, slotSchema, typeSchema)

    if (!measurementDatas.length) {
      measurementDatas = [
        {
          name: sensor + ': Measurement Slot ' + slotNumber,
          parameters: [
            {
              name: [slotSchema?.properties?.value?.title, slotNumber].join(' - '),
              length: slotSchema?.properties?.length?.default,
              class_id: slotSchema?.properties?.class_id?.default,
              param_id: slotSchema?.properties?.param_id?.default,
              value: slotNumber,
            },
          ],
        },
      ]

      config.data_collections[0].measurements.push(...measurementDatas)
    }

    for (const measurementData of measurementDatas) {
      let fieldData = measurementData.parameters.find(
        (p: RdataParameter) =>
          p.length === dataSchema?.properties?.length.default &&
          p.class_id === dataSchema?.properties?.class_id.default &&
          p.param_id === dataSchema?.properties?.param_id.default,
      )

      if (!fieldData) {
        fieldData = {
          length: dataSchema?.properties?.length?.default,
          class_id: dataSchema?.properties?.class_id?.default,
          param_id: dataSchema?.properties?.param_id?.default,
        } as RdataParameter

        measurementData.parameters.push(fieldData)
      }

      prevValue = fieldData.value

      if (field === 'ppg_led_current_steps') {
        const range = ((getFieldValue(slotNumber, sensor, schema, config, 'ppg_led_current_range') ?? 3) + 1) * 32

        fieldData.value = isNaN(value) ? 0 : Math.min(255, Math.max(0, Math.round(+value / (range / 256))))

        fieldData.name = [
          dataSchema.properties.value.title,
          fieldData.value + ' (' + fieldData.value * (range / 256) + 'mA' + ')',
        ].join(' - ')
      } else if (field === 'ring_feature_mask') {
        updateRingFeatureMask(fieldData, value, dataSchema)
      } else if (
        dataSchema?.properties?.value?.minimum !== undefined ||
        dataSchema?.properties?.value?.maximum !== undefined
      ) {
        fieldData.value = isNaN(value)
          ? 0
          : Math.min(
              dataSchema?.properties?.value?.maximum || 100,
              Math.max(dataSchema?.properties?.value?.minimum || 0, parseInt(value)),
            )

        fieldData.name = dataSchema?.properties?.value?.title + ' ' + value
      } else {
        fieldData.value = value

        const schemaValues = dataSchema?.properties?.value?.anyOf || dataSchema?.properties?.value?.oneOf
        const title = schemaValues.find((o: SchemaValueOption) => o.const === value)?.title ?? value

        fieldData.name = [dataSchema?.properties?.value?.title, title].join(' - ')
      }
    }

    if (field === 'ppg_led_settling_time') {
      if (getFieldValue(slotNumber, RingSensor.PPG, schema, config, 'ppg_pd_settling_time') < value) {
        setFieldValue(slotNumber, RingSensor.PPG, schema, config, 'ppg_pd_settling_time', value)
      }
    } else if (field === 'ppg_pd_settling_time') {
      if (getFieldValue(slotNumber, RingSensor.PPG, schema, config, 'ppg_led_settling_time') > value) {
        setFieldValue(slotNumber, RingSensor.PPG, schema, config, 'ppg_led_settling_time', value)
      }
    } else if (field === 'ppg_led_current_range') {
      if (getFieldValue(slotNumber, RingSensor.PPG, schema, config, 'ppg_led_current_steps') !== '') {
        setFieldValue(
          slotNumber,
          RingSensor.PPG,
          schema,
          config,
          'ppg_led_current_steps',

          getFieldValue(slotNumber, RingSensor.PPG, schema, config, 'ppg_led_current_steps') *
            ((((prevValue || 0) + 1) * 32) / 256),
        )
      }
    } else if (field === 'ppg_measurement_averaging' && value !== 0) {
      // If measurement averaging is not 1, set Ambient light cancellation method to 'central'.
      setFieldValue(slotNumber, RingSensor.PPG, schema, config, 'ppg_ambient_light_cancel_method', 0)
    } else if (field === 'ppg_ambient_light_cancel_method' && value !== 0) {
      // If ambient light cancellation method is not 'central', set measurement averaging to 1.
      setFieldValue(slotNumber, RingSensor.PPG, schema, config, 'ppg_measurement_averaging', 0)
    } else if (
      sensor === RingSensor.PPG &&
      field === 'measurement_channel_description_id' &&
      (getFieldValue(0, RingSensor.ECG, schema, config, 'measurement_channel_description_id') ||
        getFieldValue(0, RingSensor.BIOZ, schema, config, 'measurement_channel_description_id'))
    ) {
      // If PPG and ECG or Bioz in use then timing channel needs to exist
      setFieldValue(8, RingSensor.TIMING, schema, config, 'measurement_channel_description_id', 14)
    } else if (sensor === 'BIOZ' && field === 'bioz_stim_freq') {
      // There are restrictions for the data interval items based on bioz stim freq
      const items = getFieldItems(RingSensor.BIOZ, schema, config, 'measurement_data_interval')
      const value = getFieldValue(slotNumber, RingSensor.BIOZ, schema, config, 'measurement_data_interval')

      if (!items.find((i) => i.value === value)) {
        setFieldValue(0, RingSensor.BIOZ, schema, config, 'measurement_data_interval', items[0].value)
      }
    }

    config.data_collections = [...config.data_collections]
  }

  return true
}

function getMeasurementDatas(config: any, sensor: string, slotNumber: number, slotSchema: any, typeSchema: any) {
  const measurementDatas = config?.data_collections[0]?.measurements?.filter((m: RdataMeasurement) => {
    // Check if the measurement data has a parameter matching the slot schema
    const hasParamMatchingSlotSchema: boolean =
      sensor === 'System' ||
      m.parameters.some(
        (p: RdataParameter) =>
          p.length === slotSchema?.properties?.length.default &&
          p.class_id === slotSchema?.properties?.class_id.default &&
          p.param_id === slotSchema?.properties?.param_id.default &&
          p.value === slotNumber,
      )

    // Check if the measurement data has a parameter matching the type schema
    const hasValidParamMatchingTypeSchema: boolean =
      !m.parameters.some(
        (p: RdataParameter) =>
          p.length === typeSchema?.properties?.length.default &&
          p.class_id === typeSchema?.properties?.class_id.default &&
          p.param_id === typeSchema?.properties?.param_id.default,
      ) ||
      m.parameters.some(
        (p: RdataParameter) =>
          p.length === typeSchema?.properties?.length.default &&
          p.class_id === typeSchema?.properties?.class_id.default &&
          p.param_id === typeSchema?.properties?.param_id.default &&
          typeSchema?.properties?.value?.oneOf
            .filter((i: SchemaValueOption) => i.title.startsWith(`${sensor}: `))
            .map((i: SchemaValueOption) => i.const)
            .includes(p.value),
      )

    return hasParamMatchingSlotSchema && hasValidParamMatchingTypeSchema
  })

  return measurementDatas
}

function updateRingFeatureMask(fieldData: RdataParameter, value: any, dataSchema: any) {
  if (typeof value === 'number') {
    const option = dataSchema?.properties?.value?.oneOf?.find((o: SchemaValueOption) => o.const === value)

    if (option) {
      fieldData.value = option.const
      fieldData.name = [dataSchema?.properties?.value?.title, option.title].join(' - ')
    }
  } else if (value.length === 0) {
    // No options selected, default to 'None'
    const option = dataSchema?.properties?.value?.oneOf?.find((o: SchemaValueOption) => o.const === 0)

    fieldData.value = option?.const || 0
    fieldData.name = [dataSchema?.properties?.value?.title, option?.title || 'None'].join(' - ')
  } else {
    fieldData.value = value.map((v: any) => v.value).reduce((acc: number, v: number) => acc + v, 0)

    // Concatenate titles of selected options, excluding 'None'
    const selectedFeatureTitles = value
      .filter((feature: any) => feature.value !== 0)
      .map((feature: any) => feature.title)
      .join(', ')

    fieldData.name = [dataSchema?.properties?.value?.title, selectedFeatureTitles].join(' - ')
  }
}

export function getChannelEnabled(
  slot: number | undefined,
  sensor: RingSensor,
  schema: RdataSchema,
  config: ConfigFileData,
  field?: string,
) {
  if (slot !== undefined && field !== undefined) {
    const dataSchema = schema.definitions[field]

    const slotSchema = schema.definitions.measurement_slot_id

    const measurementData = findMatchingMeasurement(slot, sensor, slotSchema, dataSchema, config)

    return !!measurementData
  } else {
    const typeSchema = schema?.definitions?.measurement_channel_description_id

    return !!config.data_collections[0].measurements.find((m: RdataMeasurement) => {
      return m.parameters.some(
        (p: RdataParameter) =>
          p.length === typeSchema?.properties?.length.default &&
          p.class_id === typeSchema?.properties?.class_id.default &&
          p.param_id === typeSchema?.properties?.param_id.default &&
          typeSchema?.properties?.value?.oneOf
            .filter((i: SchemaValueOption) => i.title.startsWith(`${sensor}: `))
            .map((i: SchemaValueOption) => i.const)
            .includes(p.value),
      )
    })
  }
}

export function setChannelEnabled(
  slot: number,
  sensor: RingSensor,
  schema: RdataSchema,
  config: ConfigFileData,
  enabled: boolean | null,
  field?: string,
) {
  const dataSchema = field ? schema?.definitions?.[field] : schema?.definitions['measurement_channel_description_id']

  const slotSchema = schema?.definitions?.measurement_slot_id

  const slotNumber = getSlotNumber(slot, sensor, schema)

  if (!enabled) {
    config.data_collections[0].measurements = config?.data_collections[0]?.measurements?.filter(
      (m: RdataMeasurement) =>
        !(
          (sensor === RingSensor.SYSTEM ||
            !!m.parameters.find(
              (p: RdataParameter) =>
                p.length === slotSchema?.properties?.length?.default &&
                p.class_id === slotSchema?.properties?.class_id?.default &&
                p.param_id === slotSchema?.properties?.param_id?.default &&
                p.value === slotNumber,
            )) &&
          !!m.parameters.find(
            (p: RdataParameter) =>
              p.length === dataSchema?.properties?.length.default &&
              p.class_id === dataSchema?.properties?.class_id.default &&
              p.param_id === dataSchema?.properties?.param_id.default &&
              (!dataSchema?.properties?.value?.oneOf ||
                dataSchema?.properties?.value?.oneOf
                  .filter((i: SchemaValueOption) => i.title.startsWith(`${sensor}: `))
                  .map((i: SchemaValueOption) => i.const)
                  .includes(p.value)),
          )
        ),
    )
  } else if (!dataSchema?.properties?.value?.oneOf) {
    const measurementData = {
      name: [
        sensor + ': Measurement Slot ' + slotNumber,
        dataSchema?.properties?.value?.title.replace(`${sensor}: `, ''),
      ].join(' - '),
      parameters: [
        {
          name: dataSchema?.properties?.value?.title,
          value: dataSchema?.properties?.value.default,
          length: dataSchema?.properties?.length.default,
          class_id: dataSchema?.properties?.class_id.default,
          param_id: dataSchema?.properties?.param_id.default,
        },
      ],
    }

    config.data_collections[0].measurements.push(measurementData)
  } else {
    for (const value of (dataSchema?.properties?.value?.oneOf || []).filter((i: SchemaValueOption) =>
      i.title.startsWith(sensor),
    )) {
      const measurementData: RdataMeasurement = {
        name: [
          sensor + ': Measurement Slot ' + slotNumber,
          sensor === RingSensor.TEMPERATURE && value.title.replace(`${sensor}: `, ''),
        ]
          .filter(Boolean)
          .join(' - '),
        parameters: [
          {
            name: slotSchema?.properties?.value?.title,
            length: slotSchema?.properties?.length.default,
            class_id: slotSchema?.properties?.class_id.default,
            param_id: slotSchema?.properties?.param_id.default,
            value: slotNumber,
          },
          {
            name: dataSchema?.properties?.value?.title,
            length: dataSchema?.properties?.length?.default,
            class_id: dataSchema?.properties?.class_id?.default,
            param_id: dataSchema?.properties?.param_id?.default,
            value: value.const,
          },
        ],
      }

      config.data_collections[0].measurements.push(measurementData)

      if (sensor !== RingSensor.TEMPERATURE) {
        break
      }
    }
  }

  // ECG and BIOZ are mutually exclusive features

  if (enabled && sensor === RingSensor.ECG) {
    setChannelEnabled(slot, RingSensor.BIOZ, schema, config, false, field)
  } else if (enabled && sensor === RingSensor.BIOZ) {
    setChannelEnabled(slot, RingSensor.ECG, schema, config, false, field)
  }

  if (
    (sensor === RingSensor.ECG || sensor === RingSensor.BIOZ) &&
    getChannelEnabled(undefined, RingSensor.PPG, schema, config)
  ) {
    if (enabled) {
      // If PPG and ECG or Bioz in use then timing channel needs to exist
      setFieldValue(8, RingSensor.TIMING, schema, config, 'measurement_channel_description_id', 14)
    } else {
      setChannelEnabled(8, RingSensor.TIMING, schema, config, false)
    }
  }
}

export function getMonitoringEnabled(
  sensor: RingSensor,
  schema: RdataSchema,
  config: ConfigFileData,
  field: string,
  value?: number,
) {
  const dataSchema = schema.definitions[field]

  const measurementData = config.data_collections[0].measurements.find(
    (m: any) =>
      !!m.parameters.find(
        (p: any) =>
          p.length === dataSchema?.properties?.length.default &&
          p.class_id === dataSchema?.properties?.class_id.default &&
          p.param_id === dataSchema?.properties?.param_id.default,
      ),
  )

  const parameterData = measurementData?.parameters?.find(
    (p: any) =>
      p.length === dataSchema?.properties?.length.default &&
      p.class_id === dataSchema?.properties?.class_id.default &&
      p.param_id === dataSchema?.properties?.param_id.default,
  )

  return sensor === RingSensor.PPG
    ? ((parameterData?.value || 0) | value!) === (parameterData?.value || 0)
    : parameterData?.value || 0
}

export function setMonitoringEnabled(
  sensor: RingSensor,
  schema: RdataSchema,
  config: ConfigFileData,
  field: string,
  value: number,
) {
  const dataSchema = schema.definitions[field]

  let measurementData = config.data_collections[0].measurements.find(
    (m: any) =>
      !!m.parameters.find(
        (p: any) =>
          p.length === dataSchema?.properties?.length.default &&
          p.class_id === dataSchema?.properties?.class_id.default &&
          p.param_id === dataSchema?.properties?.param_id.default,
      ),
  )

  if (!measurementData) {
    measurementData = {
      name: 'Rdata monitor mode',
      parameters: [],
    }

    config.data_collections[0].measurements.push(measurementData)
  }

  let parameterData = measurementData?.parameters?.find(
    (p: any) =>
      p.length === dataSchema?.properties?.length.default &&
      p.class_id === dataSchema?.properties?.class_id.default &&
      p.param_id === dataSchema?.properties?.param_id.default,
  )

  if (!parameterData) {
    parameterData = {
      name: dataSchema?.properties?.value?.title,
      length: dataSchema?.properties?.length.default,
      class_id: dataSchema?.properties?.class_id.default,
      param_id: dataSchema?.properties?.param_id.default,
      value: 0,
    }

    measurementData.parameters.push(parameterData)
  }

  parameterData.value = sensor === RingSensor.PPG ? (parameterData?.value || 0) + value : value
}

export function recordingTimeEstimate(schema: RdataSchema, config: ConfigFileData, memoryLayout?: MemoryLayout) {
  let pagesPerSecondTotal = 0

  if (typeof schema === 'string') {
    schema = JSON.parse(schema)
  }

  const channelSchema = schema.definitions['measurement_channel_description_id']
  const intervalSchema = schema.definitions['measurement_data_interval']

  config.data_collections[0].measurements.forEach((m: RdataMeasurement) => {
    const channelParam = m.parameters.find(
      (p: RdataParameter) =>
        p.length === channelSchema?.properties?.length?.default &&
        p.class_id === channelSchema?.properties?.class_id?.default &&
        p.param_id === channelSchema?.properties?.param_id?.default,
    )

    const intervalParam = m.parameters.find(
      (p: RdataParameter) =>
        p.length === intervalSchema?.properties?.length?.default &&
        p.class_id === intervalSchema?.properties?.class_id?.default &&
        p.param_id === intervalSchema?.properties?.param_id?.default,
    )

    if (channelParam) {
      const valueOption = channelSchema?.properties.value.oneOf.find(
        (o: SchemaValueOption) => o.const === channelParam.value,
      )

      if (intervalParam && valueOption) {
        pagesPerSecondTotal += pagesPerSecond[valueOption.title.split(':')[0]][intervalParam.value]
      }
    }
  })

  return memoryLayout
    ? '~' + `${round((4 * memoryLayout.rdataSize) / (pagesPerSecondTotal || 1) / 60 / 60, 1)}h (${memoryLayout.title})`
    : '~' +
        round((4 * 11264) / (pagesPerSecondTotal || 1) / 60 / 60, 1) +
        'h (customer) / ~' +
        round((4 * 15360) / (pagesPerSecondTotal || 1) / 60 / 60, 1) +
        'h (dev)'
}

export const findMatchingMeasurement = (
  slot: number,
  sensor: RingSensor,
  slotSchema: any,
  dataSchema: any,
  config: ConfigFileData,
) => {
  const measurementData = config.data_collections[0].measurements.find((m: RdataMeasurement) => {
    const hasParamMatchingSlotSchema = m.parameters.some((p: RdataParameter) => {
      return (
        p.length === slotSchema?.properties?.length.default &&
        p.class_id === slotSchema?.properties?.class_id.default &&
        p.param_id === slotSchema?.properties?.param_id.default &&
        p.value === slot
      )
    })

    const hasValidParamMatchingDataSchema = m.parameters.some((p: RdataParameter) => {
      return (
        p.length === dataSchema?.properties?.length.default &&
        p.class_id === dataSchema?.properties?.class_id.default &&
        p.param_id === dataSchema?.properties?.param_id.default &&
        (!dataSchema?.properties?.value?.oneOf ||
          dataSchema?.properties?.value?.oneOf
            .filter((i: SchemaValueOption) => i.title.startsWith(`${sensor}: `))
            .map((i: SchemaValueOption) => i.const)
            .includes(p.value))
      )
    })

    return (sensor === RingSensor.SYSTEM || hasParamMatchingSlotSchema) && hasValidParamMatchingDataSchema
  })

  return measurementData
}

export function updateManagers(managers: string[], file: any) {
  file.managerEmails = uniq(
    managers.filter((m: string) => m !== file?.createdBy && m.includes('@ouraring.com')).map((m) => m.trim()),
  )

  return { ...file }
}

function getSlotIndex(schema: RdataSchema, measurement: RdataMeasurement) {
  const measurementSlotIdOptions = schema.definitions.measurement_slot_id.properties.value.oneOf.map(
    (option: SchemaValueOption) => option.const,
  ) // [0, 1, 2, 3, 4, 5] OR [0, 1, 2, 3, 4, 5, 6, 7, 8]

  const slotParameter = measurement.parameters.find((p: RdataParameter) => {
    return (
      p.length === schema.definitions.measurement_slot_id.properties.length.default &&
      p.class_id === schema.definitions.measurement_slot_id.properties.class_id.default &&
      p.param_id === schema.definitions.measurement_slot_id.properties.param_id.default
    )
  })

  return slotParameter ? measurementSlotIdOptions.indexOf(slotParameter.value) : Infinity
}

function getSensorIndex(schema: RdataSchema, measurement: RdataMeasurement) {
  const channelDescriptionParameter = measurement.parameters.find(
    (p: RdataParameter) =>
      p.length === schema.definitions.measurement_channel_description_id.properties.length.default &&
      p.class_id === schema.definitions.measurement_channel_description_id.properties.class_id.default &&
      p.param_id === schema.definitions.measurement_channel_description_id.properties.param_id.default,
  )

  const channelDescriptionOptions = schema.definitions.measurement_channel_description_id.properties.value.oneOf

  const matchedOption = channelDescriptionOptions.find(
    (option: SchemaValueOption) => option.const === channelDescriptionParameter?.value,
  )

  return matchedOption
    ? channelDescriptionOptions.findIndex((o: SchemaValueOption) =>
        o.title.startsWith(matchedOption.title.split(':')[0]),
      )
    : Infinity
}

export function sortMeasurements(measurements: RdataMeasurement[], schema: RdataSchema) {
  return measurements.sort((measurementA: RdataMeasurement, measurementB: RdataMeasurement) => {
    const slotIndexA = getSlotIndex(schema, measurementA)
    const slotIndexB = getSlotIndex(schema, measurementB)

    const sensorIndexA = getSensorIndex(schema, measurementA)
    const sensorIndexB = getSensorIndex(schema, measurementB)

    return sensorIndexA - sensorIndexB || slotIndexA - slotIndexB || measurementA.name.localeCompare(measurementB.name)
  })
}
