<template>
  <div>
    <v-row>
      <v-col>
        <v-select v-model="task.parameters.inputCommand" label="Command to run in analyze task" :items="commands" />
      </v-col>

      <v-col v-if="task.parameters.inputCommand === 'tlog'">
        <v-select
          v-model="task.parameters.inputCommandBranch"
          label="TLog branch / version"
          :items="tlogBranchesPrefs"
        />
      </v-col>

      <v-col cols="12">
        <v-radio-group v-model="tlogOption">
          <v-row class="ml-n4 mt-n8">
            <v-col cols="6">
              <v-radio value="all" style="min-height: 48px">
                <template #label><div class="ml-4">Include all found fw versions</div></template>
              </v-radio>
            </v-col>

            <v-col cols="6">
              <v-radio value="listed" label="" :disabled="!tlogSampleFilters.length">
                <template #label>
                  <v-select
                    v-model="tlogVersionsPrefs"
                    hide-details
                    density="compact"
                    item-title="name"
                    item-value="uid"
                    label="Include only listed fw versions"
                    :items="tlogSampleFilters"
                    :disabled="!tlogSampleFilters.length"
                    :messages="['Sample list for firmware versions']"
                  />
                </template>
              </v-radio>
            </v-col>
          </v-row>

          <v-row class="ml-n4 mt-0">
            <v-col cols="12">
              <v-radio value="custom" label="">
                <template #label>
                  <v-text-field
                    v-model="tlogOptionsPrefs"
                    hide-details
                    density="compact"
                    label="Use custom command line options"
                    :messages="['Enter tlog command line options / versions']"
                  />
                </template>
              </v-radio>
            </v-col>
          </v-row>
        </v-radio-group>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { PrefsStore, SetupStore } from '#stores'

  import { TaskConfigModes, TaskSettings } from '#types'

  @Component
  class TaskBinary extends Vue {
    @Prop() public mode!: TaskConfigModes
    @Prop() public source!: string

    @Prop() public task!: TaskSettings

    public days = ['1', '2', '3']
    public commands = ['tlog']

    private readonly prefsStore = new PrefsStore()
    private readonly setupStore = new SetupStore()

    public get loading() {
      return this.setupStore.loading
    }

    public get tlogOption() {
      return this.getTlogCommandOptions()
    }

    public set tlogOption(value: string) {
      this.tlogOptionPrefs = value
    }

    public get runCommandPrefs() {
      return this.prefsStore.runCommand
    }

    public get tlogOptionPrefs() {
      return this.prefsStore.tlogOption
    }

    public set tlogOptionPrefs(value: string) {
      this.setTlogCommandOptions(value)

      this.prefsStore.updatePrefs({ key: 'tlogOption', value })

      if (value !== 'listed') {
        this.prefsStore.updatePrefs({ key: 'fwVersions', value: '', flavor: this.source })
      } else {
        this.prefsStore.updatePrefs({
          key: 'fwVersions',
          value: this.tlogSampleFilters[0]?.uid ?? '',
          flavor: this.source,
        })
      }
    }

    public get tlogOptionsPrefs() {
      return this.prefsStore.tlogOptions
    }

    public set tlogOptionsPrefs(value: string) {
      this.task.parameters.inputOptions = value

      this.prefsStore.updatePrefs({ key: 'tlogOptions', value })
    }

    public get tlogBranchPrefs() {
      return this.prefsStore.tlogBranch
    }

    public get tlogBranchesPrefs() {
      return this.prefsStore.tlogBranches
    }

    public get tlogVersionsPrefs() {
      return !this.setupStore.loading ? this.prefsStore.fwVersions[this.source] : ''
    }

    public set tlogVersionsPrefs(value: string) {
      if (value) {
        this.task.parameters.inputOptions = ''

        this.task.parameters.fwVersionListId = value
      } else {
        this.task.parameters.inputOptions = '-run_all'

        delete this.task.parameters.fwVersionListId
      }

      this.prefsStore.updatePrefs({ key: 'fwVersions', value, flavor: this.source })
    }

    public get tlogSampleFilters() {
      return this.setupStore.sampleFilters.filter((f: any) => f.type === 'fw_version')
    }

    @Watch('runCommandPrefs')
    protected runCommandPrefsChanged() {
      if (this.mode === TaskConfigModes.RANDOM) {
        if (this.task.parameters.inputCommand === 'tlog') {
          this.task.parameters.maxUsers = '2500'
        } else {
          this.task.parameters.maxUsers = '250'
        }
      } else if (this.mode !== TaskConfigModes.RERUN) {
        this.task.parameters.maxUsers = '10000'
      }
    }

    @Watch('tlogSampleFilters', { immediate: true })
    protected tlogSampleFiltersChanged() {
      if (!this.loading) {
        this.tlogVersionsPrefs =
          this.tlogVersionsPrefs && this.tlogSampleFilters.find((f: any) => f.uid === this.tlogVersionsPrefs)
            ? this.tlogVersionsPrefs
            : ''
      }
    }

    @Watch('task.parameters.inputCommand', { immediate: true })
    protected taskParamsInputCommandChanged() {
      if (this.mode !== TaskConfigModes.RERUN) {
        delete this.task.parameters.inputDays

        this.task.parameters.maxUsers = this.mode === TaskConfigModes.RANDOM ? '2500' : '10000'

        this.task.parameters.inputCommandBranch =
          this.runCommandPrefs === this.task.parameters.inputCommand ? this.tlogBranchPrefs : 'master'

        this.setTlogCommandOptions()
      }
    }

    private getTlogCommandOptions() {
      if (this.task.parameters.inputOptions === '-run_all') {
        return 'all'
      } else if (this.task.parameters.fwVersionListId) {
        return 'listed'
      } else if (!this.task.parameters.inputOptions) {
        return this.prefsStore.tlogOption
      } else {
        return 'custom'
      }
    }

    private setTlogCommandOptions(value?: string) {
      if ((value || this.tlogOptionPrefs) === 'all') {
        delete this.task.parameters.fwVersionListId

        this.task.parameters.inputOptions = '-run_all'
      } else if ((value || this.tlogOptionPrefs) === 'listed') {
        this.task.parameters.inputOptions = ''

        this.task.parameters.fwVersionListId = this.tlogVersionsPrefs
      } else {
        delete this.task.parameters.fwVersionListId

        this.task.parameters.inputOptions = this.tlogOptionsPrefs
      }
    }
  }

  export default toNative(TaskBinary)
</script>

<style lang="scss" scoped>
  :deep(.v-radio) {
    label {
      min-width: 0;
    }
  }
</style>
