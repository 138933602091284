<template>
  <v-btn
    v-if="otaRollout.state === 'disabled'"
    color="success"
    text="Enable this rollout"
    prepend-icon="mdi-rocket-launch-outline"
    :disabled="isLoading || !otaRollout.id"
    @click="changeRolloutState(otaRollout, 'active')"
  />

  <v-btn
    v-else
    color="error"
    text="Disable this rollout"
    prepend-icon="mdi-close-outline"
    :disabled="isLoading || !otaRollout.id"
    @click="changeRolloutState(otaRollout, 'disabled')"
  />

  <v-spacer />

  <v-btn v-if="undo" class="mr-2" text="Undo create" @click="emitUndo()" />
  <v-btn v-else-if="cancel" class="mr-2" text="Cancel create" @click="emitCancel()" />

  <template v-if="!!otaRollout.id">
    <v-btn
      color="success"
      text="Activate this rollout"
      prepend-icon="mdi-rocket-launch-outline"
      :disabled="isLoading || otaRollout.state !== 'pending'"
      @click="changeRolloutState(otaRollout, 'active')"
    />
  </template>

  <template v-else>
    <v-btn
      color="success"
      text="Launch this rollout"
      prepend-icon="mdi-rocket-launch-outline"
      :disabled="!launch || isLoading || !otaRollout?.value"
      @click="createNewRollout(otaRollout)"
    />
  </template>
</template>

<script lang="ts">
  import { Component, Emit, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { OtaFRStore, SetupStore } from '#stores'

  import { OtaRollout } from '#types'

  @Component
  class RolloutActions extends Vue {
    @Prop() public undo!: boolean
    @Prop() public cancel!: boolean
    @Prop() public launch!: boolean

    @Prop() public hwType!: string
    @Prop() public appFlavor!: string

    @Prop() public otaRollout!: OtaRollout

    @Prop() public allRollouts!: OtaRollout[]

    protected readonly otaFRStore = new OtaFRStore()
    protected readonly setupStore = new SetupStore()

    public get isLoading() {
      return this.otaFRStore.loading
    }

    public get hasActiveRollouts() {
      return (
        this.allRollouts.filter(
          (r: any) => r.state === 'active' && (!r.schedule || this.$dayjs().isAfter(r.schedule[0].apply_at, 'date')),
        ).length > 1
      )
    }

    @Emit('undo')
    public emitUndo() {
      return null
    }

    @Emit('cancel')
    public emitCancel() {
      return null
    }

    @Emit('launch')
    public emitLaunch(rollout: any) {
      return {
        hwType: this.hwType,
        appFlavor: this.appFlavor,
        otaRollout: rollout || this.otaRollout,
      }
    }

    public async createNewRollout(settings: any) {
      const filter = this.otaRollout.filters.find((f: any) => f.startsWith('label:rollout:firmware:'))

      if (filter && settings.info.list) {
        const details = await this.setupStore.fetchSampleFilter(this.appFlavor, settings.info.list)

        await this.otaFRStore.createRolloutLabel(this.appFlavor, { label: filter.slice(6), users: details?.data ?? [] })
      }

      const rollout = await this.otaFRStore.createRollout({ rollout: settings })

      await this.otaFRStore.changeState({ state: 'active', rollout: rollout.data })

      this.emitLaunch(rollout.data)
    }

    public async changeRolloutState(settings: any, state: string) {
      this.$confirm('Confirm rollout state change', `This will change rollout to ${state} state, are you sure?`, {
        buttonTrueColor: 'error',
      }).then((confirmed) => {
        if (confirmed) {
          this.otaFRStore.changeState({ state, rollout: settings })
        }
      })
    }
  }

  export default toNative(RolloutActions)
</script>
