<template>
  <div>
    <v-app-bar>
      <v-tabs style="max-width: 600px" :model-value="tab">
        <v-tab to="firmware">FW Versions</v-tab>
        <v-tab to="application">App Versions</v-tab>
        <v-tab to="warranty">Warranty Reasons</v-tab>
      </v-tabs>

      <v-spacer />

      <v-menu location="bottom end" offset="16" min-width="300" :close-on-content-click="false">
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            icon="mdi-filter-variant-plus"
            :color="props.value ? 'primary' : ''"
            :disabled="tab === 'warranty'"
          />
        </template>

        <v-list>
          <v-list-subheader>Extra filtering options</v-list-subheader>

          <v-list-item>
            <span>Only show active rings</span>
            <template #append>
              <v-switch
                :model-value="routeParams.onlyActive == 'true'"
                @update:model-value="routeParams.onlyActive = $event ? 'true' : 'false'"
              />
            </template>

            <v-tooltip location="top">
              <template #activator="{ props }">
                <v-icon v-bind="props" color="primary" class="ml-1 mr-4" size="small">mdi-information</v-icon>
              </template>
              <span>active = client config change or jzlog upload within the last 7 days</span>
            </v-tooltip>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-container>
      <v-row>
        <v-col v-if="tab === 'firmware'" cols="9">
          <div class="text-h5 font-weight-light">Firmware versions per operating system</div>

          <div class="text-subtitle-2 text-grey-darken-2 font-weight-light">
            <template v-if="routeParams.onlyActive">
              Only data from currently active rings is included in the charts.
              <v-tooltip location="top">
                <template #activator="{ props }">
                  <v-icon v-bind="props" color="primary">mdi-information</v-icon>
                </template>
                <span>
                  "Current active" means that the client config has changed or jzlog based battery or summary data is
                  processed within the last 7 days.
                </span>
              </v-tooltip>
            </template>
            <template v-else>Charts include data from both active and none active rings!</template>
          </div>
        </v-col>

        <v-col v-else-if="tab === 'application'" cols="9">
          <div class="text-h5 font-weight-light">Application versions per operating system</div>

          <div class="text-subtitle-2 text-grey-darken-2 font-weight-light">
            <template v-if="routeParams.onlyActive">
              Only data from currently active rings is included in the charts.
              <v-tooltip location="top">
                <template #activator="{ props }">
                  <v-icon color="primary" v-bind="props">mdi-information</v-icon>
                </template>
                <span>
                  "Current active" means that the client config has changed or jzlog based battery or summary data is
                  processed within the last 7 days.
                </span>
              </v-tooltip>
            </template>
            <template v-else>Charts include data from both active and none active rings!</template>
          </div>
        </v-col>

        <v-col v-else-if="tab === 'warranty'" cols="9">
          <div class="text-h5 font-weight-light">Warranty reasons for all the replaced rings</div>

          <div class="text-subtitle-2 text-grey-darken-2 font-weight-light">
            Only data from failures during warranty are included in the charts
          </div>
        </v-col>

        <v-col cols="3" class="d-flex justify-end align-top">
          <v-btn text="Refresh" color="primary" :loading="isLoading" @click="updateData()" />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <StatsFilter v-model:search-inputs="routeParams" :app-flavors="appFlavors" />
        </v-col>
      </v-row>

      <v-row>
        <template v-if="isLoading">
          <v-progress-circular size="96" class="ma-auto" style="margin-top: 25vh !important" />
        </template>

        <template v-else-if="!!errorText">
          <v-col cols="12">
            <v-alert tile type="error" color="red">
              {{ errorText }}

              <v-btn text="Try again" color="primary" @click="updateData()" />
            </v-alert>
          </v-col>
        </template>

        <template v-else>
          <v-col>
            <v-expansion-panels
              v-if="tab === 'firmware'"
              multiple
              style="z-index: 0"
              :model-value="fwChartIndices.filter((i) => !routeParams.collapsedCards.split(',').includes(i.toString()))"
              @update:model-value="
                routeParams.collapsedCards = fwChartIndices.filter((i) => !($event as number[]).includes(i)).join(',')
              "
            >
              <v-expansion-panel v-for="fwChart in ringTypes" :key="fwChart.value" class="my-4" :title="fwChart.title">
                <v-expansion-panel-text>
                  <VersionChart v-if="fwChartsData?.[fwChart.value]" :chart-data="fwChartsData[fwChart.value]" />
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>

            <v-expansion-panels
              v-else-if="tab === 'application'"
              multiple
              style="z-index: 0"
              :model-value="[0, 1].filter((i) => !routeParams.collapsedCards.split(',').includes(i.toString()))"
              @update:model-value="
                routeParams.collapsedCards = [0, 1].filter((i) => !($event as number[]).includes(i)).join(',')
              "
            >
              <v-expansion-panel class="my-4">
                <v-expansion-panel-title>iOS ({{ (appIos && appIos.count) || '0' }})</v-expansion-panel-title>

                <v-expansion-panel-text>
                  <VersionChart v-if="!!appIos" :chart-data="appIos" />
                </v-expansion-panel-text>
              </v-expansion-panel>

              <v-expansion-panel class="my-4">
                <v-expansion-panel-title>
                  Android ({{ (appAndroid && appAndroid.count) || '0' }})
                </v-expansion-panel-title>

                <v-expansion-panel-text>
                  <VersionChart v-if="!!appAndroid" :chart-data="appAndroid" />
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>

            <v-expansion-panels
              v-else-if="tab === 'warranty'"
              multiple
              style="z-index: 0"
              :model-value="[0].filter((i) => !routeParams.collapsedCards.split(',').includes(i.toString()))"
              @update:model-value="
                routeParams.collapsedCards = [0].filter((i) => !($event as number[]).includes(i)).join(',')
              "
            >
              <v-expansion-panel class="my-4">
                <v-expansion-panel-title>Warranty Reasons</v-expansion-panel-title>

                <v-expansion-panel-text>
                  <WarrantyChart v-if="warrantyStats" :chart-data="warrantyStats" />
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </template>
      </v-row>
    </v-container>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { Debounce, RING_STATE, RouteParams, getRingTypesByState } from '@jouzen/outo-toolkit-vuetify'

  import { appFlavors } from '#views/stats/constants'

  import { getCounts, getFilteredFwVersions, getFwChartData, getOlderVersions } from '#views/stats/utilities'

  import { SetupStore, StatsStore } from '#stores'

  import { FwChartData } from '#types/stats'

  const env = import.meta.env.VITE_APP_ENV

  @Component
  class StatsView extends mixins(RouteParams) {
    @Prop() public tab!: 'firmware' | 'application' | 'warranty'

    public routeParams = {
      appFlavor: env == 'release' ? 'release' : env === 'staging' ? 'staging' : 'debug',
      onlyActive: 'true',
      labelListId: '',
      sampleListId: '',
      dataEndDate: '',
      userEndDate: '',
      dataStartDate: '',
      userStartDate: '',
      collapsedCards: '',
    }

    public appIos: any = null
    public appAndroid: any = null

    public fwChartsData: { [key: string]: FwChartData } = {}

    public readonly ringTypes = getRingTypesByState([~RING_STATE.DEPRECATED, RING_STATE.INTERNAL])
      .concat(getRingTypesByState([~RING_STATE.DEPRECATED, RING_STATE.RELEASED]))
      .reverse()

    public readonly appFlavors = appFlavors

    private readonly setupStore = new SetupStore()
    private readonly statsStore = new StatsStore()

    public get isLoading() {
      return this.statsStore.loading
    }

    public get errorText() {
      return this.statsStore.error
    }

    public get appVersions() {
      return this.statsStore.appVersions
    }

    public get firmwareVersions() {
      return this.statsStore.firmwareVersions
    }

    public get warrantyStats() {
      return {
        data: this.statsStore.warranty?.warrantyReasons ?? [],
        xAxes: 'reason',
        title: 'Warranty Reason',
        series: [{ name: '', field: 'count', stacked: true }],
        colors: ['#dc67ab', '#a0dc67'],
      }
    }

    public get fwChartIndices() {
      return Array.from({ length: this.ringTypes.length }, (_, index) => index)
    }

    @Watch('appVersions')
    protected appVersionsChanged() {
      const appVersions = this.appVersions || {}

      const olderCounts = getCounts(this.ringTypes)
      const totalCounts = getCounts(this.ringTypes)
      const olderVersions = getOlderVersions(this.ringTypes)

      Object.keys(appVersions).forEach((os: string) => {
        if (os === 'android' || os === 'ios') {
          appVersions[os].forEach((version: any, index: number) => {
            if (index < appVersions[os].length - 24) {
              olderCounts[os] += version.appVersionCount || 0
            }

            totalCounts[os] += version.appVersionCount || 0

            version.clientVersion = (version.clientVersion || '').slice(0, 16)
          })

          if (olderCounts[os]) {
            const sliceIndex = appVersions[os].length - 24

            olderVersions[os] = appVersions[os].slice(0, sliceIndex)

            appVersions[os].splice(0, sliceIndex, {
              clientVersion: 'Older',
              appVersionCount: olderCounts[os],
            })
          }
        }
      })

      this.appIos = {
        data: appVersions.ios,
        olderVersions: olderVersions.ios,
        xAxes: 'clientVersion',
        series: [{ name: `iOS (${totalCounts.ios})`, field: 'appVersionCount', stacked: false }],
        count: totalCounts.ios,
        colors: ['#6794dc'],
      }

      this.appAndroid = {
        data: appVersions.android,
        olderVersions: olderVersions.android,
        count: totalCounts.android,
        xAxes: 'clientVersion',
        series: [{ name: `Android (${totalCounts.android})`, field: 'appVersionCount', stacked: false }],
        colors: ['#66b7dc'],
      }
    }

    @Watch('firmwareVersions')
    protected firmwareVersionsChanged() {
      const fwVersions = this.firmwareVersions || {}

      const olderCounts = getCounts(this.ringTypes)
      const totalCounts = getCounts(this.ringTypes)
      const groupCounts = getCounts(this.ringTypes)
      const olderVersions = getOlderVersions(this.ringTypes)
      const filteredFwVersions = getFilteredFwVersions(this.ringTypes)

      Object.keys(fwVersions).forEach((hw: string) => {
        if (!this.ringTypes.find((r) => r.value === hw)) {
          return
        }

        fwVersions[hw].forEach((version: any) => {
          if (version.firmwareVersion.split('.').pop() >= 100 && hw !== 'oreo') {
            groupCounts[hw] += version.fwVersionCount || 0
            groupCounts.iosCount[hw] += version.iosCount || 0
            groupCounts.androidCount[hw] += version.androidCount || 0
          } else if (version) {
            filteredFwVersions[hw].push(version)
          }

          totalCounts[hw] += version.fwVersionCount || 0
          totalCounts.iosCount[hw] += version.iosCount || 0
          totalCounts.androidCount[hw] += version.androidCount || 0

          version.firmwareVersion = (version.firmwareVersion || '').slice(0, 16)
        })

        filteredFwVersions[hw].forEach((version: any, index: number) => {
          if (index < filteredFwVersions[hw].length - (groupCounts[hw] ? 23 : 24)) {
            olderCounts[hw] += version.fwVersionCount || 0
            olderCounts.iosCount[hw] += version.iosCount || 0
            olderCounts.androidCount[hw] += version.androidCount || 0
          }
        })

        if (groupCounts[hw]) {
          filteredFwVersions[hw].unshift({
            firmwareVersion: 'Nightly',
            fwVersionCount: groupCounts[hw] as number,
            iosCount: groupCounts.iosCount[hw],
            androidCount: groupCounts.androidCount[hw],
          })
        }

        if (olderCounts[hw]) {
          const sliceIndex = filteredFwVersions[hw].length - (groupCounts[hw] ? 23 : 24)

          olderVersions[hw] = filteredFwVersions[hw].slice(0, sliceIndex)

          filteredFwVersions[hw].splice(0, sliceIndex, {
            firmwareVersion: 'Older',
            fwVersionCount: olderCounts[hw] as number,
            iosCount: olderCounts.iosCount[hw],
            androidCount: olderCounts.androidCount[hw],
          })
        }
      })

      this.fwChartsData = getFwChartData(filteredFwVersions, olderVersions, totalCounts, this.ringTypes)
    }

    @Watch('routeParams', { immediate: true })
    protected routeParamsChanged(val: any, oldVal: any) {
      // Do not refresh view if user only toggled an expansion panel
      if (oldVal && val.collapsedCards !== oldVal.collapsedCards) {
        return
      }

      this.updateData()
    }

    public async mounted() {
      this.setupStore.listSampleFilters(this.routeParams.appFlavor)
    }

    @Debounce(500)
    public async updateData() {
      if (this.tab === 'firmware') {
        await this.statsStore.listFirmwareVersions(this.routeParams.appFlavor, this.routeParams)
      } else if (this.tab === 'application') {
        await this.statsStore.listAppVersions(this.routeParams.appFlavor, this.routeParams)
      } else {
        await this.statsStore.listWarrantyReasons(this.routeParams)
      }
    }
  }

  export default toNative(StatsView)
</script>
