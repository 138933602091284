<template>
  <v-form validate-on="invalid-input" @update:model-value="valid($event)">
    <div class="text-overline mt-4">Target</div>

    <v-row>
      <v-col cols="6">
        <v-select
          v-model="rolloutTargetSetting"
          label="Rollout target"
          :items="rolloutTargets"
          :readonly="readonly"
          :disabled="needsOuraniansRollout"
        />
      </v-col>

      <v-col cols="6">
        <v-text-field
          v-if="rolloutTargetSetting === 'all'"
          readonly
          label="Rollout segment"
          :model-value="'everyone'"
        />

        <v-select
          v-else-if="rolloutTargetSetting === 'oura'"
          v-model="rolloutTargetSegment"
          label="Rollout segment"
          :items="internalDomains"
          :readonly="readonly"
          :disabled="needsOuraniansRollout"
        />

        <v-text-field
          v-else-if="rolloutTargetSetting === 'group'"
          v-model="rolloutTargetSegment"
          label="Rollout segment"
          :readonly="readonly"
          @blur="rolloutTargetSegment = rolloutTargetSegment || 'fw-beta'"
        />

        <v-select
          v-else-if="rolloutTargetSetting === 'label'"
          v-model="rolloutTargetSegment"
          label="Rollout segment"
          item-value="uid"
          item-title="name"
          :items="userLabels"
          :readonly="readonly"
        />

        <v-select
          v-else-if="rolloutTargetSetting === 'sample'"
          v-model="rolloutTargetSegment"
          label="Rollout segment"
          item-value="uid"
          item-title="name"
          :items="sampleLists"
          :readonly="readonly"
        />
      </v-col>
    </v-row>

    <div class="text-overline">Schedule</div>

    <v-row>
      <v-col class="d-flex" cols="6">
        <v-menu v-model="scheduleMenu" offset-y min-width="auto" :close-on-content-click="false">
          <template #activator="{ props }">
            <v-text-field
              v-bind="props"
              readonly
              label="Rollout date"
              :model-value="
                $dayjs(rolloutScheduleDate).format('DD MMM YYYY') +
                ($dayjs().isSame(rolloutScheduleDate, 'date') ? ' (Today)' : '')
              "
              :error-messages="
                !otaRollout?.id && $dayjs().isAfter(rolloutScheduleDate, 'date')
                  ? ['Selected date is in the past!']
                  : []
              "
            >
              <template #append-inner>
                <v-icon :color="readonly ? 'primary' : ''">mdi-calendar-outline</v-icon>
              </template>
            </v-text-field>
          </template>

          <v-date-picker
            v-if="!readonly"
            :model-value="$dayjs(rolloutScheduleDate).toDate()"
            @update:model-value="((rolloutScheduleDate = $dayjs($event).format('YYYY-MM-DD')), (scheduleMenu = false))"
          />
          <v-list v-else>
            <v-list-item
              v-for="step in otaRollout.schedule"
              :key="step.apply_at"
              :active="currentStep === step"
              :title="$dayjs(step.apply_at).format('DD MMM YYYY - hh:mm')"
            >
              <template #append>{{ step.percentile }}%</template>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>

      <v-col class="d-flex flex-column" cols="6">
        <v-text-field
          v-if="rolloutScheduleType.startsWith('manual')"
          clearable
          label="Rollout percentage"
          :readonly="readonly"
          :model-value="rolloutScheduleType.split('-')[1] || ''"
          @click:clear="rolloutScheduleType = 'daily-1'"
          @update:model-value="rolloutScheduleType = 'manual-' + $event"
          @blur="!rolloutScheduleType.split('-')[1] && (rolloutScheduleType = 'daily-1')"
        />

        <v-select
          v-else
          v-model="rolloutScheduleType"
          label="Schedule type"
          :items="createSchedules"
          :readonly="readonly"
          :disabled="rolloutTargetSetting === 'sample'"
        />
      </v-col>
    </v-row>

    <div class="text-overline">Advanced</div>

    <v-row>
      <v-col cols="4" class="d-flex">
        <v-select v-model="appPlatform" label="Platform" :items="appPlatforms" :readonly="readonly" />
      </v-col>

      <v-col cols="4" class="d-flex">
        <v-text-field
          v-model="appMinVersion"
          clearable
          label="Min app version"
          :rules="[validateVersion]"
          :readonly="readonly"
          :disabled="!appPlatform"
        />
      </v-col>

      <v-col cols="4" class="d-flex">
        <v-text-field
          v-model="appMaxVersion"
          clearable
          label="Max app version"
          :rules="[validateVersion]"
          :readonly="readonly"
          :disabled="!appPlatform"
        />
      </v-col>
    </v-row>

    <v-divider />

    <v-row class="mt-3">
      <v-col cols="4"><div class="pl-3 py-4">Set required ecore version</div></v-col>

      <v-col cols="4">
        <v-text-field
          v-model="ecoreMinVersion"
          clearable
          label="Min ecore version"
          :readonly="readonly"
          :rules="[validateVersion]"
        />
      </v-col>

      <v-col cols="4">
        <v-text-field
          v-model="ecoreMaxVersion"
          clearable
          label="Max ecore version"
          :readonly="readonly"
          :rules="[validateVersion]"
        />
      </v-col>
    </v-row>

    <v-row class="mt-n5">
      <v-col cols="4"><div class="pl-3 py-4">Set required firmware version</div></v-col>

      <v-col cols="4">
        <v-text-field
          v-model="firmwareMinVersion"
          clearable
          label="Min firmware version"
          :readonly="readonly"
          :rules="[validateVersion]"
        />
      </v-col>

      <v-col cols="4">
        <v-text-field
          v-model="firmwareMaxVersion"
          clearable
          label="Max firmware version"
          :readonly="readonly"
          :rules="[validateVersion]"
        />
      </v-col>
    </v-row>

    <v-row class="mt-n5">
      <v-col cols="4"><div class="pl-3 py-4">Set required bootloader version</div></v-col>

      <v-col cols="4">
        <v-text-field
          v-model="bootloaderMinVersion"
          clearable
          label="Min bootloader version"
          :readonly="readonly"
          :rules="[validateVersion]"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="bootloaderMaxVersion"
          clearable
          label="Max bootloader version"
          :readonly="readonly"
          :rules="[validateVersion]"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script lang="ts">
  import { Component, Emit, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { appPlatforms, createSchedules, internalDomains, rolloutTargets } from '#views/otafr/constants'

  import { hasOuranianRollout, validateVersion } from '#views/otafr/utilities'

  import { OtaFRStore, SetupStore } from '#stores'

  import { CreateRollout, OtaRollout } from '#types'

  @Component
  class otaRollout extends Vue {
    @Prop() public hwType!: string
    @Prop() public appFlavor!: string

    @Prop() public otaRollout!: OtaRollout | CreateRollout

    @Prop() public allRollouts!: OtaRollout[]

    @Emit('valid')
    public valid(valid: boolean | null) {
      return valid
    }

    @Watch('appFlavor', { immediate: true })
    public appFlavorChanged() {
      if (!this.readonly && this.appFlavor === 'release' && this.needsOuraniansRollout) {
        this.rolloutTargetSetting = 'oura'
      }
    }

    public scheduleMenu = false

    public readonly appPlatforms = appPlatforms
    public readonly rolloutTargets = rolloutTargets
    public readonly internalDomains = internalDomains
    public readonly createSchedules = createSchedules

    public readonly validateVersion = validateVersion

    protected readonly otaFRStore = new OtaFRStore()
    protected readonly setupStore = new SetupStore()

    public get readonly() {
      return !!this.otaRollout?.id || !this.otaRollout.value
    }

    public get userLabels() {
      return this.otaFRStore.labels?.map((i: any) => ({ uid: i.name, name: i.name })) || []
    }

    public get sampleLists() {
      const sampleLists = this.setupStore.sampleFilters.filter((s) => s.type === 'user_uuid')

      return sampleLists.map((s: any) => ({ uid: s.uid ?? '', name: s.name }))
    }

    public get currentStep() {
      return (
        Array.isArray(this.otaRollout.schedule) &&
        this.otaRollout.schedule.findLast((r: any) => this.$dayjs(r.apply_at).isSameOrBefore(this.$dayjs()))
      )
    }

    public get appPlatform() {
      return this.otaRollout?.platform || ''
    }

    public set appPlatform(platform: string) {
      if (!platform) {
        delete this.otaRollout.platform
        delete this.otaRollout.min_version
        delete this.otaRollout.max_version
      } else {
        this.otaRollout.platform = platform
      }
    }

    public get appMinVersion() {
      return this.otaRollout?.min_version ?? ''
    }

    public set appMinVersion(version: string) {
      if (version) {
        this.otaRollout.min_version = version
      } else {
        delete this.otaRollout.min_version
      }
    }

    public get appMaxVersion() {
      return this.otaRollout?.max_version ?? ''
    }

    public set appMaxVersion(version: string) {
      if (version) {
        this.otaRollout.max_version = version
      } else {
        delete this.otaRollout.max_version
      }
    }

    public get ecoreMinVersion() {
      return (
        this.otaRollout?.filters
          .find((f: any) => f.startsWith('v:ecore:'))
          ?.split(':')[2]
          ?.replace('0.0.0', '') ?? ''
      )
    }

    public set ecoreMinVersion(version: string) {
      const maxVersion = this.ecoreMaxVersion

      this.otaRollout.filters = this.otaRollout.filters.filter((f: any) => !f.startsWith('v:ecore:'))

      if (version || maxVersion) {
        this.otaRollout.filters.push(maxVersion ? `v:ecore:${version || '0.0.0'}:${maxVersion}` : `v:ecore:${version}`)
      }
    }

    public get ecoreMaxVersion() {
      return this.otaRollout?.filters.find((f: any) => f.startsWith('v:ecore:'))?.split(':')[3] ?? ''
    }

    public set ecoreMaxVersion(version: string) {
      const minVersion = this.ecoreMinVersion

      this.otaRollout.filters = this.otaRollout.filters.filter((f: any) => !f.startsWith('v:ecore:'))

      if (version || minVersion) {
        this.otaRollout.filters.push(`v:ecore:${minVersion || '0.0.0'}` + (version ? `:${version}` : ''))
      }
    }

    public get firmwareMinVersion() {
      return (
        this.otaRollout?.filters
          .find((f: any) => f.startsWith(`v:firmware_${this.hwType}`))
          ?.split(':')[2]
          ?.replace('0.0.0', '') ?? ''
      )
    }

    public set firmwareMinVersion(version: string) {
      const maxVersion = this.firmwareMaxVersion

      this.otaRollout.filters = this.otaRollout.filters.filter((f: any) => !f.startsWith(`v:firmware_${this.hwType}`))

      if (version || maxVersion) {
        this.otaRollout.filters.push(
          maxVersion
            ? `v:firmware_${this.hwType}:${version || '0.0.0'}:${maxVersion}`
            : `v:firmware_${this.hwType}:${version}`,
        )
      }
    }

    public get firmwareMaxVersion() {
      return (
        this.otaRollout?.filters
          .find((f: any) => f.startsWith(`v:firmware_${this.hwType}`))
          ?.split(':')[3]
          ?.replace('0.0.0', '') ?? ''
      )
    }

    public set firmwareMaxVersion(version: string) {
      const minVersion = this.firmwareMinVersion

      this.otaRollout.filters = this.otaRollout.filters.filter((f: any) => !f.startsWith(`v:firmware_${this.hwType}:`))

      if (version || minVersion) {
        this.otaRollout.filters.push(
          `v:firmware_${this.hwType}:${minVersion || '0.0.0'}` + (version ? `:${version}` : ''),
        )
      }
    }

    public get bootloaderMinVersion() {
      return this.otaRollout?.filters.find((f: any) => f.startsWith(`v:booloader_${this.hwType}`))?.split(':')[2] ?? ''
    }

    public set bootloaderMinVersion(version: string) {
      const maxVersion = this.bootloaderMaxVersion

      this.otaRollout.filters = this.otaRollout.filters.filter((f: any) => !f.startsWith(`v:booloader_${this.hwType}:`))

      if (version || maxVersion) {
        this.otaRollout.filters.push(
          maxVersion
            ? `v:booloader_${this.hwType}:${version || '0.0.0'}:${maxVersion}`
            : `v:booloader_${this.hwType}:${version}`,
        )
      }
    }

    public get bootloaderMaxVersion() {
      return this.otaRollout?.filters.find((f: any) => f.startsWith(`v:booloader_${this.hwType}`))?.split(':')[3] ?? ''
    }

    public set bootloaderMaxVersion(version: string) {
      const minVersion = this.bootloaderMinVersion

      this.otaRollout.filters = this.otaRollout.filters.filter((f: any) => !f.startsWith(`v:booloader_${this.hwType}`))

      if (version || minVersion) {
        this.otaRollout.filters.push(
          `v:booloader_${this.hwType}:${minVersion || '0.0.0'}` + (version ? `:${version}` : ''),
        )
      }
    }

    public get rolloutTargetSetting() {
      if (
        this.otaRollout?.filters.find(
          (f: any) => f.startsWith('oura_internal') || f.startsWith('label:oura_internal:domain:'),
        )
      ) {
        return 'oura'
      } else if (this.otaRollout?.filters.find((f: any) => f.startsWith('label:rollout:firmware:'))) {
        return 'sample'
      } else if (this.otaRollout?.filters.find((f: any) => f.startsWith('oura_email:'))) {
        return 'group'
      } else if (this.otaRollout?.filters.find((f: any) => f.startsWith('label:'))) {
        return 'label'
      }

      return 'all'
    }

    public set rolloutTargetSetting(target: string) {
      delete this.otaRollout.info.list

      this.otaRollout.filters = this.otaRollout?.filters.filter(
        (f: any) => !f.startsWith('oura_internal') && !f.startsWith('oura_email:') && !f.startsWith('label:'),
      )

      if (target === 'oura') {
        this.rolloutScheduleType = 'daily-1'

        this.otaRollout.filters.push('oura_internal')
      } else if (target === 'group') {
        this.rolloutScheduleType = 'daily-1'

        this.otaRollout.filters.push(`oura_email:fw-beta`)
      } else if (target === 'label') {
        this.rolloutScheduleType = 'daily-1'

        this.otaRollout.filters.push(`label:${this.userLabels[0]?.uid || ''}`)
      } else if (target === 'sample') {
        this.rolloutScheduleType = 'daily-100:100:100:100:100:100:100:0'

        this.otaRollout.info.list = this.sampleLists[0]?.uid || ''

        const label = `rollout:firmware:${this.hwType}:${this.appFlavor ?? 'release'}:${this.otaRollout.value.version.replace(
          /\./g,
          '_',
        )}`

        this.otaRollout.filters.push(`label:${label}`)
      } else {
        this.rolloutScheduleType = 'weekly-5:30:100'
      }
    }

    public get rolloutTargetSegment() {
      if (this.rolloutTargetSetting === 'oura') {
        const filter = this.otaRollout.filters.find((f: any) => f.startsWith('label:oura_internal:domain:'))

        return filter?.split(':')[3] || ''
      } else if (this.rolloutTargetSetting === 'group') {
        const filter = this.otaRollout.filters.find((f: any) => f.startsWith('oura_email:'))

        return filter?.split(':')[1] || ''
      } else if (this.rolloutTargetSetting === 'label') {
        const filter = this.otaRollout.filters.find((f: any) => f.startsWith('label:'))

        return filter?.slice(6) || ''
      } else if (this.rolloutTargetSetting === 'sample') {
        const filter = this.otaRollout.filters.find((f: any) => f.startsWith('label:rollout:firmware:'))

        return filter ? (this.otaRollout.info.list ?? '') : ''
      }

      return 'everyone'
    }

    public set rolloutTargetSegment(segment: string) {
      const target = this.rolloutTargetSetting

      this.otaRollout.filters = this.otaRollout?.filters.filter(
        (f: any) => !f.startsWith('oura_internal') && !f.startsWith('oura_email:') && !f.startsWith('label:'),
      )

      if (target === 'oura') {
        if (!segment) {
          this.otaRollout.filters.push('oura_internal')
        } else {
          this.otaRollout.filters.push(`label:oura_internal:domain:${segment}`)
        }
      } else if (target === 'group') {
        this.otaRollout.filters.push(`oura_email:${segment}`)
      } else if (target === 'label') {
        this.otaRollout.filters.push(`label:${segment}`)
      } else if (target === 'sample') {
        this.otaRollout.info.list = segment

        const label = `rollout:firmware:${this.hwType}:${this.appFlavor ?? 'release'}:${this.otaRollout.value.version.replace(
          /\./g,
          '_',
        )}`

        this.otaRollout.filters.push(`label:rollout:firmware:${label}`)
      }
    }

    public get rolloutScheduleDate() {
      if (!Array.isArray(this.otaRollout?.schedule)) {
        return this.$dayjs(this.otaRollout?.schedule.date_start).format('YYYY-MM-DD')
      } else {
        return this.$dayjs(this.otaRollout?.schedule?.[0]?.apply_at).format('YYYY-MM-DD')
      }
    }

    public set rolloutScheduleDate(date: string) {
      if (!Array.isArray(this.otaRollout?.schedule)) {
        this.otaRollout.schedule.date_start = this.$dayjs(date).utc(true).add(6, 'h').toISOString().split('T')[0]
      }
    }

    public get rolloutScheduleType() {
      if (this.otaRollout?.schedule) {
        if (!Array.isArray(this.otaRollout.schedule)) {
          if (this.otaRollout.schedule.step_count) {
            return this.otaRollout.schedule.style + '-' + this.otaRollout.schedule.step_count
          } else if (this.otaRollout.schedule.step_list?.length > 1) {
            return this.otaRollout.schedule.style + '-' + this.otaRollout.schedule.step_list.join(':')
          } else if (this.otaRollout.schedule.step_list?.length == 1) {
            return 'manual-' + (this.otaRollout.schedule.step_list[0] || '')
          }
        } else {
          if (this.otaRollout.schedule.length === 1 && this.otaRollout.schedule[0].percentile === 100) {
            return 'daily-1'
          } else if (this.otaRollout.schedule.length === 1 && this.otaRollout.schedule[0].percentile !== 100) {
            return 'manual-' + this.otaRollout.schedule[0].percentile
          } else {
            const diff = this.$dayjs(this.otaRollout.schedule[1].apply_at).diff(
              this.$dayjs(this.otaRollout.schedule[0].apply_at),
              'day',
            )

            const cadence = diff === 1 ? 'daily' : diff === 7 ? 'weekly' : 'custom'

            const percents = this.otaRollout.schedule.map((s: any) => s.percentile)

            return `${cadence}-${percents.join(':')}`
          }
        }
      }

      return 'daily-1'
    }

    public set rolloutScheduleType(type: string) {
      const startDate = this.rolloutScheduleDate

      if (!Array.isArray(this.otaRollout?.schedule)) {
        if (type.startsWith('manual')) {
          this.otaRollout.schedule = {
            style: 'daily',
            step_list: [parseInt(type.split('-')[1] || '')],
            date_start: this.$dayjs(startDate).utc(true).add(6, 'h').toISOString().split('T')[0],
          }
        } else {
          const scheduleStyle = (type ?? '').split('-')[0]
          const scheduleSteps = (type ?? '').split('-')[1].split(':')

          if (scheduleSteps.length === 1) {
            this.otaRollout.schedule = {
              style: scheduleStyle,
              step_count: 1,
              date_start: this.$dayjs(startDate).utc(true).add(6, 'h').toISOString().split('T')[0],
            }
          } else if (scheduleSteps.length > 1) {
            this.otaRollout.schedule = {
              style: scheduleStyle,
              step_list: scheduleSteps.map((s: string) => parseInt(s)),
              date_start: this.$dayjs(startDate).utc(true).add(6, 'h').toISOString().split('T')[0],
            }
          }
        }
      }
    }

    public get needsOuraniansRollout() {
      return this.appFlavor === 'release' && !hasOuranianRollout(this.allRollouts)
    }
  }

  export default toNative(otaRollout)
</script>

<style lang="scss" scoped>
  :deep(.v-select) {
    &.v-input--readonly {
      .v-select__menu-icon {
        display: none;
      }
    }
  }
</style>
