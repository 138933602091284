import dayjs from 'dayjs'

import { AxiosRequestConfig } from 'axios'

import { Pinia, Store } from 'pinia-class-component'

import {
  FactoryFile,
  FactoryFileUpload,
  FactoryFileUploadsParams,
  FactoryTestDetail,
  FactoryTestSummary,
  SearchTestDetailsParams,
  SearchTestParams,
  serialNoParam,
} from '#types'

let polling: any

@Store
export class TestsStore extends Pinia {
  public loading = false

  public page = 1

  public pagination = {
    next: '',
    previous: '',
  }

  public files: any[] = []

  public allFactoryZipsCount = 0
  public allFactoryFilesError = ''
  public cancelAllFactoryFilePoll = false

  public ringTests: FactoryTestSummary[] = []
  public chargerTests: FactoryTestSummary[] = []

  public compareTests: FactoryTestDetail[] = []

  public allFactoryFiles: FactoryFile[] = []

  public factoryFileUploads: FactoryFileUpload[] = []

  public setAllFactoryFiles(allFactoryFiles: FactoryFile[]) {
    this.allFactoryFiles = allFactoryFiles
  }

  public setAllFactoryZips(allFactoryZipsCount: number) {
    this.allFactoryZipsCount = allFactoryZipsCount
  }

  public setAllFactoryFilesError(allFactoryFilesError: string) {
    this.allFactoryFilesError = allFactoryFilesError
  }

  public setCancelAllFactoryFilePoll(cancelAllFactoryFilePoll: boolean) {
    this.cancelAllFactoryFilePoll = cancelAllFactoryFilePoll
  }

  public updateFiles(files: any) {
    function findPath(path: string, list: any[]): any {
      const test = list.find((t) => path.startsWith(t.path))

      if (test?.path === path) {
        return test
      } else if (test) {
        return findPath(path, test.children)
      } else {
        return null
      }
    }

    if (files.path) {
      const parent = findPath(files.path, this.files)

      if (parent) {
        parent.children = files.data?.directories
          ?.map((d: any) => {
            return { ...d, children: [] }
          })
          .concat(
            files.data?.files.map((f: any) => {
              return { ...f, name: f.filename }
            }),
          )
      }
    } else {
      this.files = files.data?.directories
        ?.map((d: any) => {
          return { ...d, children: [] }
        })
        .concat(
          files.data?.files.map((f: any) => {
            return { ...f, name: f.filename }
          }),
        )
    }
  }

  public setRings(data: { page: number; contents: FactoryTestSummary[] }) {
    const tests: any = this.ringTests.slice(0, (data.page - 1) * 100).concat(data.contents ?? [])

    if (data.contents.length > 99) {
      tests.push({ type: 'hidden' })
    }

    this.ringTests = tests
  }

  public setChargers(data: { page: number; contents: FactoryTestSummary[] }) {
    const tests: any = this.chargerTests.slice(0, (data.page - 1) * 100).concat(data.contents ?? [])

    if (data.contents.length > 99) {
      tests.push({ type: 'hidden' })
    }

    this.chargerTests = tests
  }

  public async listFiles(dir: string) {
    this.loading = true

    let path = `/api/v1/rings/factory-tests`

    if (dir) {
      path += `?path=${dir}`
    }

    const response = await this.$axios.get(`${path}`)

    this.loading = false

    this.updateFiles({ path: dir, data: response?.data })

    return response?.data
  }

  public async searchTests(data: SearchTestParams) {
    this.loading = true

    const params = data.fields ? { params: data.fields } : undefined
    const path = data.page
      ? import.meta.env.VITE_API_URL + data.path
      : `/api/v1/rings/factory-tests/${data.category}?pageSize=100`

    const response = await this.$axios.get(...[path, params])

    if (data.category === 'ring') {
      this.setRings({
        contents: response?.data?.contents || [],
        page: data.page ?? 1,
      })
    } else {
      this.setChargers({
        contents: response?.data?.contents || [],
        page: data.page ?? 1,
      })
    }

    this.page = data.page ?? 1

    this.pagination = {
      next: response?.data?.pagination?.next || '',
      previous: response?.data?.pagination?.prev || '',
    }

    this.loading = false
  }

  public async searchTestDetails(tests: SearchTestDetailsParams) {
    this.loading = true

    this.compareTests = []

    const path = `/api/v1/rings/factory-tests/${tests.category}`

    const compareTests: FactoryTestDetail[] = []

    for (const testId of tests.testIds) {
      const response = await this.$axios.get(`${path}/${testId}`)

      if (response?.data) {
        compareTests.push(response.data)
      }
    }

    this.loading = false

    this.compareTests = compareTests
  }

  public async downloadAll(data: { category: string; fields: any }) {
    this.loading = true

    const { category, fields } = data

    const archiveRes = await this.$axios.get(`/api/v1/rings/factory-tests/${category}/archive`, {
      params: fields,
    })

    if (archiveRes?.data) {
      let interval = 3000

      const axios = this.$axios

      const { path, bucket, archive_count } = archiveRes.data

      this.allFactoryZipsCount = archive_count

      async function poll() {
        let result: any

        const testsStore = new TestsStore()

        async function fetchFiles() {
          const filesRes = await axios.get(`/api/v1/files?search_prefix=${path}&bucket=${bucket}`)

          return filesRes?.data?.contents
        }

        if (testsStore.cancelAllFactoryFilePoll) {
          if (polling) {
            clearTimeout(polling)
          }

          testsStore.loading = false
        } else {
          if (interval < 930000) {
            const timeStartFetch = performance.now()
            result = await fetchFiles()

            const timeFetchDuration = Math.round(performance.now() - timeStartFetch)
            interval = timeFetchDuration + interval

            if (!result) {
              testsStore.allFactoryFilesError = 'Error occur when fetching the download all factory test files'
            } else {
              if (result?.length !== archive_count) {
                testsStore.allFactoryFiles = result

                if (polling) {
                  clearTimeout(polling)
                }

                polling = setTimeout(poll, 3000)
                interval += 3000
              } else {
                testsStore.allFactoryFiles = result

                testsStore.loading = false
              }
            }
          } else {
            if (result?.length && result?.length < archive_count) {
              testsStore.allFactoryFiles = result?.data?.contents

              testsStore.allFactoryFilesError = `All factory files are too large to download and server has timed out!
                Only ${result?.data?.contents?.length} zips was created to download. Missing ${
                  archive_count - result?.data?.contents?.length
                } more zip files. Please report this in #poirot channel`
            } else {
              testsStore.allFactoryFilesError =
                'All the files are too large and server has timed out! Please report this in #poirot channel'
            }

            testsStore.loading = false
          }
        }
      }

      await poll()
    } else {
      this.allFactoryFilesError = 'Error occurred when creating a zip files'

      this.loading = false
    }
  }

  public async downloadFiles(data: { bucket: string; key: string[] }) {
    this.loading = true

    const payload: { key: string[]; bucket: string; save_as?: string } = {
      bucket: data.bucket,
      key: data.key,
    }

    if (data.key.length > 2) {
      payload['save_as'] = 'factory_test_files_' + dayjs().format('DD-MM-YYYY') + '.zip'
    }

    const response = await this.$axios.post('/api/v1/files/url', payload)

    if (response?.data?.fileUrl) {
      window.open(response?.data?.fileUrl)
    }

    this.loading = false
  }

  public async listFactoryFileUploads(params: FactoryFileUploadsParams | serialNoParam) {
    const { serialNo } = params as serialNoParam
    const serialNoPath = serialNo ? `${serialNo}/` : ''
    const requestUrl = `/api/v1/rings/${serialNoPath}factory-files`
    const requestConfig: AxiosRequestConfig<FactoryFileUploadsParams> | undefined = serialNo ? undefined : { params }

    this.loading = true

    const response = await this.$axios.get(requestUrl, requestConfig)

    this.factoryFileUploads = response?.data?.files ?? []

    this.loading = false
  }
}
