export interface FactoryTestSummary {
  testId: string
  serialNo: string
  factory: string
  productType: string
  testType: string
  overallTestResult?: string
  durationSeconds: number
  testStart: string
  bucket?: string
  key?: string
  failedMeasNo?: number
}

export interface FactoryTestDetail {
  uid: string
  serialNo: string
  factory: string
  productType: string | null
  testType: string
  testStart: string
  overallTestResult: string
  durationSeconds: number
  filePath: string
  testCount: number
  productionPhase: string
  ringSize: number
  ringModel: number
  ringColor: number
  purpose: string
  type: string
  testReady: string
  failedMeasNo: string
  failedMeasName: string
  testInformation: TestInformation
}

export interface FactoryTests {
  contents: FactoryTestSummary[]
  previous: string
  next: string
  count: number
}

export interface TestInformation {
  pc: string
  hwid: string
  imei: string
  rssi: string
  tester: string
  adapter: string
  wlan_mac: string
  device_sw: string
  module_code: string
  bluetooth_id: string
  product_code: string
  product_name: string
  hope_sw_version: string
  bluetooth_fw_ver: string
  basic_product_code: string
  photost_res_ambient: string
  photost_res_gain_used: string
  test_operator_id_code: string
  vusb_current_usb_on_ma: string
  vusb_current_usb_off_ma: string
  photost_res_led1a_amb_diff: string
  photost_res_led1b_amb_diff: string
  photost_res_led1c_amb_diff: string
  photost_res_led2a_amb_diff: string
  photost_res_led2b_amb_diff: string
  photost_res_led2c_amb_diff: string
  test_seq_name_and_revision: string
  photost_res_ambient_max_diff: string
}

export interface SearchTestDetailsParams {
  category: string
  testIds: string[]
}

export interface FactoryFile {
  key: string
  last_modified: string
  size: number
  type: string
}

export interface SearchTestParams {
  page?: number
  category: 'ring' | 'charger'
  path: string
  fields?: Partial<SearchFilters>
}

export type FactoryFileUpload = {
  /**
   * S3 bucket
   *
   * Example: 'oura-production-factory-io'
   */
  bucket: string

  /**
   * S3 file path
   *
   * Example: 'factory=oura/testing/file_type=log/product_type=ring/product=Gen4/test_phase=Final/date=2024-03-19/[DESKTOP-KJDRHR3][Gen4-ring-panel-test-114.seq][2024-02-23]\n[12-57-37][40150B2408000139][6].log
   */
  path: string

  /**
   * Product serial number
   *
   * Example: '2A300000000000'
   */
  serialNo: string

  /**
   * Factory name
   *
   * Example: 'sanmina-mx'
   */
  factory: FactoryNames

  /**
   * Factory test phase
   *
   * Example: 'final'
   */
  testPhase: TestPhases

  /**
   * Time of the file upload
   *
   * Example: '2024-03-21T07:41:48.560688+00:00'
   */
  uploadedAt: string
}

export type FactoryFileUploadsParams = {
  /**
   * Factory name
   *
   * Example: 'sanmina-mx'
   */
  factory?: FactoryNames

  /**
   * Factory test phase
   *
   * Example: 'final'
   */
  testPhase?: TestPhases

  /**
   * Timestamp range start
   *
   * Example: '2024-03-21T07:41:48.560688+00:00'
   */
  timestampFrom?: string

  /**
   * Timestamp range end
   *
   * Example: '2024-03-21T07:41:48.560688+00:00'
   */
  timestampTo?: string
}

export type SearchFilters = {
  productType: string
  resultSize: string
  serialNo: string
  serialRegex: string
  factory: string
  testType: string
  timeStart: string
  timeEnd: string
  purpose: string
  ringSize: string
  ringModel: string
  ringColor: string
  failedMeasNo: string
  overallTestResult: string
  testPhases: string
  hardwareType: string
}

export type serialNoParam = Pick<SearchFilters, 'serialNo'>

export enum FactoryTestsTabs {
  FILES = 'files',
  RINGS = 'rings',
  CHARGERS = 'chargers',
}

export enum TestPhases {
  PREMOLD = 'premold',
  PANEL = 'panel',
  FINAL = 'final',
  PCB = 'pcb',
  FINAL_RETEST = 'final-retest',
  PREMOLD_RETEST = 'premold-retest',
  FACTORY_RESET_FINAL_PHASE = 'factory_reset_final_phase',
  FACTORY_RESET_PREMOLD_PHASE = 'factory_reset_premold_phase',
  FACTORY_RESET = 'factory_reset',
  SET_FINAL_FLAG = 'set_final_flag',
  SET_PANEL_FLAG = 'set_panel_flag',
}

export enum FactoryNames {
  OURA = 'oura',
  SANMINA = 'sanmina',
  SANMINA_MX = 'sanmina-mx',
  LEAB = 'leab',
}
